import { defineStore } from 'pinia';
import { ref } from 'vue';

import JustificationRecordInputOutputAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useJustificationRecordInputOutputStore = defineStore(
  'justificationRecordInputOutputStore',
  () => {
    const allJustifications = ref<Array<any>>([]);
    const allJustificationsByPaginate = ref<Array<any>>([]);
    const justification = ref({});
    const lastPage = ref(1);
    const total = ref(0);

    async function getAllJustifications() {
      const data = await JustificationRecordInputOutputAPI().getAllJustifications();

      allJustifications.value = data;
    }

    async function getAllJustificationsByPaginate({ filter, paginate }) {
      const data = await JustificationRecordInputOutputAPI().paginate(
        filter,
        paginate.page,
        paginate.perPage,
      );

      allJustificationsByPaginate.value = data.data;
      total.value = data.total;
      lastPage.value = data.lastPage;
    }

    async function createJustification(obj: Record<string, string>) {
      const data = await JustificationRecordInputOutputAPI().saveJustification(obj);

      allJustificationsByPaginate.value.unshift(data);
    }

    async function updateJustification(obj: any) {
      const data = await JustificationRecordInputOutputAPI().updateJustification(obj);

      updateItemByField(allJustificationsByPaginate.value, 'id', data.id, data);
    }

    async function deleteJustification(id: number) {
      const data = await JustificationRecordInputOutputAPI().deleteJustification(id);

      removeItemByField(allJustificationsByPaginate.value, 'id', data.id);
    }

    return {
      allJustifications,
      allJustificationsByPaginate,
      lastPage,
      total,
      justification,
      deleteJustification,
      updateJustification,
      createJustification,
      getAllJustificationsByPaginate,
      getAllJustifications,
    };
  },
);
