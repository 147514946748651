import { defineStore } from 'pinia';
import { ref } from 'vue';

import AppraisalTypeAPI from './service';

export const useAppraisalTypeStore = defineStore('appraisalTypeStore', () => {
  const allAppraisalTypes = ref<Array<any>>([]);

  async function getAllAppraisalTypes() {
    const data = await AppraisalTypeAPI().getAllAppraisalTypes();

    allAppraisalTypes.value = data;
  }

  return {
    allAppraisalTypes,
    getAllAppraisalTypes,
  };
});
