import HttpClient from '@/services/http-client';

class VehicleTypeAPI extends HttpClient {
  async getAllVehicleTypes() {
    const response = await this.http.get('/vehicle-types');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicle-types/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveVehicleType(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-types', data);
    return response.data;
  }
  async updateVehicleType({ id, ...data }) {
    const response = await this.http.patch('/vehicle-types/' + id, data);
    return response.data;
  }
  async deleteVehicleType(id) {
    const response = await this.http.delete('/vehicle-types/' + id);
    return response.data;
  }
}

export default () => new VehicleTypeAPI();
