import HttpClient from '@/services/http-client';

class AdditionalUsersAPI extends HttpClient {
  async saveAdditionalUser(obj) {
    const response = await this.http.post('/additional-users', obj);
    return response.data;
  }
  async deleteAdditionalUser(id) {
    const response = await this.http.delete('/additional-users/' + id);
    return response.data;
  }
  async updateAdditionalUser({ id, ...data }) {
    const response = await this.http.patch('/additional-users/' + id, data);
    return response.data;
  }
  async getAllAdditionalUsers() {
    const response = await this.http.get('/additional-users');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/additional-users/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
}

export default () => new AdditionalUsersAPI();
