import moment from 'moment';

const getTitleAndContent = (maintenance) => {
  const servicesWithPendencies = maintenance.authorizedServices.filter(
    (service) => service.pivot.status === 'Pendente',
  );
  const servicesName = servicesWithPendencies.map((service) => service.name);

  return { title: 'Pendencias identificadas', content: servicesName.join('; <br/>') };
};

const getStatusMaintenance = (maintenance) => {
  const servicesWithPendencies = maintenance?.authorizedServices?.filter(
    (service) => service?.pivot?.status === 'Pendente',
  );

  if (servicesWithPendencies?.length)
    return {
      name: 'Com Pendencias',
      color: 'warning',
      servicesWithPendencies: getTitleAndContent(maintenance),
    };

  return { name: 'Sem pendencias', color: 'success', servicesWithPendencies: '' };
};

function getMaintenancesOrderNumber(allMaintenancesByPaginate) {
  return allMaintenancesByPaginate.map((item: any) => {
    const releaseForecasts = item?.releaseForecasts;
    item.last_release_forecast = null;
    item.status = getStatusMaintenance(item);

    if (releaseForecasts?.length) {
      const date = releaseForecasts[releaseForecasts.length - 1].date;

      item.last_release_forecast = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return item;
  });
}

export default {
  getMaintenancesOrderNumber,
};
