import HttpClient from '@/services/http-client';
import qs from 'qs';

class MaintenanceServiceCategotyAPI extends HttpClient {
  async index(fields?: string[], relations?: { [key: string]: { fields?: string[] | string } }) {
    const response = await this.http.get('/vehicle-part-groups', {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async paginate(filter: Object, page: number, perPage: number) {
    const response = await this.http.get('/vehicle-part-groups/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async save(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-part-groups', data);
    return response.data;
  }
  async update({ id, ...data }) {
    const response = await this.http.patch('/vehicle-part-groups/' + id, data);
    return response.data;
  }
  async delete(id: number) {
    const response = await this.http.delete('/vehicle-part-groups/' + id);
    return response.data;
  }
}

export default () => new MaintenanceServiceCategotyAPI();
