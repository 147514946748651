import HttpClient from '@/services/http-client';

class SyncServices extends HttpClient {
  async syncRequest(request) {
    if (request.type === 'post') {
      const response = await this.http.post(request.url, request.data);
      return response.data;
    }

    if (request.type === 'delete') {
      const response = await this.http.delete(request.url);
      return response.data;
    }

    const response = await this.http.patch(request.url, request.data);
    return response.data;
  }
}

export default () => new SyncServices();
