import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import nProgress from 'nprogress';

Vue.use(VueRouter);
import paths from './paths';

const routes: Array<RouteConfig> = paths;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];

  if (to?.meta?.title) document.title = to?.meta?.title ?? process.env.VUE_APP_NAME ?? '';

  const publicPagesNotRequired = 'VehiclePublicScreen';
  const authNotRequired = publicPagesNotRequired === to.name;
  if (authNotRequired) {
    next();
    return;
  }

  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem('token');
  if (authRequired && !token) next({ name: 'Login' });
  else next();
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    nProgress.start();
  }
  next();
});

router.afterEach(() => {
  // ...
  nProgress.done();
});

export default router;
