import { defineStore } from 'pinia';

import FileDownloadServiceAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useFileDownloadStore = defineStore('fileDownloadStore', () => {
  async function extractFile(fileName: string) {
    return FileDownloadServiceAPI().downloadFile(fileName);
  }

  return { extractFile };
});
