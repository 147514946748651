import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleGroupAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useVehicleGroupStore = defineStore('vehicleGroupStore', () => {
  const allVehicleGroups = ref<Array<any>>([]);
  const allVehicleGroupsByPaginate = ref<Array<any>>([]);
  const vehicleGroup = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteVehicleGroup(id: number) {
    const data = await VehicleGroupAPI().deleteVehicleGroup(id);

    removeItemByField(allVehicleGroupsByPaginate.value, 'id', data.id);
  }

  async function updateVehicleGroup(obj: any) {
    const data = await VehicleGroupAPI().updateVehicleGroup(obj);

    updateItemByField(allVehicleGroupsByPaginate.value, 'id', data.id, data);
  }

  async function getAllVehicleGroups() {
    const data = await VehicleGroupAPI().getAllVehicleGroups();

    allVehicleGroups.value = data;
  }

  async function getAllVehicleGroupsByPaginate({ filter, paginate }) {
    const data = await VehicleGroupAPI().paginate(filter, paginate.page, paginate.perPage);

    allVehicleGroupsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveVehicleGroup(obj: Record<string, string>) {
    const data = await VehicleGroupAPI().saveVehicleGroup(obj);

    allVehicleGroupsByPaginate.value.unshift(data);
  }

  return {
    allVehicleGroups,
    allVehicleGroupsByPaginate,
    vehicleGroup,
    lastPage,
    total,
    deleteVehicleGroup,
    updateVehicleGroup,
    getAllVehicleGroupsByPaginate,
    getAllVehicleGroups,
    saveVehicleGroup,
  };
});
