import HttpClient from '@/services/http-client';
import qs from 'qs';

class TrackersAPI extends HttpClient {
  async paginate({ page, perPage, filter }) {
    const response = await this.http.get('/tracker-costs/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async getAllTrackerCostServiceTypes() {
    const response = await this.http.get('/tracker-costs/service-types');
    return response.data;
  }

  async extractTrackerCostByYear(year: number) {
    const response = await this.http.get('summaries/tracker-cost/extract', {
      params: { year },
    });

    return response.data;
  }

  async getTrackerCostSummary({ year, costCenters = [] }) {
    const response = await this.http.get('summaries/tracker-cost', {
      params: { year, costCenters },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async create(trackerCosts, referenceMonth) {
    const response = await this.http.post('/tracker-costs', { trackerCosts, referenceMonth });
    return response.data;
  }

  async delete(id: number) {
    await this.http.delete(`/tracker-costs/${id}`);
  }
}

export default () => new TrackersAPI();
