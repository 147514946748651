import HttpClient from '@/services/http-client';

class AuthAPI extends HttpClient {
  async signIn(data: Record<string, string>) {
    const response = await this.http.post('/authenticate/login', data);
    return response;
  }

  async getDetails() {
    const response = await this.http.get('/users/profile');
    return response.data;
  }
}

export default () => new AuthAPI();
