import { defineStore } from 'pinia';
import { ref } from 'vue';

import DocumentStatusAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useDocumentStatusStore = defineStore('documentStatusStore', () => {
  const allDocumentStatuses = ref<Array<any>>([]);
  const documentStatusesByPaginate = ref<Array<any>>([]);
  const documentStatus = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteDocumentStatus(id: number) {
    const data = await DocumentStatusAPI().deleteDocumentStatus(id);

    removeItemByField(documentStatusesByPaginate.value, 'id', data.id);
  }

  async function updateDocumentStatus(obj: any) {
    const data = await DocumentStatusAPI().updateDocumentStatus(obj);

    updateItemByField(documentStatusesByPaginate.value, 'id', data.id, data);
  }

  async function getDocumentStatusesByPaginate({ filter, paginate }) {
    const data = await DocumentStatusAPI().paginate(filter, paginate.page, paginate.perPage);

    documentStatusesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllDocumentStatuses() {
    const data = await DocumentStatusAPI().getAllDocumentStatuses();

    allDocumentStatuses.value = data;
  }

  async function saveDocumentStatus(obj: Record<string, string>) {
    const data = await DocumentStatusAPI().saveDocumentStatus(obj);

    documentStatusesByPaginate.value.unshift(data);
  }

  return {
    allDocumentStatuses,
    documentStatusesByPaginate,
    lastPage,
    total,
    documentStatus,
    saveDocumentStatus,
    getAllDocumentStatuses,
    getDocumentStatusesByPaginate,
    updateDocumentStatus,
    deleteDocumentStatus,
  };
});
