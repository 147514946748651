import { defineStore } from 'pinia';
import { ref } from 'vue';

import UserAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useUserStore = defineStore('userStore', () => {
  const allUsers = ref<Array<any>>([]);
  const usersByPaginate = ref<Array<any>>([]);
  const user = ref({});
  const permission = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteUser(obj: { id: number }) {
    const data = await UserAPI().deleteUser(obj);

    removeItemByField(usersByPaginate.value, 'id', data.id);
  }

  async function updateUser(obj: { id: number }) {
    const data = await UserAPI().updateUser(obj);

    updateItemByField(usersByPaginate.value, 'id', data.id, data);
  }

  async function createUser(obj: Record<string, string>) {
    const data = await UserAPI().createUser(obj);

    usersByPaginate.value.unshift(data);
  }

  async function getAllUsers() {
    const data = await UserAPI().getAllUsers();

    allUsers.value = data;
  }

  async function getUsersByPaginate({ filter, paginate }) {
    const data = await UserAPI().paginate(filter, paginate.page, paginate.perPage);

    usersByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getUser(id) {
    const data = await UserAPI().getUser(id);

    user.value = data;
  }

  function validateIfUserHavePermission(user, permission) {
    const userIsAdmistrator = user?.roles?.some((role) => role.slug === 'administrator');
    const userHavePermission = user?.permissions?.some(
      (permissionEntiy) => permissionEntiy.slug === permission,
    );

    if (userIsAdmistrator || userHavePermission) return true;

    return false;
  }

  return {
    allUsers,
    usersByPaginate,
    user,
    permission,
    lastPage,
    total,
    getUsersByPaginate,
    getAllUsers,
    createUser,
    updateUser,
    deleteUser,
    getUser,
    validateIfUserHavePermission,
  };
});
