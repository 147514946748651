import currency from 'currency.js';

export function useMath() {
  function sum(numberOne, numberTwo, precision = 11) {
    return currency(numberOne, { precision }).add(numberTwo).value;
  }

  function multiply(numberOne, numberTwo, precision = 11) {
    return currency(numberOne, { precision }).multiply(numberTwo).value;
  }

  function divide(numberOne, numberTwo, precision = 11) {
    return currency(numberOne, { precision }).divide(numberTwo).value;
  }

  function subtract(numberOne, numberTwo, precision = 11) {
    return currency(numberOne, { precision }).subtract(numberTwo).value;
  }

  function convert(number, precision = 11) {
    return currency(number, { precision }).value;
  }

  return { sum, multiply, divide, subtract, convert };
}
