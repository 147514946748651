import HTTPClient from '@/services/http-client';

class AdditionalInformationAPI extends HTTPClient {
  async getAllAdditionalInformations() {
    const response = await this.http.get('/additional-informations');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/additional-informations/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveAdditionalInformation(data: Record<string, string>) {
    const response = await this.http.post('/additional-informations', data);
    return response.data;
  }
  async updateAdditionalInformation({ id, ...data }) {
    const response = await this.http.patch('/additional-informations/' + id, data);
    return response.data;
  }
  async deleteAdditionalInformation(id: number) {
    const response = await this.http.delete('/additional-informations/' + id);
    return response.data;
  }
}

export default () => new AdditionalInformationAPI();
