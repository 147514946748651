import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import TrafficViolationAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useTrafficViolationStore = defineStore('trafficViolationStore', () => {
  const allTrafficViolations = ref<Array<any>>([]);
  const trafficViolationsByPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  const normalizeNameTrafficViolations = computed(() =>
    getters.normalizeNameTrafficViolations(allTrafficViolations.value),
  );

  async function getAllTrafficViolations() {
    const data = await TrafficViolationAPI().getAllTrafficViolations();

    allTrafficViolations.value = data;
  }

  async function deleteTrafficViolation(id: [String, Number]) {
    const data = await TrafficViolationAPI().deleteTrafficViolation(id);

    removeItemByField(trafficViolationsByPaginate.value, 'id', data.id);
  }

  async function updateTrafficViolation({ id, ...payload }: any) {
    const data = await TrafficViolationAPI().updateTrafficViolation(id, payload);

    updateItemByField(trafficViolationsByPaginate.value, 'id', data.id, data);
  }

  async function getTrafficViolationsByPaginate({ filter, paginate }) {
    const data = await TrafficViolationAPI().paginate(filter, paginate.page, paginate.perPage);

    trafficViolationsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveTrafficViolation(obj: Record<string, string>) {
    const data = await TrafficViolationAPI().saveTrafficViolation(obj);

    trafficViolationsByPaginate.value.unshift(data);
  }

  return {
    allTrafficViolations,
    trafficViolationsByPaginate,
    lastPage,
    total,
    normalizeNameTrafficViolations,
    getAllTrafficViolations,
    deleteTrafficViolation,
    updateTrafficViolation,
    getTrafficViolationsByPaginate,
    saveTrafficViolation,
  };
});
