import HttpClient from '@/services/http-client';

class VehicleMovementPermissionAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicle-movement-permissions/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveVehicleMovementPermission(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-movement-permissions', data);
    return response.data;
  }
  async getAllVehicleMovementPermissions() {
    const response = await this.http.get('/vehicle-movement-permissions');
    return response.data;
  }
  async updateVehicleMovementPermission({ id, ...data }) {
    const response = await this.http.patch('/vehicle-movement-permissions/' + id, data);
    return response.data;
  }
  async deleteVehicleMovementPermission(id: number) {
    const response = await this.http.delete('/vehicle-movement-permissions/' + id);
    return response.data;
  }
}
export default () => new VehicleMovementPermissionAPI();
