import { defineStore } from 'pinia';
import { ref } from 'vue';

import ConsolidatedCostAPI from './service';

export const useConsolidatedCostStore = defineStore('consolidatedCostStore', () => {
  const loading = ref(false);

  async function getConsolidatedCosts({ year, costCenters }) {
    loading.value = true;
    const data = await ConsolidatedCostAPI().getConsolidatedCosts({ year, costCenters });
    loading.value = false;

    return data;
  }

  async function extractConsolidatedCosts(year) {
    const data = await ConsolidatedCostAPI().extractConsolidatedCosts(year);
    return data;
  }

  return {
    loading,
    getConsolidatedCosts,
    extractConsolidatedCosts,
  };
});
