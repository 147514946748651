import HttpClient from '@/services/http-client';

class DocumentStatusAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/document-statuses/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async getAllDocumentStatuses() {
    const response = await this.http.get('/document-statuses');
    return response.data;
  }
  async saveDocumentStatus(data: Record<string, string>) {
    const response = await this.http.post('/document-statuses', data);
    return response.data;
  }
  async updateDocumentStatus({ id, ...data }) {
    const response = await this.http.patch('/document-statuses/' + id, data);
    return response.data;
  }
  async deleteDocumentStatus(id) {
    const response = await this.http.delete('/document-statuses/' + id);
    return response.data;
  }
}

export default () => new DocumentStatusAPI();
