import HttpClient from '@/services/http-client';

class RentalFeeAPI extends HttpClient {
  async paginate(id: [Number, String], filter: Object, page: number, perPage: number) {
    const result = await this.http.get('rental-fees/paginate', {
      params: { id, filter, page, perPage },
    });
    return result.data;
  }
  async saveRentalFee(payload: any) {
    const response = await this.http.post('/rental-fees', payload);
    return response.data;
  }
  async updateRentalFee({ id, ...data }) {
    const response = await this.http.patch('/rental-fees/' + id, data);
    return response.data;
  }
  async deleteRentalFee(id) {
    const response = await this.http.delete('/rental-fees/' + id);
    return response.data;
  }
}

export default () => new RentalFeeAPI();
