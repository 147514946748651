import http from '@/services/http';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { Ability } from '@casl/ability';
import { abilitiesPlugin, Can } from '@casl/vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueMask from 'v-mask';
import Vue from 'vue';
import Cleave from 'vue-cleave-component';
import VueCurrencyInput from 'vue-currency-input';
import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';
import 'vue-js-modal/dist/styles.css';

import VueMeta from 'vue-meta';

import VueQrcodeReader from 'vue-qrcode-reader';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';
import 'vue2-datepicker/index.css';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import App from './App.vue';

//Components imports

import './filter/index';
import './registerServiceWorker';
import router from './router';
import Formatters from './utils/formatters.js';
import SwalUtils from './utils/swal.js';
import Validators from './utils/validators';

import TreeGrid from '@ksgl/treegrid';
import '@ksgl/treegrid/src/assets/css/jquery.treegrid.css';

//you need to import the CSS manually (in case you want to override it)

const ability = new Ability([]);

Vue.use(BootstrapVue);
Vue.use(VueFormGenerator);
Vue.use(VueQrcodeReader);
Vue.use(VueSweetalert2);
Vue.use(abilitiesPlugin, ability);
Vue.use(VueCurrencyInput);
Vue.use(SwalUtils);
Vue.use(Formatters);
Vue.use(Validators);
Vue.use(Cleave);
Vue.use(TreeGrid);

Vue.use(VueSimpleAccordion, {});

Vue.prototype.$moment = (value) => {
  moment.locale('pt-br');

  if (!value) return moment();
  return moment(value);
};

Vue.prototype.$rootMoment = moment;

Vue.prototype.$http = http();

Vue.use(VueTheMask);

Vue.component('Can', Can);
Vue.component('app-layout-footer', () => import('@/components/Drawer/LayoutFooter.vue'));
Vue.component('menu-sidenav', () => import('@/components/Drawer/Sidenav.vue'));
Vue.component('menu-navbar', () => import('@/components/Drawer/NavBar.vue'));
Vue.component('paginate', () => import('@/molecules/Paginate.vue'));
Vue.component('per-page-and-filter', () => import('@/molecules/PerPageAndFilter.vue'));
Vue.component('EditButton', () => import('@/atoms/Buttons/EditButton.vue'));
Vue.component('CreateButton', () => import('@/atoms/Buttons/CreateButton.vue'));
Vue.component('ShowFileButton', () => import('@/atoms/Buttons/ShowFileButton.vue'));
Vue.component('DownloadButton', () => import('@/atoms/Buttons/DownloadButton.vue'));
Vue.component('PrintButton', () => import('@/atoms/Buttons/PrintButton.vue'));
Vue.component('DeleteButton', () => import('@/atoms/Buttons/DeleteButton.vue'));
Vue.component('FilterContainer', () => import('@/atoms/FilterContainer.vue'));
Vue.component('ApproveMaintenanceButton', () =>
  import('@/atoms/Maintenances/ApproveMaintenanceButton.vue'),
);
Vue.component('RejectMaintenanceButton', () =>
  import('@/atoms/Maintenances/RejectMaintenanceButton.vue'),
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('integer-input', () => import('@/atoms/IntegerInput.vue'));
Vue.component('title-page', () => import('@/atoms/TitlePage.vue'));
Vue.component('user-has-permission', () => import('@/atoms/UserHasPermission.vue'));
Vue.component('multiselect', () => import('vue-multiselect'));
Vue.component('v-date-picker', () => import('v-calendar/lib/components/date-picker.umd'));
Vue.component('date-picker', () => import('vue2-datepicker'));
Vue.component('date-range-picker', () => import('vue2-daterange-picker'));
Vue.component('v-multiselect-listbox', () => import('vue-multiselect-listbox'));
Vue.component('download-excel', () => import('vue-json-excel'));
Vue.component('apexchart', () => import('vue-apexcharts'));
Vue.component('VueMonthlyPicker', () => import('vue-monthly-picker'));
Vue.component('form-header', () => import('@/molecules/FormHeader.vue'));
Vue.component('form-section-header', () => import('@/molecules/FormSectionHeader.vue'));
Vue.component('form-footer', () => import('@/molecules/FormFooter.vue'));
Vue.component('vue-simple-spinner', () => import('vue-simple-spinner'));
Vue.component('page-header', () => import('@/molecules/PageHeader.vue'));

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueMeta);
Vue.use(VueMask, {
  placeholders: {
    '#': null, // passing `null` removes default placeholder, so `#` is treated as character
    D: /\d/, // define new placeholder
    Я: /[\wа-яА-Я]/, // cyrillic letter as a placeholder
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
