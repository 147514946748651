import HttpClient from '@/services/http-client';
import qs from 'qs';

class UnitAPI extends HttpClient {
  async getAllUnits(
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
  ) {
    const response = await this.http.get('/federative-units', {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('federative-units/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveUnit(data: Record<string, string>) {
    const response = await this.http.post('/federative-units', data);
    return response.data;
  }
  async updateUnit({ id, ...data }) {
    const response = await this.http.patch('/federative-units/' + id, data);
    return response.data;
  }
  async deleteUnit(id) {
    const response = await this.http.delete('/federative-units/' + id);
    return response.data;
  }
}

export default () => new UnitAPI();
