import HttpClient from '@/services/http-client';

class ViolationClassificationAPI extends HttpClient {
  async getAllViolationClassifications() {
    const response = await this.http.get('/violation-classifications');
    return response.data;
  }
}

export default () => new ViolationClassificationAPI();
