import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehiclePartGroupAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useVehiclePartGroupStore = defineStore('vehiclePartGroupStore', () => {
  const vehiclePartGroupsByPaginate = ref<Array<any>>([]);
  const allVehiclePartGroups = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteVehiclePartGroups(id: number) {
    const data = await VehiclePartGroupAPI().delete(id);

    removeItemByField(vehiclePartGroupsByPaginate.value, 'id', data.id);
  }

  async function updateVehiclePartGroups(obj: any) {
    const data = await VehiclePartGroupAPI().update(obj);

    updateItemByField(vehiclePartGroupsByPaginate.value, 'id', data.id, data);
  }

  async function saveVehiclePartGroups(obj: any) {
    const data = await VehiclePartGroupAPI().save(obj);

    vehiclePartGroupsByPaginate.value.unshift(data);
  }

  async function getVehiclePartGroupsByPaginate({ filter, paginate }) {
    const data = await VehiclePartGroupAPI().paginate(filter, paginate.page, paginate.perPage);

    vehiclePartGroupsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllVehiclePartGroups({ fields = [], relations = {} } = {}) {
    const data = await VehiclePartGroupAPI().index(fields, relations);

    allVehiclePartGroups.value = data;
  }

  return {
    vehiclePartGroupsByPaginate,
    allVehiclePartGroups,
    lastPage,
    total,
    deleteVehiclePartGroups,
    updateVehiclePartGroups,
    saveVehiclePartGroups,
    getVehiclePartGroupsByPaginate,
    getAllVehiclePartGroups,
  };
});
