import HttpClient from '@/services/http-client';
class PermissionAPI extends HttpClient {
  async getAllPermissions() {
    const result = await this.http.get('/permissions');
    return result.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get(
      `/permissions/paginate?page=${page}&perPage=${perPage}&filter=${
        Object.keys(filter).length ? JSON.stringify(filter, undefined, 2) : ''
      }`,
    );
    return result.data;
  }
}

export default () => new PermissionAPI();
