<template>
  <div id="app">
    <div class="layout-wrapper layout-2">
      <div class="layout-inner">
        <menu-sidenav v-if="!getMetaPublic" />
        <div class="layout-container">
          <menu-navbar v-if="!getMetaPublic" />

          <div class="layout-content">
            <div class="router-transitions container-fluid flex-grow-1 container-p-y">
              <router-view />
            </div>
          </div>
          <app-layout-footer />
        </div>
      </div>
      <div class="layout-overlay" @click="closeSidenav" />
    </div>
  </div>
</template>

<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script setup>
import { onMounted, computed, onBeforeUnmount } from 'vue';

import { useAppSettingsStore, useAuthStore } from '@/stores';
import { useSwalProvider, useMomentProvider, useRoute, useCasl } from '@/composables';
import { AbilityBuilder } from '@casl/ability';

const authStore = useAuthStore();
const appSettingsStore = useAppSettingsStore();
const route = useRoute();
const { ability } = useCasl();

const getMetaPublic = computed(() => {
  return route.meta.public;
});

useSwalProvider();
useMomentProvider();

function closeSidenav() {
  appSettingsStore.layoutHelpers.setCollapsed(true);
}

function updateAbility(user) {
  const { can, rules } = new AbilityBuilder();

  if (!user?.roles) {
    return;
  }

  user.roles.map((role) => {
    role.permissions.map((permission) => {
      const [action, subject] = permission?.slug.split('_');
      can(action, subject);
    });
  });

  user.permissions.map((permission) => {
    const [action, subject] = permission?.slug.split('_');
    can(action, subject);
  });

  ability.update(rules);
}

onMounted(async () => {
  if (authStore.token) {
    await authStore.getDetailsUser();

    updateAbility(authStore.user);
  }
});

onBeforeUnmount(() => {
  appSettingsStore.layoutHelpers.destroy();
});
</script>

<style src="@/vendor/styles/appwork.scss" lang="scss"></style>

<style>
.swal2-container {
  z-index: 1100 !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

/* classes for treegrid reports */

.tree-grid-report .row-active td {
  color: white;
  background-color: #708090;
  font-weight: 600;
}
.tree-grid-report .row-active td:first-of-type {
  color: white;
  font-weight: 500;
}

.tree-grid-report .is-line-cost-center {
  font-weight: 500;
  background-color: #ffffff;
}

.tree-grid-report .is-line-root {
  z-index: 2;
  background-color: #ffffff;
}
.tree-grid-report .is-line-total {
  background-color: #1b1e23;
  color: white;
}

.tree-grid-report .is-month-total {
  background-color: #2f3237;
  color: white;
}

.tree-grid-report .row-active .treegrid-expander {
  filter: brightness(2) saturate(100%);
}

.treegrid-expander {
  filter: brightness(0.7) saturate(50%);
}
.tree-grid-report .name-field {
  z-index: 2;
  background-color: white;
}
.tree-grid-report .table {
  border-collapse: separate;
  border-spacing: 0;
}
.tree-grid-report thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
  background-color: white;
}
.tree-grid-report th:first-child,
.tree-grid-report td:first-child {
  position: sticky;
  left: 0px;
  z-index: 1;
}

.column-media {
  background-color: #525252;
  color: white;
}
.column-total {
  background-color: #525252;
  color: white;
}

.column-projection {
  background-color: #cbdcf3;
  color: black;
}
</style>
