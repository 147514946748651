import { getCurrentInstance, nextTick } from 'vue';

export function useCasl() {
  const currentInstance = getCurrentInstance();

  function can(action: string, subject: string): Promise<boolean | undefined> {
    return new Promise<boolean | undefined>((resolve) => {
      nextTick(() => {
        resolve(currentInstance?.proxy.$can(action, subject));
      });
    });
  }

  return {
    ability: currentInstance?.proxy.$ability,
    can,
  };
}
