import HttpClient from '@/services/http-client';
import qs from 'qs';
import { IGetAllEmployees } from './interfaces';

class EmployeeAPI extends HttpClient {
  async getAllEmployees({
    normalized = true,
    onlyActive = true,
    fields = [],
    relations,
    module = '',
  }: IGetAllEmployees) {
    const response = await this.http.get('/employees', {
      params: {
        fields,
        relations,
        normalized,
        onlyActive,
        module,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get('employees/paginate', { params: { filter, page, perPage } });

    return result.data;
  }
  async importEmployeesByMetadataFile(obj: FormData) {
    const response = await this.http.post('/employees/create-many', obj);
    return response.data;
  }
}

export default () => new EmployeeAPI();
