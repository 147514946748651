import { defineStore } from 'pinia';
import { ref } from 'vue';

import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useFtableStore = defineStore('ftableStore', () => {
  const items = ref(null);
  const filter = ref({
    search: null,
    initDate: null,
    finalDate: null,
    useVisitorsTable: false,
    operationalBases: [{ id: '', name: 'Todas' }],
    status: null,
  });

  const page = ref(1);
  const perPage = ref(25);
  const total = ref(0);
  const update = ref(0);

  function loadItems(itemsData) {
    items.value = itemsData;
  }

  function setTotal(totalValue) {
    total.value = totalValue;
  }

  function setPage(pageValue) {
    page.value = pageValue;
  }

  function setPerPage(perPageValue) {
    perPage.value = perPageValue;
  }

  function setFilter({ key, value }) {
    filter.value[key] = value;
  }

  function clearTable(state) {
    items.value = null;
    page.value = 1;
    perPage.value = 25;
    total.value = 0;
    filter.value = {
      ...filter.value,
      search: null,
      initDate: null,
      finalDate: null,
      operationalBases: [{ id: '', name: 'Todas' }],
      status: null,
    };
    update.value = 0;
  }

  function incrementUpdate() {
    update.value += 1;
  }

  return {
    items,
    filter,
    page,
    perPage,
    total,
    update,
    incrementUpdate,
    clearTable,
    setFilter,
    setPerPage,
    setPage,
    setTotal,
    loadItems,
  };
});
