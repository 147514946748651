import HttpClient from '@/services/http-client';
import qs from 'qs';

class ConsolidatedCostAPI extends HttpClient {
  async getConsolidatedCosts({ year, costCenters }) {
    const response = await this.http.get('/summaries/consolidated-cost', {
      params: { year, costCenters },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async extractConsolidatedCosts(year) {
    const response = await this.http.get('/summaries/consolidated-cost/extract', {
      params: { year },
    });
    return response.data;
  }
}

export default () => new ConsolidatedCostAPI();
