import HttpClient from '@/services/http-client';

class VehicleUsageInfoAPI extends HttpClient {
  async getAllVehicleUsageInfos() {
    const response = await this.http.get('/vehicle-usage-infos');
    return response.data;
  }
  async saveVehicleUsageInfo(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-usage-infos', data);
    return response.data;
  }
  async saveBulkVehicleUsageInfo(data) {
    const response = await this.http.post('/vehicle-usage-infos/bulk', {inconsistencies: data});
    return response.data
  }
}

export default () => new VehicleUsageInfoAPI();
