import { defineStore } from 'pinia';
import { ref } from 'vue';

import AdditionalUsersAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useAdditionalUserStore = defineStore('additionalUserStore', () => {
  const allAdditionalUsers = ref<Array<any>>([]);
  const additionalUser = ref({});
  const allAdditionalUsersByPaginate = ref<Array<any>>([]);
  const lastPage = ref(0);
  const total = ref(0);

  async function updateAdditionalUser(obj: any) {
    const data = await AdditionalUsersAPI().updateAdditionalUser(obj);

    updateItemByField(allAdditionalUsersByPaginate.value, 'id', data.id, data);
  }

  async function deleteAdditionalUser(id: string) {
    const data = await AdditionalUsersAPI().deleteAdditionalUser(id);

    removeItemByField(allAdditionalUsersByPaginate.value, 'id', data.id);
  }

  async function getAllAdditionalUsers() {
    const data = await AdditionalUsersAPI().getAllAdditionalUsers();

    allAdditionalUsers.value = data;
  }

  async function getAllAdditionalUsersByPaginate({ filter, paginate }) {
    const data = await AdditionalUsersAPI().paginate(filter, paginate.page, paginate.perPage);

    allAdditionalUsersByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveAdditionalUser(obj: Record<string, string>) {
    const data = await AdditionalUsersAPI().saveAdditionalUser(obj);

    allAdditionalUsersByPaginate.value.unshift(data);
  }

  return {
    allAdditionalUsers,
    additionalUser,
    total,
    lastPage,
    allAdditionalUsersByPaginate,
    updateAdditionalUser,
    deleteAdditionalUser,
    getAllAdditionalUsersByPaginate,
    getAllAdditionalUsers,
    saveAdditionalUser,
  };
});
