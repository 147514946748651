const getVehiclesMovementsRecords = (allVehicleMovementRecords: any[]) => {
  return allVehicleMovementRecords.map((element) => {
    if (!element.vehicle) {
      element.vehicle = { plate: element.plate };
    }

    if (!element.driver) {
      element.driver = { name: element.driver_name };
    }

    return element;
  });
};

export default {
  getVehiclesMovementsRecords,
};
