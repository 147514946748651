import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleMovementPermissionAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useVehicleMovementPermissionStore = defineStore(
  'vehicleMovementPermissionStore',
  () => {
    const allVehicleMovementPermissions = ref<Array<any>>([]);
    const allVehicleMovementPermissionsByPaginate = ref<Array<any>>([]);
    const vehicleMovementPermission = ref({});
    const lastPage = ref(1);
    const total = ref(0);

    async function deleteVehicleMovementPermission(id: number) {
      const data = await VehicleMovementPermissionAPI().deleteVehicleMovementPermission(id);

      removeItemByField(allVehicleMovementPermissionsByPaginate.value, 'id', data.id);
    }

    async function updateVehicleMovementPermission(obj: any) {
      const data = await VehicleMovementPermissionAPI().updateVehicleMovementPermission(obj);

      updateItemByField(allVehicleMovementPermissionsByPaginate.value, 'id', data.id, data);
    }

    async function getAllVehicleMovementPermissions() {
      const data = await VehicleMovementPermissionAPI().getAllVehicleMovementPermissions();

      allVehicleMovementPermissions.value = data;
    }

    async function getAllVehicleMovementPermissionsByPaginate({ filter, paginate }) {
      const data = await VehicleMovementPermissionAPI().paginate(
        filter,
        paginate.page,
        paginate.perPage,
      );

      allVehicleMovementPermissionsByPaginate.value = data.data;
      total.value = data.total;
      lastPage.value = data.lastPage;
    }

    async function saveVehicleMovementPermission(obj: Record<string, string>) {
      const data = await VehicleMovementPermissionAPI().saveVehicleMovementPermission(obj);

      allVehicleMovementPermissionsByPaginate.value.unshift(data);
    }

    return {
      allVehicleMovementPermissions,
      allVehicleMovementPermissionsByPaginate,
      vehicleMovementPermission,
      lastPage,
      total,
      deleteVehicleMovementPermission,
      updateVehicleMovementPermission,
      getAllVehicleMovementPermissionsByPaginate,
      getAllVehicleMovementPermissions,
      saveVehicleMovementPermission,
    };
  },
);
