import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import MaintenanceAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useMaintenanceStore = defineStore('maintenanceStore', () => {
  const allMaintenances = ref<Array<any>>([]);
  const allMaintenancesByPaginate = ref<Array<any>>([]);
  const allMaintenancesWithApprovalPendencies = ref<Array<any>>([]);
  const allMaintenancesCosts = ref<Array<any>>([]);
  const maintenance = ref({});
  const lastPage = ref(1);
  const total = ref(0);
  const totalMaintenancesWithApprovalPendencies = ref(0);
  const lastPageMaintenanceWithApprovalPendencies = ref(0);

  async function getAllMaintenances({ fields = [], relations = {} }) {
    const data = await MaintenanceAPI().getAllMaintenances(fields, relations);

    allMaintenances.value = data;
  }

  const allMaintenancesByPaginateWithLastMaintenance = computed(() =>
    getters.getMaintenancesOrderNumber(allMaintenancesByPaginate.value),
  );

  async function getMaintenance(id) {
    const data = await MaintenanceAPI().getMaintenance(id);

    maintenance.value = data;
  }

  async function getMaintenancesByPaginate({ filter, paginate }) {
    const data = await MaintenanceAPI().paginate(filter, paginate.page, paginate.perPage);

    allMaintenancesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getMaintenancesWithApprovalPendenciesByPaginate({ filter, paginate }) {
    const data = await MaintenanceAPI().paginateMaintenancesWithApprovalPendencies(
      filter,
      paginate.page,
      paginate.perPage,
    );

    allMaintenancesWithApprovalPendencies.value = data.data;
    totalMaintenancesWithApprovalPendencies.value = data.total;
    lastPageMaintenanceWithApprovalPendencies.value = data.lastPage;
  }

  async function changeMaintenanceApprovalStatus({ statusId, maintenanceId }: any) {
    const data = await MaintenanceAPI().changeMaintenanceApprovalStatus(maintenanceId, statusId);

    maintenance.value = data;

    if (data.approval_status_id === 1) {
      removeItemByField(allMaintenancesWithApprovalPendencies.value, 'id', data.id);

      return allMaintenancesByPaginate.value.unshift(data);
    }

    updateItemByField(allMaintenancesWithApprovalPendencies.value, 'id', data.id, data);
  }

  async function updateMaintenance(obj: any) {
    const data = await MaintenanceAPI().updateMaintenance(obj);

    maintenance.value = data;
    updateItemByField(allMaintenancesByPaginate.value, 'id', data.id, data);
    updateItemByField(allMaintenancesWithApprovalPendencies.value, 'id', data.id, data);
  }

  async function deleteMaintenance(id: number) {
    const data = await MaintenanceAPI().deleteMaintenance(id);

    removeItemByField(allMaintenancesByPaginate.value, 'id', data.id);
    removeItemByField(allMaintenancesWithApprovalPendencies.value, 'id', data.id);
  }

  async function saveMaintenance(obj: Record<string, string>) {
    const data = await MaintenanceAPI().saveMaintenance(obj);

    allMaintenancesByPaginate.value.unshift(data);
  }

  async function reportExtract(dates: { startDate: string; endDate: string }) {
    return await MaintenanceAPI().reportExtract(dates);
  }

  async function getMaintenanceCosts({ year, costCenters }) {
    const data = await MaintenanceAPI().getMaintenanceCosts({ year, costCenters });
    return data;
  }

  async function exportMaintenanceCostDataInExcel(year: [Number, String]) {
    const data = await MaintenanceAPI().exportMaintenanceCostDataInExcel(year);
    return data;
  }

  async function getMaintenanceChartData({
    filteredDate,
    filterType,
    costCentersToFilter,
    costCentersToHide,
  }) {
    const data = await MaintenanceAPI().getMaintenanceChartData({
      filteredDate,
      filterType,
      costCentersToFilter,
      costCentersToHide,
    });

    return data;
  }

  async function getMaintenanceQuantityByStatus({
    filteredDate,
    filterType,
    costCentersToFilter,
    costCentersToHide,
  }) {
    const data = await MaintenanceAPI().getMaintenaceQuantityByStatus({
      filteredDate,
      filterType,
      costCentersToFilter,
      costCentersToHide,
    });

    return data;
  }

  async function getMaintenancesByVehicle(vehicleId, { fields = [], relations = {} } = {}) {
    const data = await MaintenanceAPI().getMaintenancesByVehicle({
      vehicleId,
      fields,
      relations,
    });

    return data;
  }

  return {
    allMaintenances,
    allMaintenancesByPaginate,
    allMaintenancesWithApprovalPendencies,
    allMaintenancesCosts,
    maintenance,
    lastPage,
    total,
    totalMaintenancesWithApprovalPendencies,
    lastPageMaintenanceWithApprovalPendencies,
    allMaintenancesByPaginateWithLastMaintenance,
    getMaintenancesByVehicle,
    getMaintenanceQuantityByStatus,
    getMaintenanceChartData,
    exportMaintenanceCostDataInExcel,
    getMaintenanceCosts,
    reportExtract,
    saveMaintenance,
    deleteMaintenance,
    updateMaintenance,
    changeMaintenanceApprovalStatus,
    getMaintenancesWithApprovalPendenciesByPaginate,
    getMaintenancesByPaginate,
    getMaintenance,
    getAllMaintenances,
  };
});
