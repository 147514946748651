import HttpClient from '@/services/http-client';
import qs from 'qs';

class DashboardAPI extends HttpClient {
  async exportAssetStructure() {
    const response = await this.http.get('summaries/asset-structure/export');
    return response.data;
  }
  async getAssetStructureSummaryByFilterType({
    filterType,
    costCentersToFilter,
    costCentersToHide,
  }) {
    const response = await this.http.get('summaries/asset-structure', {
      params: {
        filterType,
        costCentersToFilter,
        costCentersToHide,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getVehicleTypeSummary({ costCentersToFilter, costCentersToHide }) {
    const response = await this.http.get('summaries/asset-structure/vehicle-type', {
      params: {
        costCentersToFilter,
        costCentersToHide,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getListCostCenter() {
    const response = await this.http.get('/cost-centers/user');

    return response.data;
  }
  async getHistoryOfSendingUnavailability(page: number, perPage: number) {
    const response = await this.http.get('/summaries/unavailable/historic', {
      params: { page, perPage },
    });
    return response.data;
  }

  async getUnavailable() {
    const response = await this.http.get('/summaries/unavailable');
    return response.data;
  }
  async reportExtract() {
    const response = await this.http.get('/summaries/unavailable/extract');
    return response.data;
  }
  async getVehicleUnavailable() {
    const response = await this.http.get('/summaries/unavailable/maintenance');
    return response.data;
  }
  async sendTemplateByEmail(payload) {
    const response = await this.http.post('/summaries/unavailable/send-email', payload);
    return response.data;
  }

  async getLastDestinations() {
    const response = await this.http.get('/summaries/unavailable/destinations/last');
    return response.data;
  }
}

export default () => new DashboardAPI();
