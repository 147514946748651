import { defineStore } from 'pinia';
import { ref } from 'vue';

import AuthAPI from './service';

export const useAuthStore = defineStore('authStore', () => {
  const token = ref(localStorage.getItem('token'));
  const user = ref({});

  async function signIn(obj: Record<string, string>) {
    const result = await AuthAPI().signIn(obj);

    if (result.status !== 200) return;

    const userToken = result?.data?.token;

    localStorage.setItem('token', userToken);
    token.value = userToken;
    user.value = result.data;
  }

  async function getDetailsUser() {
    const data = await AuthAPI().getDetails();

    user.value = data;
  }

  return {
    token,
    user,
    signIn,
    getDetailsUser,
  };
});
