import { defineStore } from 'pinia';
import { ref } from 'vue';

import IncidentRecordAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useIncidentRecordStore = defineStore('incidentRecordStore', () => {
  const allIncidentRecords = ref<Array<any>>([]);
  const incidentRecordsByPaginate = ref<Array<any>>([]);
  const incidentRecord = ref({});
  const documentTypes = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getIncidentRecord(id) {
    const data = await IncidentRecordAPI().show(id);

    incidentRecord.value = data;
  }

  async function createIncidentRecord(obj) {
    const data = await IncidentRecordAPI().create(obj);

    incidentRecordsByPaginate.value.push(data);
    total.value += 1;
  }

  async function updateIncidentRecord(obj: any) {
    const id = obj.get('id');

    const data = await IncidentRecordAPI().update(id, obj);

    updateItemByField(incidentRecordsByPaginate.value, 'id', data.id, data);
  }

  async function deleteIncidentRecord(id: number) {
    const data = await IncidentRecordAPI().delete(id);

    removeItemByField(incidentRecordsByPaginate.value, 'id', data.id);
  }

  async function getAllIncidentRecords() {
    const data = await IncidentRecordAPI().index();

    allIncidentRecords.value = data;
  }

  async function getIncidentRecordByPaginate({ filter, paginate }) {
    const data = await IncidentRecordAPI().paginate(filter, paginate.page, paginate.perPage);

    incidentRecordsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getIncidentRecordDocumentTypes() {
    const data = await IncidentRecordAPI().getDocumentTypes();

    documentTypes.value = data;
  }

  async function exportDocumentFile(id) {
    const data = await IncidentRecordAPI().exportDocumentFile(id);
    return data;
  }

  async function extractSheet() {
    const data = await IncidentRecordAPI().extractSheet();
    return data;
  }

  return {
    allIncidentRecords,
    incidentRecordsByPaginate,
    lastPage,
    total,
    incidentRecord,
    documentTypes,
    extractSheet,
    exportDocumentFile,
    getIncidentRecordDocumentTypes,
    getIncidentRecordByPaginate,
    getAllIncidentRecords,
    deleteIncidentRecord,
    updateIncidentRecord,
    createIncidentRecord,
    getIncidentRecord,
  };
});
