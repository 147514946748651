import HttpClient from '@/services/http-client';

class IdentifiedProblemAPI extends HttpClient {
  async index() {
    const response = await this.http.get('/incident-records');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/incident-records/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async create(data: Record<string, string>) {
    const response = await this.http.post('/incident-records', data);
    return response.data;
  }
  async update(id, data) {
    const response = await this.http.patch('/incident-records/' + id, data);
    return response.data;
  }
  async show(id: number) {
    const response = await this.http.get('/incident-records/' + id);
    return response.data;
  }
  async delete(id: number) {
    const response = await this.http.delete('/incident-records/' + id);
    return response.data;
  }

  async getDocumentTypes() {
    const response = await this.http.get('/incident-records/document-types');
    return response.data;
  }

  async exportDocumentFile(id) {
    const response = await this.http.get(`/incident-records/extract/file/${id}`);
    return response.data;
  }

  async extractSheet() {
    const response = await this.http.get(`/incident-records/extract/`);
    return response.data;
  }
}

export default () => new IdentifiedProblemAPI();
