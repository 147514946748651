import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import RentalContractAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

import { useVehicleStore } from '@/features/vehicles/store';
import getters from './getters';

interface RentalContract {
  [key: string]: any;
  contract_price_adjustments?: Array<any>;
}

export const useRentalContractStore = defineStore('rentalContractStore', () => {
  const unrelatedVehicles = ref<Array<any>>([]);
  const allRentalContracts = ref<Array<any>>([]);
  const allRentalContractsByPaginate = ref<Array<any>>([]);
  const rentalContract = ref<RentalContract>({});
  const lastPage = ref(1);
  const total = ref(0);
  const avalibleMonths = ref<Array<any>>([
    { name: 'Janeiro', id: 1 },
    { name: 'Fevereiro', id: 2 },
    { name: 'Março', id: 3 },
    { name: 'Abril', id: 4 },
    { name: 'Maio', id: 5 },
    { name: 'Junho', id: 6 },
    { name: 'Julho', id: 7 },
    { name: 'Agosto', id: 8 },
    { name: 'Setembro', id: 9 },
    { name: 'Outubro', id: 10 },
    { name: 'Novembro', id: 11 },
    { name: 'Dezembro', id: 12 },
  ]);

  const calculateRentalContractValue = computed(() =>
    getters.calculateRentalContractValue(rentalContract.value),
  );

  async function getVehicles({ id, filter, paginate }) {
    const data = await RentalContractAPI().getVehicles({
      id,
      filter,
      page: paginate.page,
      perPage: paginate.perPage,
    });

    const vehicleStore = useVehicleStore();

    vehicleStore.updateVehiclePaginationData(data);
  }

  async function getUnrelatedVehicles() {
    const data = await RentalContractAPI().getUnrelatedVehicles();

    unrelatedVehicles.value = data;
  }

  async function deleteRentalContract(id: number) {
    const data = await RentalContractAPI().deleteRentalContract(id);

    removeItemByField(allRentalContractsByPaginate.value, 'id', data.id);
  }

  async function updateRentalContract(obj: any) {
    const data = await RentalContractAPI().updateRentalContract(obj);

    updateItemByField(allRentalContractsByPaginate.value, 'id', data.id, data);
  }

  async function getAllRentalContracts({ fields = [], relations = {} } = {}) {
    const data = await RentalContractAPI().getAllRentalContracts(fields, relations);

    allRentalContracts.value = data;
  }

  const getAllRentalContractsByPaginate = async ({ filter, paginate }) => {
    const data = await RentalContractAPI().paginate(filter, paginate.page, paginate.perPage);

    allRentalContractsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  };

  async function saveRentalContract(obj: Record<string, string>) {
    const data = await RentalContractAPI().saveRentalContract(obj);

    allRentalContractsByPaginate.value.unshift(data);
  }

  async function getRentalContract(id: number) {
    const data = await RentalContractAPI().getRentalContract(id);

    rentalContract.value = data;
  }

  async function updateContractPriceAdjustments(obj: any) {
    const data = await RentalContractAPI().updateContractPriceAdjustments(obj);

    updateItemByField(rentalContract.value.contract_price_adjustments, 'id', data.id, data);
  }

  async function saveContractPriceAdjustments(obj: Record<string, string>) {
    const data = await RentalContractAPI().saveContractPriceAdjustments(obj);

    rentalContract.value?.contract_price_adjustments?.push(data);
  }

  async function deleteContractPriceAdjustments(id: number) {
    const data = await RentalContractAPI().deleteContractPriceAdjustments(id);

    removeItemByField(rentalContract.value?.contract_price_adjustments, 'id', data.id);
  }

  async function extractAll(type) {
    return RentalContractAPI().extractAll({ type });
  }

  return {
    unrelatedVehicles,
    allRentalContracts,
    allRentalContractsByPaginate,
    rentalContract,
    avalibleMonths,
    lastPage,
    total,
    calculateRentalContractValue,
    extractAll,
    deleteContractPriceAdjustments,
    saveContractPriceAdjustments,
    updateContractPriceAdjustments,
    getRentalContract,
    saveRentalContract,
    getAllRentalContractsByPaginate,
    getAllRentalContracts,
    updateRentalContract,
    deleteRentalContract,
    getUnrelatedVehicles,
    getVehicles,
  };
});
