import { defineStore } from 'pinia';
import { ref } from 'vue';

import SyncServices from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useSyncStore = defineStore('syncStore', () => {
  async function syncRequest(request) {
    await SyncServices().syncRequest(request);
  }

  return {
    syncRequest,
  };
});
