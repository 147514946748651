import { defineStore } from 'pinia';
import { ref } from 'vue';

import ReadjustmentIndexAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useReadjustmentIndexStore = defineStore('readjustmentIndexStore', () => {
  const allReadjustmentIndices = ref<Array<any>>([]);
  const readjustmentIndexByPaginate = ref<Array<any>>([]);
  const readjustmentIndices = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteReadjustmentIndex(obj: any) {
    const data = await ReadjustmentIndexAPI().deleteReadjustmentIndex(obj);

    removeItemByField(readjustmentIndexByPaginate.value, 'id', data.id);
  }

  async function updateReadjustmentIndex(obj: any) {
    const data = await ReadjustmentIndexAPI().updateReadjustmentIndex(obj);

    updateItemByField(readjustmentIndexByPaginate.value, 'id', data.id, data);
  }

  async function getReadjustmentIndicesByPaginate({ filter, paginate }) {
    const data = await ReadjustmentIndexAPI().paginate(filter, paginate.page, paginate.perPage);

    readjustmentIndexByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllReadjustmentIndices() {
    const data = await ReadjustmentIndexAPI().getAllReadjustmentIndices();

    allReadjustmentIndices.value = data;
  }

  async function saveReadjustmentIndex(obj: Record<string, string>) {
    const data = await ReadjustmentIndexAPI().saveReadjustmentIndex(obj);

    readjustmentIndexByPaginate.value.unshift(data);
  }

  return {
    allReadjustmentIndices,
    readjustmentIndexByPaginate,
    readjustmentIndices,
    lastPage,
    total,
    saveReadjustmentIndex,
    getAllReadjustmentIndices,
    getReadjustmentIndicesByPaginate,
    updateReadjustmentIndex,
    deleteReadjustmentIndex,
  };
});
