import HttpClient from '@/services/http-client';

class SettingAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/settings/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async updateSettingSystem({ id, ...data }: Record<string, string>) {
    const response = await this.http.patch('/settings/' + id, data);
    return response.data;
  }
}

export default () => new SettingAPI();
