import HttpClient from '@/services/http-client';
import qs from 'qs';

class AppraisalAPI extends HttpClient {
  async extractAll() {
    const response = await this.http.get('/appraisals/extract');
    return response.data;
  }

  async getAppraisal(id) {
    const response = await this.http.get(`/appraisals/${id}`);

    return response.data;
  }

  async extractByPeriod({ startDate, endDate }) {
    const response = await this.http.get('/appraisals/extract-period', {
      params: { startDate, endDate },
    });
    return response.data;
  }
  async getAllAppraisals({ year, costCentersToFilter, costCentersToHide, fields, relations }) {
    const response = await this.http.get('/appraisals', {
      params: { year, costCentersToFilter, costCentersToHide, fields, relations },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/appraisals/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveAppraisal(data: Record<string, string>) {
    const response = await this.http.post('/appraisals', data);
    return response.data;
  }
  async updateAppraisal(id, data) {
    const response = await this.http.patch('/appraisals/' + id, data);
    return response.data;
  }
  async deleteAppraisal(id) {
    const response = await this.http.delete('/appraisals/' + id);
    return response.data;
  }
  async exportData(id: Number) {
    const response = await this.http.get('/appraisals/extract/' + id);
    return response.data;
  }
  async exportDataPublicScreen(id: Number) {
    const response = await this.http.get('/appraisals/extract/public/' + id);
    return response.data;
  }
  async getAppraisalsSummaryByType({ appraisalType }) {
    const response = await this.http.get('/summaries/appraisal', {
      params: {
        appraisalType,
      },
    });

    return response.data;
  }
  async extractSummary() {
    const response = await this.http.get('/summaries/appraisal/extract');

    return response.data;
  }
}

export default () => new AppraisalAPI();
