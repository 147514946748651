export function useSummaries() {
  function formatCurrency(value) {
    return (
      value?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) ?? 'R$ 0.00'
    );
  }

  return { formatCurrency };
}
