import HttpClient from '@/services/http-client';
import qs from 'qs';

class InvoiceAPI extends HttpClient {
  async getAllInvoices(
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
  ) {
    const result = await this.http.get(`/invoices`, {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result.data;
  }

  async getInvoiceById(id: number) {
    const result = await this.http.get(`/invoices/${id}`);

    return result.data;
  }

  async paginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get(
      `/invoices/paginate?page=${page}&perPage=${perPage}&filter=${
        Object.keys(filter).length ? JSON.stringify(filter, undefined, 2) : ''
      }`,
    );
    return result.data;
  }
  async saveInvoice(data: Record<string, string>) {
    const result = await this.http.post('/invoices', data);
    return result.data;
  }
  async updateInvoice(id, data) {
    const result = await this.http.patch('/invoices/' + id, data);
    return result.data;
  }
  async exportData({ id, fieldToExtract }: { id: number; fieldToExtract: string }) {
    const result = await this.http.get(`/invoices/extract/${id}`, {
      params: {
        fieldToExtract,
      },
    });

    return result.data;
  }
  async reportExtract(dates: { startDate: string; endDate: string }) {
    const result = await this.http.get(
      `/invoices/report?startDate=${dates.startDate}&endDate=${dates.endDate}`,
    );
    return result.data;
  }
  async deleteInvoice(id: number) {
    const result = await this.http.delete('/invoices/' + id);
    return result.data;
  }

  async downloadSpreadsheetTemplateByCostType(costType: string) {
    const result = await this.http.get('/invoices/extract/template/', {
      params: { costType },
    });
    return result.data;
  }

  async getAllCostTypes() {
    const result = await this.http.get('/invoices/cost-types/');
    return result.data;
  }
}

export default () => new InvoiceAPI();
