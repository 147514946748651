import { defineStore } from 'pinia';
import { ref } from 'vue';

import MaintenanceOriginsAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useMaintenanceOriginStore = defineStore('maintenanceOriginStore', () => {
  const allMaintenanceOrigins = ref<Array<any>>([]);

  async function getAllMaintenanceOrigins() {
    const data = await MaintenanceOriginsAPI().getAllMaintenanceOrigins();

    allMaintenanceOrigins.value = data;
  }

  return {
    allMaintenanceOrigins,
    getAllMaintenanceOrigins,
  };
});
