export function useValidator() {
  function validateCpf(value) {
    let a = 0;
    let b = 0;
    if (value == '00000000000') return false;

    for (let i = 1; i <= 9; i++) a = a + parseInt(value.toString().substring(i - 1, i)) * (11 - i);
    b = (a * 10) % 11;

    if (b == 10 || b == 11) b = 0;
    if (b != parseInt(value.toString().substring(9, 10))) return false;

    a = 0;
    for (let i = 1; i <= 10; i++) a = a + parseInt(value.toString().substring(i - 1, i)) * (12 - i);
    b = (a * 10) % 11;

    if (b == 10 || b == 11) b = 0;
    if (b != parseInt(value.toString().substring(10, 11))) return false;
    return true;
  }

  function validateCnh(value) {
    if (value.toString().length !== 11) return false;
    return true;
  }

  function validateCnpj(value) {
    value = value.replace(/[^\d]+/g, '');

    if (value == '') return false;

    if (value.length != 14) return false;

    if (
      value == '00000000000000' ||
      value == '11111111111111' ||
      value == '22222222222222' ||
      value == '33333333333333' ||
      value == '44444444444444' ||
      value == '55555555555555' ||
      value == '66666666666666' ||
      value == '77777777777777' ||
      value == '88888888888888' ||
      value == '99999999999999'
    )
      return false;

    let length = value.length - 2;
    let numbers = value.substring(0, length);
    const digits = value.substring(length);
    let sum = 0;
    let after = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * after--;
      if (after < 2) after = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(0)) return false;

    length = length + 1;
    numbers = value.substring(0, length);
    sum = 0;
    after = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * after--;
      if (after < 2) after = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(1)) return false;

    return true;
  }

  return { validateCnh, validateCnpj, validateCpf };
}
