import { defineStore } from 'pinia';
import { ref } from 'vue';

import EquipmentsAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useEquipmentStore = defineStore('equipmentStore', () => {
  const allEquipments = ref<Array<any>>([]);
  const equipmentsByPaginate = ref<Array<any>>([]);
  const equipment = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteEquipment(id: number) {
    const data = await EquipmentsAPI().deleteEquipment(id);

    removeItemByField(equipmentsByPaginate.value, 'id', data.id);
  }

  async function updateEquipment(obj: any) {
    const data = await EquipmentsAPI().updateEquipment(obj);

    updateItemByField(equipmentsByPaginate.value, 'id', data.id, data);
  }

  async function getEquipmentsByPaginate({ filter, paginate }) {
    const data = await EquipmentsAPI().paginate(filter, paginate.page, paginate.perPage);

    equipmentsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllEquipments() {
    const data = await EquipmentsAPI().getAllEquipments();

    allEquipments.value = data;
  }

  async function saveEquipment(obj: Record<string, string>) {
    const data = await EquipmentsAPI().saveEquipment(obj);

    equipmentsByPaginate.value.unshift(data);
  }

  return {
    allEquipments,
    equipmentsByPaginate,
    lastPage,
    total,
    equipment,
    saveEquipment,
    getAllEquipments,
    getEquipmentsByPaginate,
    updateEquipment,
    deleteEquipment,
  };
});
