import { defineStore } from 'pinia';
import { ref } from 'vue';
import AdditionalInformationAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useAdditionalInformationStore = defineStore('additionalInformationStore', () => {
  const allAdditionalInformations = ref<Array<any>>([]);
  const additionalInformation = ref({});
  const additionalInformationsByPaginate = ref<Array<any>>([]);
  const lastPage = ref(0);
  const total = ref(0);

  async function deleteAdditionalInformation(id: number) {
    const data = await AdditionalInformationAPI().deleteAdditionalInformation(id);

    removeItemByField(additionalInformationsByPaginate.value, 'id', data.id);
  }

  async function updateAdditionalInformation(obj: any) {
    const data = await AdditionalInformationAPI().updateAdditionalInformation(obj);

    updateItemByField(additionalInformationsByPaginate.value, 'id', data.id, data);
  }

  async function getAllAdditionalInformations() {
    const data = await AdditionalInformationAPI().getAllAdditionalInformations();

    allAdditionalInformations.value = data;
  }

  async function getAdditionalInformationsByPaginate({ filter, paginate }) {
    const data = await AdditionalInformationAPI().paginate(filter, paginate.page, paginate.perPage);

    additionalInformationsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveAdditionalInformation(obj: Record<string, string>) {
    const data = await AdditionalInformationAPI().saveAdditionalInformation(obj);

    additionalInformationsByPaginate.value.unshift(data);
  }

  return {
    additionalInformation,
    additionalInformationsByPaginate,
    total,
    lastPage,
    allAdditionalInformations,
    deleteAdditionalInformation,
    updateAdditionalInformation,
    getAllAdditionalInformations,
    getAdditionalInformationsByPaginate,
    saveAdditionalInformation,
  };
});
