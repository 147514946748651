import { defineStore } from 'pinia';
import { ref } from 'vue';

import SettingAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useSettingStore = defineStore('settingStore', () => {
  const allSettingsSystem = ref<Array<any>>([]);
  const settingsSystemByPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getSettingSystemByPaginate({ filter, paginate }) {
    const data = await SettingAPI().paginate(filter, paginate.page, paginate.perPage);

    settingsSystemByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function updateSettingSystem(payload: Record<string, string>) {
    const data = await SettingAPI().updateSettingSystem(payload);

    updateItemByField(settingsSystemByPaginate.value, 'id', data.id, data);
  }

  return {
    allSettingsSystem,
    settingsSystemByPaginate,
    lastPage,
    total,
    updateSettingSystem,
    getSettingSystemByPaginate,
  };
});
