import HttpClient from '@/services/http-client';
class JustificationRecordInputOutputAPI extends HttpClient {
  async saveJustification(data: Record<string, string>) {
    const response = await this.http.post('/justification_record_input_output', data);
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get(
      `/justification_record_input_output/paginate?page=${page}&perPage=${perPage}&filter=${
        Object.keys(filter).length ? JSON.stringify(filter, undefined, 2) : ''
      }`,
    );
    return response.data;
  }
  async getAllJustifications() {
    const response = await this.http.get('/justification_record_input_output');
    return response.data;
  }
  async updateJustification({ id, ...data }) {
    const response = await this.http.patch('/justification_record_input_output/' + id, data);
    return response.data;
  }
  async deleteJustification(id) {
    const response = await this.http.delete('/justification_record_input_output/' + id);
    return response.data;
  }
}

export default () => new JustificationRecordInputOutputAPI();
