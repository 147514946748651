import $ from 'jquery';

export function useTreeGrid() {
  function onClickRow({ element }) {
    if (!$(element).hasClass('row-active')) {
      $('.row-active').removeClass('row-active');
      return $(element).addClass('row-active');
    }
    return $('.row-active').removeClass('row-active');
  }

  function formatNoMatches() {
    return 'Nenhum registro correspondente encontrado';
  }

  function headerStyle(column) {
    return {
      media: {
        classes: 'column-media',
      },
      total: {
        classes: 'column-total',
      },
    }[column.field];
  }

  function cellStyle(className) {
    const space = { 'vertical-align': 'top' };
    return {
      css: space,
      classes: className,
    };
  }

  function onPostBody(treeGridInstance) {
    treeGridInstance.treegrid({
      initialState: 'collapsed',
    });

    const totalLines = document.querySelectorAll('.is-line-total');

    for (const totalLine of totalLines) {
      totalLine.setAttribute('colspan', '3');
    }
  }

  function rowStyle(row) {
    if (row.isTotal) {
      return {
        classes: 'is-month-total',
      };
    }
    if (row.isConsolidation) {
      return {
        classes: 'is-line-root',
      };
    }
    return {};
  }

  return { onClickRow, formatNoMatches, headerStyle, onPostBody, cellStyle, rowStyle };
}
