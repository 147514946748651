import HttpClient from '@/services/http-client';

class OperationalBasesAPI extends HttpClient {
  async getAllPaginate({ page, perPage, filter }) {
    const response = await this.http.get('/operational-bases/paginate', {
      params: {
        page,
        perPage,
        filter,
      },
    });
    return response.data;
  }

  async getAll() {
    const response = await this.http.get('/operational-bases/');
    return response.data;
  }

  async update(data) {
    const response = await this.http.patch(`/operational-bases/${data.id}`, data);
    return response.data;
  }

  async create(data) {
    const response = await this.http.post(`/operational-bases/`, data);
    return response.data;
  }

  async delete(id: number) {
    const response = await this.http.delete(`/operational-bases/${id}`);
    return response.data;
  }
}

export default () => new OperationalBasesAPI();
