import HttpClient from '@/services/http-client';

class RoleAPI extends HttpClient {
  async getRolesByPaginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get('/roles/paginate', { params: { filter, page, perPage } });
    return result.data;
  }
  async getAllRoles() {
    const result = await this.http.get('/roles');
    return result.data;
  }
  async saveRole(data: Record<string, string>) {
    const result = await this.http.post('/roles', data);
    return result.data;
  }
  async updateRole({ id, ...data }) {
    const result = await this.http.patch('roles/' + id, data);
    return result.data;
  }
  async deleteRole(id) {
    const result = await this.http.delete('roles/' + id);
    return result.data;
  }
}

export default () => new RoleAPI();
