import Vue, { provide } from 'vue';
import { getErrorMessage } from '../utils/errorHelper';

export function useSwalProvider() {
  provide('$error', function(message, errorEntity) {
    const errorMessage = typeof message !== 'string' ? getErrorMessage(message) : message;

    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: `${errorMessage} ${errorEntity ? getErrorMessage(errorEntity) : ''}`,
    });
  });

  provide('$warning', function(message = '') {
    return Vue.swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Atenção',
      showConfirmButton: false,
      html: `${message}!`,
      timer: 1500,
    });
  });

  provide('$success', function(value, message = '') {
    if (message) {
      return Vue.swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> ${message}!`,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return Vue.swal.fire({
      position: 'center',
      icon: 'success',
      html: `<b>${value}</b> salvo com sucesso!`,
      showConfirmButton: false,
      timer: 1500,
    });
  });

  provide('$ask', function(value, title = 'Aviso') {
    return Vue.swal.fire({
      position: 'center',
      icon: 'warning',
      title: title,
      text: value,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Continuar`,
      cancelButtonText: `Cancelar`,
    });
  });

  provide('$delete', function(value, message = '') {
    if (typeof value === 'object') return;

    if (message) {
      return Vue.swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> ${message}!`,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return Vue.swal.fire({
      position: 'center',
      icon: 'success',
      html: `<b>${value}</b> deletado com sucesso!`,
      showConfirmButton: false,
      timer: 1500,
    });
  });
}
