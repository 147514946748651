import { defineStore } from 'pinia';
import { ref } from 'vue';

import UnitAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useUnitStore = defineStore('unitStore', () => {
  const allUnits = ref<Array<any>>([]);
  const unitsByPaginate = ref<Array<any>>([]);
  const unit = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteUnit(obj: any) {
    const data = await UnitAPI().deleteUnit(obj);

    removeItemByField(unitsByPaginate.value, 'id', data.id);
  }

  async function updateUnit(obj: any) {
    const data = await UnitAPI().updateUnit(obj);

    updateItemByField(unitsByPaginate.value, 'id', data.id, data);
  }

  async function getUnitsByPaginate({ filter, paginate }) {
    const data = await UnitAPI().paginate(filter, paginate.page, paginate.perPage);

    unitsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllUnits({ fields = [], relations = {} } = {}) {
    const data = await UnitAPI().getAllUnits(fields, relations);

    allUnits.value = data;
  }

  async function saveUnit(obj: Record<string, string>) {
    const data = await UnitAPI().saveUnit(obj);

    unitsByPaginate.value.unshift(data);
  }

  return {
    allUnits,
    unitsByPaginate,
    unit,
    lastPage,
    total,
    deleteUnit,
    updateUnit,
    getUnitsByPaginate,
    getAllUnits,
    saveUnit,
  };
});
