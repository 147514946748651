import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleCostCenterAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useRentalFeeStore = defineStore('rentalFeeStore', () => {
  const rentalFeesByPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getRentalFeesByVehicle({ id, filter, paginate }) {
    const data = await VehicleCostCenterAPI().paginate(id, filter, paginate.page, paginate.perPage);

    rentalFeesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveRentalFee(payload: Object) {
    const data = await VehicleCostCenterAPI().saveRentalFee(payload);

    rentalFeesByPaginate.value.unshift(data);
  }

  async function updateRentalFee(payload: any) {
    const data = await VehicleCostCenterAPI().updateRentalFee(payload);

    updateItemByField(rentalFeesByPaginate.value, 'id', data.id, data);
  }

  async function deleteRentalFee(id: [Number, String]) {
    const data = await VehicleCostCenterAPI().deleteRentalFee(id);

    removeItemByField(rentalFeesByPaginate.value, 'id', data.id);
  }

  return {
    rentalFeesByPaginate,
    lastPage,
    total,
    deleteRentalFee,
    updateRentalFee,
    saveRentalFee,
    getRentalFeesByVehicle,
  };
});
