import HttpClient from '@/services/http-client';

class IdentifiedProblemAPI extends HttpClient {
  async getAllIdentifiedProblems() {
    const response = await this.http.get('/identified-problems');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/identified-problems/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveIdentifiedProblem(data: Record<string, string>) {
    const response = await this.http.post('/identified-problems', data);
    return response.data;
  }
  async updateIdentifiedProblem({ id, ...data }) {
    const response = await this.http.patch('/identified-problems/' + id, data);
    return response.data;
  }
  async deleteIdentifiedProblem(id: number) {
    const response = await this.http.delete('/identified-problems/' + id);
    return response.data;
  }
}

export default () => new IdentifiedProblemAPI();
