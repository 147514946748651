import HttpClient from '@/services/http-client';
import qs from 'qs';

class MaintenanceAPI extends HttpClient {
  async getMaintenance(id) {
    const response = await this.http.get(`/maintenances/${id}`);

    return response.data;
  }

  async getAllMaintenances(
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
  ) {
    const response = await this.http.get(`/maintenances`, {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async paginateMaintenancesWithApprovalPendencies(filter: string, page: string, perPage: string) {
    const response = await this.http.get('/maintenances/paginate/approval-pendencies', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async changeMaintenanceApprovalStatus(maintenanceId, statusId) {
    const response = await this.http.patch(
      `/maintenances/${maintenanceId}/approval-status/${statusId}`,
    );

    return response.data;
  }

  async paginate(filter: string, page: string, perPage: string) {
    const response = await this.http.get('/maintenances/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async getMaintenancesByVehicle({ vehicleId, fields, relations }) {
    const response = await this.http.get(`/maintenances/vehicles/${vehicleId}`, {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return response.data;
  }

  async getMaintenanceChartData({
    filteredDate,
    filterType,
    costCentersToFilter,
    costCentersToHide,
  }) {
    const response = await this.http.get('/maintenances/chart', {
      params: {
        filter: filteredDate,
        filterType,
        costCentersToFilter,
        costCentersToHide,
      },
    });

    return response.data;
  }

  async getMaintenaceQuantityByStatus({
    filteredDate,
    filterType,
    costCentersToFilter,
    costCentersToHide,
  }) {
    const response = await this.http.get('/maintenances/quantity-by-status', {
      params: {
        filter: filteredDate,
        filterType,
        costCentersToFilter,
        costCentersToHide,
      },
    });

    return response.data;
  }

  async updateMaintenance({ id, ...data }) {
    const response = await this.http.patch('/maintenances/' + id, data);
    return response.data;
  }

  async deleteMaintenance(id: number) {
    const response = await this.http.delete('/maintenances/' + id);
    return response.data;
  }

  async saveMaintenance(data) {
    const response = await this.http.post('/maintenances', data);
    return response.data;
  }

  async getMaintenanceCosts({ year, costCenters }) {
    const response = await this.http.get('summaries/maintenances/', {
      params: { year, costCenters },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async exportMaintenanceCostDataInExcel(year: [Number, String]) {
    const result = await this.http.get('summaries/maintenances/extract', {
      params: { year },
    });
    return result.data;
  }

  async reportExtract({ startDate, endDate }) {
    const response = await this.http.get('/maintenances/extract', {
      params: { startDate, endDate },
    });
    return response.data;
  }
}

export default () => new MaintenanceAPI();
