import HttpClient from '@/services/http-client';

class NoteTypeAPI extends HttpClient {
  async getAllNoteTypes() {
    const response = await this.http.get('/note-types');
    return response.data;
  }
}

export default () => new NoteTypeAPI();
