import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import VehicleMovementRecordAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useVehicleMovementRecordStore = defineStore('vehicleMovementRecordStore', () => {
  const allVehicleMovementRecords = ref<Array<any>>([]);
  const vehicleMovementRecord = ref({});
  const allVehicleMovementRecordStatuses = ref<Array<any>>([]);
  const allVehicleMovementRecordsSummary = ref<Array<any>>([]);
  const totalSummary = ref(0);
  const lastPageSummary = ref(1);
  const lastPage = ref(1);
  const total = ref(0);
  const filter = ref({
    search: null,
    initDate: null,
    finalDate: null,
    useVisitorsTable: false,
    operationalBases: [{ id: '', name: 'Todas' }],
    status: null,
  });

  const getVehiclesMovementsRecords = computed(() =>
    getters.getVehiclesMovementsRecords(allVehicleMovementRecords.value),
  );

  async function getVehicleMovementRecordsByPaginate({ filter, paginate, type }) {
    const data = await VehicleMovementRecordAPI().paginate(
      filter,
      paginate.page,
      paginate.perPage,
      type,
    );

    if (type === 'summary') {
      allVehicleMovementRecordsSummary.value = data.data;
      lastPageSummary.value = data.lastPage;
      totalSummary.value = data.total;
      return;
    }

    allVehicleMovementRecords.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveVehicleMovementRecord(obj: Record<string, string>) {
    const data = await VehicleMovementRecordAPI().saveVehicleMovementRecord(obj);

    allVehicleMovementRecords.value.unshift(data);
    total.value += 1;

    return data;
  }

  async function updateVehicleMovementRecord(obj: Record<string, string>) {
    const data = await VehicleMovementRecordAPI().updateVehicleMovementRecord(obj);

    allVehicleMovementRecords.value = allVehicleMovementRecords.value.map((record) => {
      if (record.id === data.id || (data.uuid && record?.uuid === data?.uuid)) return { ...data };
      return record;
    });

    allVehicleMovementRecordsSummary.value = allVehicleMovementRecordsSummary.value.map(
      (record) => {
        if (record.id === data.id || (data.uuid && record?.uuid === data?.uuid)) return { ...data };
        return record;
      },
    );
    return data;
  }

  async function deleteVehicleMovementRecord({ id, uuid }: { id: number; uuid: string }) {
    !uuid && (await VehicleMovementRecordAPI().deleteVehicleMovementRecord(id));

    allVehicleMovementRecords.value = allVehicleMovementRecords.value.filter(
      (record) => record.id !== id || record.uuid !== uuid,
    );

    allVehicleMovementRecordsSummary.value = allVehicleMovementRecordsSummary.value.filter(
      (record) => record.id !== id || record.uuid !== uuid,
    );
  }

  async function exportByType(data: {
    startDate: string;
    endDate: string;
    useVisitorsTable: boolean;
    type: string;
  }) {
    return VehicleMovementRecordAPI().exportByType(data);
  }

  async function getLastVehicleMovementRecord({ column, value }) {
    return VehicleMovementRecordAPI().getLastVehicleMovementRecord({ column, value });
  }

  async function getVehicleMovementRecordStatuses() {
    const data = await VehicleMovementRecordAPI().getVehicleMovementRecordStatuses();

    allVehicleMovementRecordStatuses.value = data;
  }

  async function setVehicleMovementRecordsFilter(filterData) {
    filter.value = filterData;
  }

  return {
    allVehicleMovementRecords,
    vehicleMovementRecord,
    allVehicleMovementRecordStatuses,
    allVehicleMovementRecordsSummary,
    lastPageSummary,
    totalSummary,
    lastPage,
    total,
    filter,
    getVehiclesMovementsRecords,
    exportByType,
    getVehicleMovementRecordsByPaginate,
    saveVehicleMovementRecord,
    updateVehicleMovementRecord,
    deleteVehicleMovementRecord,
    getLastVehicleMovementRecord,
    getVehicleMovementRecordStatuses,
    setVehicleMovementRecordsFilter,
  };
});
