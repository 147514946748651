import { defineStore } from 'pinia';
import { ref } from 'vue';

import RotationAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useRotationStore = defineStore('RotationStore', () => {
  const allRotations = ref<Array<any>>([]);
  const allRotationsByPaginate = ref<Array<any>>([]);
  const rotation = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteRotation(id: number) {
    const data = await RotationAPI().deleteRotation(id);

    removeItemByField(allRotationsByPaginate.value, 'id', data.id);
  }

  async function updateRotation(obj: any) {
    const data = await RotationAPI().updateRotation(obj);

    updateItemByField(allRotationsByPaginate.value, 'id', data.id, data);
  }

  async function getAllRotations() {
    const data = await RotationAPI().getAllRotations();

    allRotations.value = data;
  }

  async function getAllRotationsByPaginate({ filter, paginate }) {
    const data = await RotationAPI().paginate(filter, paginate.page, paginate.perPage);

    allRotationsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveRotation(obj: Record<string, string>) {
    const data = await RotationAPI().saveRotation(obj);

    allRotationsByPaginate.value.unshift(data);
  }

  return {
    allRotations,
    allRotationsByPaginate,
    rotation,
    lastPage,
    total,
    saveRotation,
    getAllRotationsByPaginate,
    getAllRotations,
    updateRotation,
    deleteRotation,
  };
});
