import { defineStore } from 'pinia';
import { ref } from 'vue';

import SupplierAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useSupplierStore = defineStore('supplierStore', () => {
  const allSuppliers = ref<Array<any>>([]);
  const allSuppliersByPaginate = ref<Array<any>>([]);
  const supplier = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function getAllSuppliers({ fields = [], relations = {} } = {}) {
    const data = await SupplierAPI().getAllSuppliers(fields, relations);

    allSuppliers.value = data;
  }

  async function getAllSuppliersByPaginate({ filter, paginate }) {
    const data = await SupplierAPI().paginate(filter, paginate.page, paginate.perPage);

    allSuppliersByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveSupplier(obj: Record<string, string>) {
    const data = await SupplierAPI().saveSupplier(obj);

    allSuppliersByPaginate.value.unshift(data);
  }

  async function updateSupplier(obj: any) {
    const data = await SupplierAPI().updateSupplier(obj);

    updateItemByField(allSuppliersByPaginate.value, 'id', data.id, data);
  }

  async function deleteSupplier(id: number) {
    const data = await SupplierAPI().deleteSupplier(id);

    removeItemByField(allSuppliersByPaginate.value, 'id', data.id);
  }

  async function getVehiclesByInsurer() {
    return SupplierAPI().getVehiclesByInsurer();
  }

  return {
    allSuppliers,
    allSuppliersByPaginate,
    lastPage,
    total,
    supplier,
    getVehiclesByInsurer,
    deleteSupplier,
    updateSupplier,
    saveSupplier,
    getAllSuppliersByPaginate,
    getAllSuppliers,
  };
});
