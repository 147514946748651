import HttpClient from '@/services/http-client';

class MaintenanceSchedulingAPI extends HttpClient {
  async getAllMaintenanceSchedulings() {
    const response = await this.http.get('/maintenance-schedulings');
    return response.data;
  }

  async getMaintenanceSchedulingById(id: number) {
    const response = await this.http.get(`/maintenance-schedulings/${id}`);
    return response.data;
  }

  async paginate(filter: Object, page: number, perPage: number) {
    const response = await this.http.get('/maintenance-schedulings/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async saveMaintenanceScheduling(data: Record<string, string>) {
    const response = await this.http.post('/maintenance-schedulings', data);
    return response.data;
  }

  async updateMaintenanceScheduling({ id, ...data }) {
    const response = await this.http.patch('/maintenance-schedulings/' + id, data);
    return response.data;
  }

  async deleteMaintenanceScheduling(id: number) {
    const response = await this.http.delete('/maintenance-schedulings/' + id);
    return response.data;
  }

  async reportExtract(id: number) {
    const response = await this.http.get('maintenance-schedulings/extract/' + id);
    return response.data;
  }

  async extractAll({ type }) {
    const response = await this.http.get('maintenance-schedulings/extract', {
      params: { type },
    });
    return response.data;
  }
}

export default () => new MaintenanceSchedulingAPI();
