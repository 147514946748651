import HttpClient from '@/services/http-client';

class VehicleCostCenterAPI extends HttpClient {
  async paginate(id: [Number, String], filter: Object, page: number, perPage: number) {
    const result = await this.http.get('vehicle-transfer/paginate', {
      params: { id, filter, page, perPage },
    });
    return result.data;
  }
  async saveVehicleTransfer(payload: any) {
    const response = await this.http.post('/vehicle-transfer', payload);
    return response.data;
  }
}

export default () => new VehicleCostCenterAPI();
