import HttpClient from '@/services/http-client';

class UserAPI extends HttpClient {
  async getAllUsers() {
    const result = await this.http.get('/users');
    return result.data;
  }
  async createUser(data: Record<string, string>) {
    const result = await this.http.post('/users', data);
    return result.data;
  }
  async updateUser({ id, ...data }) {
    const result = await this.http.patch('/users/' + id, { id, ...data });
    return result.data;
  }
  async deleteUser(id) {
    const result = await this.http.delete('/users/' + id);
    return result.data;
  }
  async getUser(id) {
    const result = await this.http.get('/users/' + id);
    return result.data;
  }

  async paginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get('/users/paginate', {
      params: { page, perPage, filter },
    });

    return result.data;
  }
}

export default () => new UserAPI();
