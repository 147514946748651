import HttpClient from '@/services/http-client';

class RotationAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/rotations/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveRotation(data: Record<string, string>) {
    const response = await this.http.post('/rotations', data);
    return response.data;
  }
  async updateRotation({ id, ...data }) {
    const response = await this.http.patch('/rotations/' + id, data);
    return response.data;
  }
  async deleteRotation(id) {
    const response = await this.http.delete('/rotations/' + id);
    return response.data;
  }
  async getAllRotations() {
    const response = await this.http.get('/rotations');
    return response.data;
  }
}

export default () => new RotationAPI();
