export function useValidationMessage() {
  function status({ $error = null, $dirty = null } = {}) {
    if (!$dirty) return;

    return $error ? 'border rounded border-danger' : 'border rounded border-success';
  }

  function checkArrayLength(label, field, required = false) {
    if (!field?.minLength && field?.$params?.minLength) {
      return `${label} deve possuir ao menos ${field?.$params?.minLength?.min} item(s).`;
    }
    if (!field?.maxLength && field?.$params?.maxLength) {
      return `${label} deve possuir no maximo ${field?.$params?.maxLength?.max} item(s).`;
    }
    if (required) {
      return 'Campo é obrigatório.';
    }
    return '';
  }

  function checkPassword(label, field, required = false) {
    if (!field?.minLength && field?.$params?.minLength) {
      return `${label} deve possuir ao menos ${field?.$params?.minLength?.min} caracteres.`;
    }

    if (!field?.containsUppercase && field?.$params?.containsUppercase) {
      return `${label} deve possuir no minimo 1 caracter maisculo.`;
    }

    if (!field?.containsSpecial && field?.$params?.containsSpecial) {
      return `${label} deve possuir no minimo 1 caracter especial.`;
    }

    if (!field?.containsNumber && field?.$params?.containsNumber) {
      return `${label} deve possuir no minimo 1 numero.`;
    }

    if (required) {
      return 'Campo é obrigatório.';
    }
    return '';
  }

  function checkValue(label, field, required = false) {
    const alphaNumType = field?.$params?.alphaNum;
    const numericType = field?.$params?.numeric;
    const minLength = field?.$params?.minLength;
    const maxLength = field?.$params?.maxLength;

    if (!field?.alphaNum && alphaNumType) {
      return `${label} deve ter letras e números.`;
    }
    if (!field?.numeric && numericType) {
      return `${label} deve ter somente números.`;
    }
    if (!field?.minLength && minLength) {
      return `${label} deve possuir pelo menos ${minLength?.min} caracteres.`;
    }
    if (!field?.maxLength && maxLength) {
      return `${label} deve possuir menos de ${maxLength?.max} caracteres.`;
    }
    if (required) {
      return 'Campo é obrigatório.';
    }
    return '';
  }

  return { checkValue, checkPassword, checkArrayLength, status };
}
