import HttpClient from '@/services/http-client';

class FileDownloadServiceAPI extends HttpClient {
  async downloadFile(fileName) {
    const response = await this.http.get('file/' + fileName);
    return response.data;
  }
}

export default () => new FileDownloadServiceAPI();
