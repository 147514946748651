import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleCostCenterAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

import { useVehicleStore } from '@/features/vehicles/store';

export const useVehicleCostCenterStore = defineStore('vehicleCostCenterStore', () => {
  const vehicleCostCentersByPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getCostCentersByVehicle({ id, filter, paginate }) {
    const data = await VehicleCostCenterAPI().paginate(id, filter, paginate.page, paginate.perPage);

    vehicleCostCentersByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveVehicleTransfer(payload: Object) {
    const data = await VehicleCostCenterAPI().saveVehicleTransfer(payload);

    const vehicleStore = useVehicleStore();

    vehicleStore.updateVehicleData(data);
  }

  return {
    vehicleCostCentersByPaginate,
    lastPage,
    total,
    getCostCentersByVehicle,
    saveVehicleTransfer,
  };
});
