import HttpClient from '@/services/http-client';

const currentYear = new Date().getFullYear();

class RentalCostAPI extends HttpClient {
  async exportRentalCostDataInExcel(year: [Number, String]) {
    const response = await this.http.get('summaries/rental/extract', { params: { year } });
    return response.data;
  }
  async fetchRentalCostSummary(year = currentYear, costCenters = []) {
    const response = await this.http.get('summaries/rental', { params: { year, costCenters } });
    return response.data;
  }
}

export default () => new RentalCostAPI();
