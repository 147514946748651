import { orderValue } from '@/utils/Array';

const calculateRentalContractValue = (rentalContract: any) => {
  const readjustmentValues = rentalContract.contract_price_adjustments || [];
  let total = rentalContract.value;

  readjustmentValues.sort((valueOne, valueTwo) => {
    const valueOneFormatter = valueOne.date.toString().split('T')[0];
    const valueTwoFormatter = valueTwo.date.toString().split('T')[0];
    return orderValue(new Date(valueOneFormatter), new Date(valueTwoFormatter));
  });

  return readjustmentValues.map((element) => {
    const percentual = (element.percentage * 100) / total;
    total = percentual * 100 + total;
    element.total = total;
    return element;
  });
};

export default { calculateRentalContractValue };
