import HttpClient from '@/services/http-client';

class VehicleGroupAPI extends HttpClient {
  async getAllVehicleGroups() {
    const response = await this.http.get('/vehicle-groups');
    return response.data;
  }
  async saveVehicleGroup(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-groups', data);
    return response.data;
  }
  async updateVehicleGroup({ id, ...data }) {
    const response = await this.http.patch('/vehicle-groups/' + id, data);
    return response.data;
  }
  async deleteVehicleGroup(id) {
    const response = await this.http.delete('/vehicle-groups/' + id);
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicle-groups/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
}

export default () => new VehicleGroupAPI();
