import HttpClient from '@/services/http-client';
import qs from 'qs';

class RentalContractAPI extends HttpClient {
  async getVehicles({ id, filter, page, perPage }) {
    const response = await this.http.get('/rental-contracts/vehicles', {
      params: { id, filter, page, perPage },
    });
    return response.data;
  }

  async getUnrelatedVehicles() {
    const response = await this.http.get('/rental-contracts/unrelated-vehicles');
    return response.data;
  }

  async saveRentalContract(data: Record<string, string>) {
    const response = await this.http.post('/rental-contracts', data);
    return response.data;
  }

  async updateRentalContract({ id, ...data }) {
    const response = await this.http.patch('/rental-contracts/' + id, data);
    return response.data;
  }

  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/rental-contracts/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async deleteRentalContract(id: number) {
    const response = await this.http.delete('/rental-contracts/' + id);
    return response.data;
  }

  async getAllRentalContracts(
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
  ) {
    const response = await this.http.get('/rental-contracts', {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getRentalContract(id) {
    const response = await this.http.get('/rental-contracts/' + id);
    return response.data;
  }

  async saveContractPriceAdjustments(data: Record<string, string>) {
    const response = await this.http.post('/rental-contracts/contract-readjustment', data);
    return response.data;
  }

  async updateContractPriceAdjustments({ id, ...data }) {
    const response = await this.http.patch('/rental-contracts/contract-readjustment/' + id, data);
    return response.data;
  }

  async deleteContractPriceAdjustments(id) {
    const response = await this.http.delete('/rental-contracts/contract-readjustment/' + id);
    return response.data;
  }

  async extractAll({ type }) {
    const response = await this.http.get('/rental-contracts/extract', {
      params: { type },
    });
    return response.data;
  }
}

export default () => new RentalContractAPI();
