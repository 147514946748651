import HttpClient from '@/services/http-client';

class AppraisalTypeAPI extends HttpClient {
  async getAllAppraisalTypes() {
    const response = await this.http.get('/appraisal-types');
    return response.data;
  }
}

export default () => new AppraisalTypeAPI();
