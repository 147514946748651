import { defineStore } from 'pinia';
import { ref } from 'vue';

import TrackersAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useTrackerCostStore = defineStore('trackerCostStore', () => {
  const allTrackersCostByPaginate = ref<Array<any>>([]);
  const allTrackerCostServiceTypes = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getTrackerCostsPaginate({ page, perPage, filter }) {
    const data = await TrackersAPI().paginate({ page, perPage, filter });

    allTrackersCostByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveTrackerCosts({ data, referenceMonth }) {
    return TrackersAPI().create(data, referenceMonth);
  }

  async function deleteTrackerCost(id: number) {
    return TrackersAPI().delete(id);
  }

  async function getAllTrackerCostServiceTypes() {
    const data = await TrackersAPI().getAllTrackerCostServiceTypes();

    allTrackerCostServiceTypes.value = data;
  }

  async function getTrackerCostSummary({ year, costCenters }) {
    return TrackersAPI().getTrackerCostSummary({ year, costCenters });
  }

  async function extractTrackerCostSummariesByYear(year: number) {
    return TrackersAPI().extractTrackerCostByYear(year);
  }

  return {
    allTrackersCostByPaginate,
    allTrackerCostServiceTypes,
    lastPage,
    total,
    extractTrackerCostSummariesByYear,
    getTrackerCostSummary,
    getAllTrackerCostServiceTypes,
    deleteTrackerCost,
    saveTrackerCosts,
    getTrackerCostsPaginate,
  };
});
