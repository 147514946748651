import HttpClient from '@/services/http-client';

class MaintenanceTypeAPI extends HttpClient {
  async getAllMaintenanceTypes() {
    const response = await this.http.get('/maintenance-types');
    return response.data;
  }
}

export default () => new MaintenanceTypeAPI();
