import { defineStore } from 'pinia';
import { ref } from 'vue';

import FuelSupplyAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useFuelSupplyStore = defineStore('fuelSupplyStore', () => {
  const allFuelCosts = ref<Array<any>>([]);
  const fuelSupplies = ref<Array<any>>([]);
  const newFuelCost = ref<Array<any>>([]);
  const allUsageErrors = ref<Array<any>>([]);
  const historicSendingFuelSupply = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);
  const loadingData = ref(false);

  async function exportFuelCostDataByYearInEmail(year: [Number, String]) {
    const result = await FuelSupplyAPI().exportFuelCostDataByYearInEmail(year);
    return result;
  }

  async function fetchFuelCostTreeByYear({ year, costCenters, fuelCostAverage }) {
    const data = await FuelSupplyAPI().fetchFuelCostTreeByYear({
      year,
      costCenters,
      fuelCostAverage,
    });

    return data;
  }

  function updateFuelSuppliesData(data) {
    fuelSupplies.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getFuelSupplies({ filter, paginate }) {
    const data = await FuelSupplyAPI().paginate(filter, paginate.page, paginate.perPage);

    updateFuelSuppliesData(data);
  }

  async function getAllErrors() {
    const data = await FuelSupplyAPI().getAllUsageErrors();

    allUsageErrors.value = data;
  }

  async function extractInconsistencySheet() {
    const data = await FuelSupplyAPI().extractInconsistencySheet();

    return data;
  }

  function removeVehicleByPlateFromUsageErrors(plate: string) {
    allUsageErrors.value = allUsageErrors.value.filter((usageError) => usageError.plate !== plate);
  }

  async function removeVehicleListErrors(plate: string) {
    removeVehicleByPlateFromUsageErrors(plate);
  }

  async function saveFuelSupply(data: object) {
    const result = await FuelSupplyAPI().saveFuelSupply(data);
    return result;
  }

  async function reportExtract(dates: { startDate: string; endDate: string }) {
    const data = await FuelSupplyAPI().extract(dates);
    return data;
  }

  async function getHistoryOfSendingFuelSupply({ page, perPage }) {
    const result = await FuelSupplyAPI().getHistoryOfSendingFuelSupply(page, perPage);

    historicSendingFuelSupply.value = result.data;
    total.value = result.total;
    lastPage.value = result.lastPage;
  }

  return {
    allFuelCosts,
    fuelSupplies,
    newFuelCost,
    allUsageErrors,
    historicSendingFuelSupply,
    lastPage,
    total,
    loadingData,
    getHistoryOfSendingFuelSupply,
    reportExtract,
    saveFuelSupply,
    removeVehicleListErrors,
    removeVehicleByPlateFromUsageErrors,
    extractInconsistencySheet,
    getAllErrors,
    getFuelSupplies,
    fetchFuelCostTreeByYear,
    exportFuelCostDataByYearInEmail,
    updateFuelSuppliesData,
  };
});
