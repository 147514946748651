import HttpClient from '@/services/http-client';
import qs from 'qs';

class TrafficTicketAPI extends HttpClient {
  async getAllTrafficTicketsForExport() {
    const response = await this.http.get('traffic-tickets/export');
    return response.data;
  }

  async getRealInfractorByVehicleMovementRecord({ infractionDate, vehicle }) {
    const response = await this.http.get(
      'traffic-tickets/real-infractor-by-vehicle-movement-record',
      {
        params: { infractionDate, vehicle },
      },
    );

    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('traffic-tickets/paginate', {
      params: { filter, page, perPage },
    });

    return response.data;
  }

  async getNotificationsWithConductorIdentificationPendencies(page, perPage) {
    const response = await this.http.get(
      'traffic-tickets/paginate/notifications/identification-pendencies',
      {
        params: { page, perPage },
      },
    );

    return response.data;
  }

  async saveTrafficTicket(data: Record<string, string>) {
    const response = await this.http.post('traffic-tickets', data);
    return response.data;
  }
  async updateTrafficTicket(id, data) {
    const response = await this.http.patch('traffic-tickets/' + id, data);
    return response.data;
  }
  async deleteTrafficTicket(id) {
    const response = await this.http.delete('traffic-tickets/' + id);
    return response.data;
  }
  async exportData(id: Number, fileToExtract: string) {
    const response = await this.http.post('traffic-tickets/extract/' + id, { fileToExtract });
    return response.data;
  }

  async getAllTicketsToSendByEmail() {
    const response = await this.http.get('traffic-tickets/tickets-to-email');

    return response.data;
  }

  async getHistoryOfSendingTrafficTickets(page, perPage) {
    const response = await this.http.get('/traffic-tickets/historic', {
      params: { page, perPage },
    });

    return response.data;
  }

  async sendTemplateByEmail(payload) {
    const response = await this.http.post('/traffic-tickets/send-email', payload);
    return response.data;
  }

  async getAllTrafficTickets({
    filterDate,
    filterType,
    costCentersToHide,
    costCentersToFilter,
    fields,
    relations,
  }) {
    const response = await this.http.get('/traffic-tickets/', {
      params: {
        filter: filterDate,
        filterType,
        costCentersToHide,
        costCentersToFilter,
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getRealInfractorRanking({
    filterDate,
    filterType,
    costCentersToHide,
    costCentersToFilter,
  }) {
    const response = await this.http.get('/traffic-tickets/real-infractor-ranking', {
      params: {
        filter: filterDate,
        filterType,
        costCentersToHide,
        costCentersToFilter,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getTrafficTicketById(id) {
    const response = await this.http.get(`/traffic-tickets/${id}`);

    return response.data;
  }
}

export default () => new TrafficTicketAPI();
