import HttpClient from '@/services/http-client';

class VehicleModelAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicle-models/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async updateVehicleModel({ id, ...data }) {
    const response = await this.http.patch('/vehicle-models/' + id, data);
    return response.data;
  }
  async saveVehicleModel(data: Record<string, string>) {
    const response = await this.http.post('/vehicle-models', data);
    return response.data;
  }
  async deleteVehicleModel(id) {
    const response = await this.http.delete('/vehicle-models/' + id);
    return response.data;
  }
  async getAllVehicleModels() {
    const response = await this.http.get('/vehicle-models');
    return response.data;
  }
}

export default () => new VehicleModelAPI();
