import { defineStore } from 'pinia';
import { ref } from 'vue';

import PermissionAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const usePermissionStore = defineStore('permissionStore', () => {
  const allPermissions = ref<Array<any>>([]);
  const allPermissionsByPaginate = ref<Array<any>>([]);
  const permission = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function getAllPermissions() {
    const data = await PermissionAPI().getAllPermissions();

    allPermissions.value = data;
  }

  async function getPermissionsByPaginate({ filter, paginate }) {
    const data = await PermissionAPI().paginate(filter, paginate.page, paginate.perPage);

    allPermissionsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  return {
    allPermissions,
    allPermissionsByPaginate,
    permission,
    lastPage,
    total,
    getPermissionsByPaginate,
    getAllPermissions,
  };
});
