import HttpClient from '@/services/http-client';

class BranchAPI extends HttpClient {
  async getAllBranches() {
    const response = await this.http.get('/branches');
    return response.data;
  }

  async getAllBranchesPaginate({ page, perPage, filter }) {
    const response = await this.http.get('/branches/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
}

export default () => new BranchAPI();
