import HttpClient from '@/services/http-client';
import qs from 'qs';

class TollTicketAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/tag-records/paginate/', {
      params: { filter, page, perPage, recordType: 'tollTickets' },
    });
    return response.data;
  }
  async paginateParkingTickets(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/tag-records/paginate/', {
      params: { filter, page, perPage, recordType: 'parkingTickets' },
    });
    return response.data;
  }
  async paginateTollTagMonthlyFees(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/tag-records/paginate/', {
      params: { filter, page, perPage, recordType: 'tollTagMonthlyFees' },
    });
    return response.data;
  }
  async saveTollTickets(data: Record<string, string>) {
    const response = await this.http.post('/tag-records', data);
    return response.data;
  }
  async extract({ startDate, endDate, type }) {
    const response = await this.http.get('/tag-records/extract/', {
      params: { startDate, endDate, type },
    });
    return response.data;
  }

  async getAllTollTicket(payload: Object) {
    const response = await this.http.get('/summaries/tag-record', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async getExtractTollsTickets(payload: Object) {
    const response = await this.http.get('/summaries/tag-record/extract', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
}

export default () => new TollTicketAPI();
