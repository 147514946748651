import HttpClient from '@/services/http-client';

class AuthorizedServiceAPI extends HttpClient {
  async getAllAuthorizedServices() {
    const response = await this.http.get('/authorized-services');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get(
      `/authorized-services/paginate?page=${page}&perPage=${perPage}&filter=${
        Object.keys(filter).length ? JSON.stringify(filter, undefined, 2) : ''
      }`,
    );
    return response.data;
  }
  async saveAuthorizedService(data: Record<string, string>) {
    const response = await this.http.post('/authorized-services', data);
    return response.data;
  }
  async updateAuthorizedService({ id, ...data }) {
    const response = await this.http.patch('/authorized-services/' + id, data);

    return response.data;
  }
  async deleteAuthorizedService(id: number) {
    const response = await this.http.delete('/authorized-services/' + id);
    return response.data;
  }
}

export default () => new AuthorizedServiceAPI();
