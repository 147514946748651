import { defineStore } from 'pinia';
import { ref } from 'vue';

import ViolationClassification from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useViolationClassificationStore = defineStore('violationClassificationStore', () => {
  const allViolationClassifications = ref<Array<any>>([]);

  async function getAllViolationClassifications() {
    const data = await ViolationClassification().getAllViolationClassifications();

    allViolationClassifications.value = data;
  }

  return {
    allViolationClassifications,
    getAllViolationClassifications,
  };
});
