import { defineStore } from 'pinia';
import { ref } from 'vue';

import InvoiceAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import { integer } from '@vuelidate/validators';

export const useInvoiceStore = defineStore('invoiceStore', () => {
  const allInvoices = ref<Array<any>>([]);
  const allInvoicesByPaginate = ref<Array<any>>([]);
  const invoiceCostTypes = ref<Array<any>>([]);
  const invoice = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteInvoice(id: number) {
    const data = await InvoiceAPI().deleteInvoice(id);

    removeItemByField(allInvoicesByPaginate.value, 'id', data.id);

    invoice.value = {};
  }

  async function exportData(obj: any) {
    return InvoiceAPI().exportData({
      id: obj.item?.id,
      fieldToExtract: obj.fileField ?? 'file_id',
    });
  }

  async function reportExtract(dates: { startDate: string; endDate: string }) {
    return InvoiceAPI().reportExtract(dates);
  }

  async function updateInvoice(obj: any) {
    const id = obj.get('id');
    const data = await InvoiceAPI().updateInvoice(id, obj);

    updateItemByField(allInvoicesByPaginate.value, 'id', data.id, data);

    invoice.value = data;
  }

  async function getInvoiceById(id: number) {
    const data = await InvoiceAPI().getInvoiceById(id);

    invoice.value = data;
  }

  async function saveInvoice(obj: Record<string, string>) {
    const data = await InvoiceAPI().saveInvoice(obj);

    allInvoicesByPaginate.value.unshift(data);
    total.value += 1;
  }

  async function getAllInvoices({ fields = [], relations = {} } = {}) {
    const data = await InvoiceAPI().getAllInvoices(fields, relations);

    allInvoices.value = data;
  }

  async function getInvoicesByPaginate({ filter, paginate }) {
    const data = await InvoiceAPI().paginate(filter, paginate.page, paginate.perPage);

    allInvoicesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function downloadSpreadsheetTemplateByCostType(costType: string) {
    return InvoiceAPI().downloadSpreadsheetTemplateByCostType(costType);
  }

  async function getAllInvoiceCostTypes() {
    const data = await InvoiceAPI().getAllCostTypes();

    invoiceCostTypes.value = data;

    return data;
  }

  return {
    allInvoices,
    allInvoicesByPaginate,
    lastPage,
    total,
    invoiceCostTypes,
    invoice,
    getAllInvoiceCostTypes,
    downloadSpreadsheetTemplateByCostType,
    getInvoicesByPaginate,
    getAllInvoices,
    saveInvoice,
    updateInvoice,
    reportExtract,
    exportData,
    deleteInvoice,
    getInvoiceById,
  };
});
