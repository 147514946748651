import HttpClient from '@/services/http-client';
import qs from 'qs';

class FuelSupplyAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get('/fuel-supplies/paginate', {
      params: { filter, page, perPage },
    });
    return result.data;
  }
  async getAllUsageErrors() {
    const result = await this.http.get('/fuel-supplies/usage-errors');
    return result.data;
  }
  async saveFuelSupply(data: object) {
    const result = await this.http.post('/fuel-supplies', data);
    return result.data;
  }
  async extract({ startDate, endDate }) {
    const response = await this.http.get('/fuel-supplies/extract', {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  }
  async exportFuelCostDataByYearInEmail(year) {
    const result = await this.http.get('/summaries/fuel-cost/extract', {
      params: { year },
    });
    return result.data;
  }
  async fetchFuelCostTreeByYear({ costCenters, year, fuelCostAverage }) {
    const result = await this.http.get('/summaries/fuel-cost', {
      params: { year, costCenters, fuelCostAverage },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return result.data;
  }
  async getHistoryOfSendingFuelSupply(page: number, perPage: number) {
    const result = await this.http.get('/summaries/fuel-cost/historic', {
      params: { page, perPage },
    });

    return result.data;
  }
  async extractInconsistencySheet() {
    const result = await this.http.get('/fuel-supplies/extract/inconsistency-sheet');

    return result.data;
  }
}

export default () => new FuelSupplyAPI();
