import HttpClient from '@/services/http-client';

class MaintenanceStatusAPI extends HttpClient {
  async getAllMaintenanceStatus() {
    const response = await this.http.get('/maintenance-status');
    return response.data;
  }
}

export default () => new MaintenanceStatusAPI();
