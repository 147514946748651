import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import VehicleModelAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useVehicleModelStore = defineStore('vehicleModelStore', () => {
  const allVehicleModels = ref<Array<any>>([]);
  const vehicleModelsByPaginate = ref<Array<any>>([]);
  const vehicleModel = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  const normalizeVehicleModels = computed(() =>
    getters.normalizeVehicleModels(allVehicleModels.value),
  );

  async function deleteVehicleModel(obj: Record<string, string>) {
    const data = await VehicleModelAPI().deleteVehicleModel(obj);

    removeItemByField(vehicleModelsByPaginate.value, 'id', data.id);
  }

  async function saveVehicleModel(obj: Record<string, string>) {
    const data = await VehicleModelAPI().saveVehicleModel(obj);

    vehicleModelsByPaginate.value.unshift(data);
  }

  async function updateVehicleModel(obj: any) {
    const data = await VehicleModelAPI().updateVehicleModel(obj);

    updateItemByField(vehicleModelsByPaginate.value, 'id', data.id, data);
  }

  async function getAllVehicleModels() {
    const data = await VehicleModelAPI().getAllVehicleModels();

    allVehicleModels.value = data;
  }

  async function getAllVehicleModelsByPaginate({ filter, paginate }) {
    const data = await VehicleModelAPI().paginate(filter, paginate.page, paginate.perPage);

    vehicleModelsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  return {
    allVehicleModels,
    vehicleModel,
    vehicleModelsByPaginate,
    lastPage,
    total,
    normalizeVehicleModels,
    deleteVehicleModel,
    updateVehicleModel,
    getAllVehicleModels,
    getAllVehicleModelsByPaginate,
    saveVehicleModel,
  };
});
