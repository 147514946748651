export function useMaintenance() {
  function getServiceStatusBadge(status) {
    const serviceStatuses = {
      Executando: 'badge badge-primary',
      Pendente: 'badge badge-warning',
      'Aguardando retirada': 'badge badge-secondary',
      Entregue: 'badge badge-success',
    };

    return serviceStatuses[status] || '';
  }

  function userCanApproveMaintenance(user, maintenance) {
    const userHavePermission = user?.permissions?.some(
      (permission) => permission.slug === 'approve_maintenance_registry',
    );

    if (userHavePermission) return userHavePermission;

    const userRoleHavePermission = user?.roles?.some((role) =>
      role?.permissions?.some((permission) => permission.slug === 'approve_maintenance_registry'),
    );

    if (userRoleHavePermission) return userRoleHavePermission;

    const userCreateMaintenance = maintenance?.user_id === user.id;

    if (userCreateMaintenance) return userCreateMaintenance;

    return false;
  }

  function getMaintenanceApprovalConfirmationMessage(approvalType, maintenanceId) {
    if (approvalType === 'approved')
      return `Certeza que deseja aprovar a manutenção referente a OS Nº ${maintenanceId}? Ao aprovar o registro, os dados serão utilizados nos relatórios de manutenções.`;

    return `Certeza que deseja recusar a manutenção referente a OS Nº ${maintenanceId}? Ao recusar o registro, o usuário tera que editá-lo para submetê-lo a aprovação novamente.`;
  }

  return {
    getServiceStatusBadge,
    userCanApproveMaintenance,
    getMaintenanceApprovalConfirmationMessage,
  };
}
