import HttpClient from '@/services/http-client';

class TrafficViolationAPI extends HttpClient {
  async getAllTrafficViolations() {
    const response = await this.http.get('traffic-violations');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('traffic-violations/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveTrafficViolation(data: Record<string, string>) {
    const response = await this.http.post('traffic-violations', data);
    return response.data;
  }
  async updateTrafficViolation(id: [String, Number], data: Record<string, string>) {
    const response = await this.http.patch('traffic-violations/' + id, data);
    return response.data;
  }
  async deleteTrafficViolation(id: [String, Number]) {
    const response = await this.http.delete('traffic-violations/' + id);
    return response.data;
  }
}

export default () => new TrafficViolationAPI();
