import { defineStore } from 'pinia';
import { ref } from 'vue';

import CityAPI from './service';

export const useCityStore = defineStore('cityStore', () => {
  const allCities = ref<Array<any>>([]);

  async function getAllCities() {
    const data = await CityAPI().getAllCities();
    allCities.value = data;
  }

  return {
    allCities,
    getAllCities,
  };
});
