import HttpClient from '@/services/http-client';

class ReadjustmentIndexAPI extends HttpClient {
  async getAllReadjustmentIndices() {
    const response = await this.http.get('/readjustment-indices');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/readjustment-indices/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveReadjustmentIndex(data: Record<string, string>) {
    const response = await this.http.post('/readjustment-indices', data);
    return response.data;
  }
  async updateReadjustmentIndex({ id, ...data }) {
    const response = await this.http.patch('/readjustment-indices/' + id, data);
    return response.data;
  }
  async deleteReadjustmentIndex(id) {
    const response = await this.http.delete('/readjustment-indices/' + id);
    return response.data;
  }
}

export default () => new ReadjustmentIndexAPI();
