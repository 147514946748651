export default [
  {
    name: 'Dashboard',
    icon: 'ion ion-md-analytics',
    to: '/',
  },
  {
    name: 'Resumos',
    icon: 'ion ion-md-settings',
    action: '',
    model: '',
    children: [
      {
        name: 'Estrutura de Ativos',
        to: '/asset-structure',
        icon: '',
        action: 'read',
        model: 'asset-structure-summary',
      },
      {
        name: 'Resumo de Veiculos',
        to: '/vehicle-summary',
        icon: '',
        action: 'read',
        model: 'vehicles-summary',
      },
      {
        name: 'Indisponibilidade',
        to: '/unavailabilities',
        icon: '',
        action: 'read',
        model: 'unavailability-summary',
      },
      {
        name: 'Custo de Locação',
        to: '/rental-cost',
        action: 'read',
        model: 'rental-cost-summary',
        icon: '',
      },
      {
        name: 'Custo de Manutenção',
        to: '/maintenance-cost',
        action: 'read',
        model: 'maintenances-summary',
        icon: '',
      },
      {
        name: 'Resumo de Manutenção',
        to: '/maintenance-summary',
        action: 'read',
        model: 'maintenances-summary',
        icon: '',
      },
      {
        name: 'Custo de Combustível',
        to: '/fuel-cost',
        action: 'read',
        model: 'fuel-cost-summary',
        icon: '',
      },
      {
        name: 'Custo de Tag',
        to: '/cost-tag',
        icon: '',
        action: 'read',
        model: 'tag-cost-summary',
      },
      {
        name: 'Custo Consolidado',
        to: '/consolidated-cost',
        icon: '',
        action: 'read',
        model: 'consolidated-cost-summary',
      },
      {
        name: 'Custo de Rastreamento',
        to: '/tracker-cost-summary',
        icon: '',
        action: 'read',
        model: 'tracker-cost-summary',
      },
      {
        name: 'Resumo de Laudos',
        to: '/appraisals-summary',
        icon: '',
        action: 'read',
        model: 'appraisals-summary',
      },
    ],
  },
  {
    name: 'Centros de Custos',
    to: '/cost-centers',
    icon: 'ion ion-md-albums',
    action: 'read',
    model: 'cost-centers',
  },
  {
    name: 'Notas Fiscais',
    to: '/invoices',
    icon: 'ion ion-md-folder-open',
    action: 'read',
    model: 'invoices',
  },
  {
    name: 'Manutenções',
    action: 'read',
    model: 'maintenances',
    icon: 'ion ion-md-settings',
    children: [
      {
        name: 'Manutenções',
        to: '/maintenances',
        icon: '',
        action: 'read',
        model: 'maintenances',
      },
      {
        name: 'Agendamentos',
        to: '/maintenance-schedulings',
        icon: '',
        action: 'read',
        model: 'maintenance-schedulings',
      },
      {
        name: 'Critérios de Revisão',
        to: '/review-criterias',
        icon: '',
        action: 'read',
        model: 'review-criterias',
      },
      {
        name: 'Informações Adicionais',
        to: '/additional-informations',
        icon: '',
        action: 'read',
        model: 'additional-informations',
      },
      {
        name: 'Problemas Identificados',
        to: '/identified-problems',
        action: 'read',
        model: 'identified-problems',
        icon: '',
      },
      {
        name: 'Serviços Autorizados',
        to: '/authorized-services',
        icon: '',
        action: 'read',
        model: 'authorized-services',
      },
    ],
  },
  {
    name: 'Fornecedores',
    to: '/suppliers',
    action: 'read',
    model: 'suppliers',
    icon: 'ion ion-md-briefcase',
  },
  {
    name: 'Funcionários',
    to: '/employees',
    icon: 'ion ion-md-people',
    action: 'read',
    model: 'employees',
  },
  {
    name: 'Abastecimento',
    to: '/fuel-supplies',
    icon: 'ion ion-md-timer',
    action: 'read',
    model: 'fuel-supplies',
  },
  {
    name: 'Registros de Tag',
    to: '/tag-records',
    icon: 'ion ion-md-options',
    action: 'read',
    model: 'tag-records',
  },
  {
    name: 'Rastreamento',
    icon: 'ion ion-ios-contract',
    to: '/tracker-costs',
    action: 'read',
    model: 'tracker-costs',
  },
  {
    name: 'Infrações de Trânsito',
    action: 'read',
    model: 'traffic-tickets',
    icon: 'ion ion-md-warning',
    children: [
      {
        name: 'Multas',
        to: '/traffic-tickets',
        icon: '',
        action: 'read',
        model: 'traffic-tickets',
      },
      {
        name: 'Tipos de infrações',
        to: '/traffic-violations',
        icon: '',
        action: 'read',
        model: 'traffic-violations',
      },
    ],
  },
  {
    name: 'Registros de ocorrência',
    action: 'read',
    to: '/incident-records',
    model: 'incident-records',
    icon: 'ion ion-ios-alert',
  },
  {
    name: 'Filiais',
    action: 'read',
    to: '/branches',
    model: 'branches',
    icon: 'ion ion-ios-git-branch',
  },
  {
    name: 'Bases operacionais',
    action: 'read',
    to: '/operational-bases',
    model: 'operational-bases',
    icon: 'ion ion-ios-git-network',
  },

  {
    name: 'Veículos',
    action: '',
    model: '',
    icon: 'ion ion-md-car',
    children: [
      {
        name: 'Veículos',
        to: '/vehicles',
        icon: '',
        action: 'read',
        model: 'vehicles',
      },
      {
        name: 'Laudos',
        to: '/appraisals',
        icon: '',
        action: 'read',
        model: 'appraisals',
      },
      {
        name: 'Registros de Portaria',
        to: '/vehicle-movement-records',
        action: 'read',
        model: 'vehicle-movement-records',
        icon: '',
      },
      {
        name: 'Justificativas de Portaria',
        to: '/justification-record-input-output',
        action: 'read',
        model: 'justification-record-input-output',
        icon: '',
      },
      {
        name: 'Permissões de Portaria',
        to: '/vehicle-movement-permissions',
        action: 'read',
        model: 'vehicle-movement-permissions',
        icon: '',
      },
      {
        name: 'Contratos de locação',
        to: '/rental-contracts',
        icon: '',
        action: 'read',
        model: 'rental-contracts',
      },
      {
        name: 'Agrupamento de Veículos',
        to: '/vehicle-groups',
        icon: '',
        action: 'read',
        model: 'vehicle-groups',
      },
      {
        name: 'Equipamentos',
        to: '/equipments',
        icon: '',
        action: 'read',
        model: 'equipments',
      },
      {
        name: 'Acessorios',
        to: '/accessories',
        icon: '',
        action: 'read',
        model: 'accessories',
      },
      {
        name: 'Índices de Reajuste',
        to: '/readjustment-indices',
        action: 'read',
        model: 'readjustment-indices',
        icon: '',
      },
      {
        name: 'Unidade federativa',
        to: '/federative-units',
        icon: '',
        action: 'read',
        model: 'federative-units',
      },
      {
        name: 'Tipos de veículos',
        icon: '',
        to: '/vehicle-types',
        action: 'read',
        model: 'vehicle-types',
      },
      {
        name: 'Modelos de veículos',
        to: '/vehicle-models',
        icon: '',
        action: 'read',
        model: 'vehicle-models',
      },
      {
        name: 'Grupos de peças do veículo',
        to: '/vehicle-part-groups',
        icon: '',
        action: 'read',
        model: 'vehicle-part-groups',
      },
      {
        name: 'Status de documentos',
        icon: '',
        to: '/document-status',
        action: 'read',
        model: 'document-status',
      },
    ],
  },
  {
    name: 'Acesso',
    action: '',
    model: '',
    icon: 'ion ion-md-person',
    children: [
      {
        name: 'Usuários',
        icon: '',
        action: 'read',
        model: 'user',
        to: '/users',
      },
      {
        name: 'Turnos',
        icon: '',
        action: 'read',
        model: 'rotations',
        to: '/rotations',
      },
      {
        name: 'Usuários Adicionais',
        icon: '',
        action: 'read',
        model: 'additional-users',
        to: '/additional-users',
      },
      {
        name: 'Cargos',
        icon: '',
        action: 'read',
        model: 'roles',
        to: '/roles',
      },
      {
        name: 'Permissões',
        icon: '',
        action: 'read',
        model: 'permissions',
        to: '/permissions',
      },
    ],
  },
  {
    name: 'Configurações',
    action: 'read',
    to: '/settings',
    model: 'settings',
    icon: 'ion ion-md-settings',
  },
];
