import { defineStore } from 'pinia';

import HealthCheckAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useHealthCheckStore = defineStore('healthCheckStore', () => {
  async function getHealthStatus() {
    return HealthCheckAPI().getHealthStatus();
  }

  return { getHealthStatus };
});
