const moment = require('moment');

const getAllTollTickets = (allTollTickets: any[]) => {
  return allTollTickets.map((tollTicket) => {
    return {
      plate: tollTicket.vehicle.plate,
      tag: tollTicket.tag,
      brand: tollTicket.vehicle.vehicleModel.brand,
      category: tollTicket.category,
      passage_time: tollTicket.passage_time,
      highway: tollTicket.highway,
      address: tollTicket.address,
      price: tollTicket.price,
    };
  });
};

function compareTimeWithTwoDate(dateFirst, dateSecond) {
  const diff = moment(dateSecond).diff(moment(dateFirst));
  const duration = moment.duration(diff);
  return Math.floor(duration.asHours()) + moment.utc(diff).format(':mm:ss');
}

const getAllParkings = (allParkings: any[]) => {
  return allParkings.map((parking) => {
    const permanence = compareTimeWithTwoDate(parking.arrival_time, parking.exit_time);
    return {
      plate: parking.vehicle.plate,
      tag: parking.tag,
      brand: parking.vehicle.vehicleModel.brand,
      category: parking.category,
      arrival_time: parking.arrival_time,
      exit_time: parking.exit_time,
      permanence,
      company: parking.company,
      price: parking.price,
    };
  });
};

const getAllTollTagMonthlyFees = (allTollTagMonthlyFees: any[]) => {
  return allTollTagMonthlyFees.map((monthly) => {
    return {
      plate: monthly.vehicle.plate,
      tag: monthly.tag,
      category: monthly.category,
      reference_month: monthly.reference_month,
      price: monthly.price,
    };
  });
};

export default {
  getAllTollTagMonthlyFees,
  getAllParkings,
  getAllTollTickets,
};
