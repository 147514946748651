import { defineStore } from 'pinia';
import { ref } from 'vue';

import EmployeeAPI from './service';
import { IGetAllEmployees } from './interfaces';

export const useEmployeeStore = defineStore('employeeStore', () => {
  const allEmployees = ref<Array<any>>([]);
  const employeesByPaginate = ref<Array<any>>([]);
  const employee = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function getAllEmployees({
    fields = [],
    relations,
    normalized = true,
    onlyActive = true,
    module = '',
  }: IGetAllEmployees = {}) {
    const data = await EmployeeAPI().getAllEmployees({
      normalized,
      onlyActive,
      fields,
      relations,
      module,
    });

    allEmployees.value = data;
  }

  async function getEmployeesByPaginate({ filter, paginate }) {
    const data = await EmployeeAPI().paginate(filter, paginate.page, paginate.perPage);

    employeesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function importEmployeesByMetadataFile(obj: FormData) {
    return await EmployeeAPI().importEmployeesByMetadataFile(obj);
  }

  return {
    allEmployees,
    employeesByPaginate,
    lastPage,
    total,
    employee,
    getAllEmployees,
    getEmployeesByPaginate,
    importEmployeesByMetadataFile,
  };
});
