import { defineStore } from 'pinia';
import { ref } from 'vue';

import MaintenanceStatusAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useMaintenanceStatusStore = defineStore('maintenanceStatusStore', () => {
  const allMaintenanceStatus = ref<Array<any>>([]);

  async function getAllMaintenanceStatus() {
    const data = await MaintenanceStatusAPI().getAllMaintenanceStatus();

    allMaintenanceStatus.value = data;
  }

  return {
    allMaintenanceStatus,
    getAllMaintenanceStatus,
  };
});
