import HttpClient from '@/services/http-client';

class AccessoryAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/accessories/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async getAllAccessories() {
    const response = await this.http.get('/accessories');
    return response.data;
  }
  async saveAccessory(data: Record<string, string>) {
    const response = await this.http.post('/accessories', data);
    return response.data;
  }
  async updateAccessory({ id, ...data }) {
    const response = await this.http.patch('/accessories/' + id, data);
    return response.data;
  }
  async deleteAccessory(id) {
    const response = await this.http.delete('/accessories/' + id);
    return response.data;
  }
}

export default () => new AccessoryAPI();
