import Vue from 'vue';
import moment from 'moment';
import { useOfflineRequestStore } from '@/stores';

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';

export default class HttpClient {
  http: AxiosInstance;
  uuid: string;

  constructor() {
    this.http = Vue.prototype.$http;
    this.uuid = uuid();
  }

  async offlineRequest(config: AxiosRequestConfig) {
    const offlineRequestStore = useOfflineRequestStore();

    const requestStorage = localStorage.requests ? localStorage.getItem('requests') : {};
    const cache = typeof requestStorage === 'string' ? JSON.parse(requestStorage) : requestStorage;

    if (config?.data?.uuid && config.method === 'PATCH') {
      await offlineRequestStore.updateRequestByUUID({
        uuid: config?.data?.uuid,
        data: config.data,
      });

      return Promise.resolve({ data: config.data });
    }

    const data = { ...config.data, isCache: true, uuid: this.uuid };

    const cachePayload = {
      data,
      url: config.url,
      method: config.method,
      requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
    };

    const method = config?.method?.toLocaleLowerCase() ?? '';

    if (!cache[method]?.length) {
      cache[method] = [cachePayload];

      await offlineRequestStore.updateRequests(cache);

      return Promise.resolve({ data });
    }

    cache[method].push(cachePayload);

    await offlineRequestStore.updateRequests(cache);

    return Promise.resolve({ data });
  }

  async request(payload: any) {
    if (!navigator.onLine && payload.offline) {
      return this.offlineRequest(payload);
    }
    const result = await this.http.request(payload);
    return result;
  }
}
