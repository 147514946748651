import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import VehicleAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useVehicleStore = defineStore('vehicleStore', () => {
  const allVehiclesByFields = ref<any[]>([]);
  const allVehicles = ref<any[]>([]);
  const allVehiclesByPaginate = ref<any[]>([]);
  const allRentedVehiclesCloseToReturnPaginate = ref<any[]>([]);
  const schedulings = ref<any[]>([]);
  const vehicle = ref({});
  const lastPage = ref(1);
  const total = ref(0);
  const totalRentedVehiclesCloseToReturn = ref(0);

  const getAllVehiclesByPaginateWithBrand = computed(() =>
    getters.getAllVehiclesByPaginateWithBrand(allVehiclesByPaginate.value),
  );

  const normalizeDataSchedulings = computed(() =>
    getters.normalizeDataSchedulings(schedulings.value),
  );

  function findByPlate(plate: string) {
    return getters.findByPlate(allVehicles.value, plate);
  }

  function findVehicleFieldByPlateOrAlternativePlate(plate: string) {
    return getters.findVehicleFieldByPlateOrAlternativePlate(allVehiclesByFields.value, plate);
  }

  async function getTotalCost(id: number) {
    return VehicleAPI().getTotalCost(id);
  }

  async function getAppraisalByVehicle(id: number) {
    return VehicleAPI().getAppraisalByVehicle(id);
  }

  async function getAppraisalByVehiclePublicScreen(plate: number) {
    return VehicleAPI().getAppraisalByVehiclePublicScreen(plate);
  }

  async function getSuppliesByVehicle({ id, filter, paginate }) {
    return VehicleAPI().getSuppliesByVehicle({
      id,
      filter,
      page: paginate.page,
      perPage: paginate.perPage,
    });
  }

  async function exportDataAppraisal(obj: any) {
    return VehicleAPI().exportDataAppraisal(obj.id);
  }

  async function deleteVehicle(id: number) {
    const data = await VehicleAPI().deleteVehicle(id);
    removeItemByField(allVehiclesByPaginate.value, 'id', data.id);
  }

  async function getAllVehiclesByFields({ fields, relations, onlyActive = false, module = '' }) {
    const data = await VehicleAPI().getAllVehiclesByFields(onlyActive, fields, relations, module);

    allVehiclesByFields.value = data;
  }

  async function getAllVehicles() {
    const data = await VehicleAPI().getAllVehicles();

    allVehicles.value = data;
  }

  async function getAllActiveVehicle() {
    const data = await VehicleAPI().getAllActiveVehicle();

    allVehicles.value = data;
  }

  function updateVehiclePaginationData(data) {
    allVehiclesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllVehiclesByPaginate({ filter, paginate }) {
    const data = await VehicleAPI().paginate(filter, paginate.page, paginate.perPage);

    updateVehiclePaginationData(data);
  }

  async function saveVehicle(obj: Record<string, string>) {
    const data = await VehicleAPI().saveVehicle(obj);

    allVehiclesByPaginate.value.unshift(data);
  }

  async function updateVehicle(obj: any) {
    const data = await VehicleAPI().updateVehicle(obj);

    updateItemByField(allVehiclesByPaginate.value, 'id', data.id, data);
  }

  function updateVehicleData(data) {
    updateItemByField(allVehiclesByPaginate.value, 'id', data.id, data);
  }

  async function updateVehicleCRLV(obj: any) {
    const data = await VehicleAPI().updateVehicleCRLV(obj);

    updateItemByField(allVehiclesByPaginate.value, 'id', data.id, data);

    return data;
  }

  async function getAllVehiclesForExport() {
    return VehicleAPI().getAllVehiclesForExport();
  }

  async function getVehicleModel() {
    return VehicleAPI().getVehicleModel();
  }

  async function getVehicle(id: number) {
    const data = await VehicleAPI().getVehicle(id);

    vehicle.value = data;
  }

  async function getVehiclePublicScreen(plate: string) {
    const data = await VehicleAPI().getVehiclePublicScreen(plate);

    vehicle.value = data;
  }

  async function getMaintenanceByVehicle(id: number) {
    return VehicleAPI().getMaintenanceByVehicle(id);
  }

  async function getMaintenanceSchedulingByVehicle(id: number) {
    const data = await VehicleAPI().getMaintenanceSchedulingByVehicle(id);

    schedulings.value = data;
  }

  async function saveBulkVehicles(payload: Object) {
    return VehicleAPI().saveBulkVehicles(payload);
  }

  async function extractFile({ vehicleId, fileType, accessoryId, entityToExtract = 'vehicle' }) {
    return VehicleAPI().extractFile({ vehicleId, fileType, entityToExtract, accessoryId });
  }

  async function getRentalVehiclesCloseToReturnPaginated({ filter, paginate }) {
    const data = await VehicleAPI().getRentalVehiclesCloseToReturnPaginated(
      filter,
      paginate.page,
      paginate.perPage,
    );

    allRentedVehiclesCloseToReturnPaginate.value = data.data;
    totalRentedVehiclesCloseToReturn.value = data.total;
  }

  return {
    allVehiclesByFields,
    allVehicles,
    allVehiclesByPaginate,
    allRentedVehiclesCloseToReturnPaginate,
    schedulings,
    vehicle,
    lastPage,
    total,
    totalRentedVehiclesCloseToReturn,
    getAllVehiclesByPaginateWithBrand,
    normalizeDataSchedulings,
    getTotalCost,
    getAppraisalByVehiclePublicScreen,
    getVehiclePublicScreen,
    saveBulkVehicles,
    getVehicleModel,
    deleteVehicle,
    updateVehicle,
    exportDataAppraisal,
    getSuppliesByVehicle,
    getAppraisalByVehicle,
    getMaintenanceSchedulingByVehicle,
    getMaintenanceByVehicle,
    getVehicle,
    getAllVehiclesForExport,
    getAllVehicles,
    getAllVehiclesByPaginate,
    saveVehicle,
    getAllVehiclesByFields,
    extractFile,
    getAllActiveVehicle,
    updateVehicleCRLV,
    updateVehicleData,
    updateVehiclePaginationData,
    findByPlate,
    findVehicleFieldByPlateOrAlternativePlate,
    getRentalVehiclesCloseToReturnPaginated,
  };
});
