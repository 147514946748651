import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useOfflineRequestStore = defineStore('offlineRequestStore', () => {
  const userIsOnline = ref(true);
  const requests = ref(
    localStorage.requests ? JSON.parse(localStorage.getItem('requests') || '{}') : {},
  );

  const totalRequests = computed(() => {
    const requestsEntries = Object.entries<any[]>(requests.value);

    return requestsEntries.reduce(
      (acc, [_type, requestsByType]) => (requestsByType.filter((request) => request).length += acc),
      0,
    );
  });

  async function updateRequests(array: Array<any> = []) {
    requests.value = array;
    localStorage.setItem('requests', JSON.stringify(array));
  }

  async function changeOnlineStatus(status: boolean = true) {
    userIsOnline.value = status;
  }

  async function deleteRequestByUUID(uuid: string) {
    const newRequest = {};

    for (const [requestType, requestsArray] of Object.entries<any[]>(requests.value)) {
      newRequest[requestType] = requestsArray.filter((request) => request.data.uuid !== uuid) ?? [];
    }

    requests.value = newRequest;
  }

  async function updateRequestByUUID({ uuid, data }: { uuid: string; data: object }) {
    const newRequest = {};

    for (const [requestType, requestsArray] of Object.entries<any[]>(requests.value)) {
      newRequest[requestType] =
        requestsArray.map((request) => {
          if (request.data.uuid === uuid) return { ...request, data };
          return request;
        }) ?? [];
    }

    requests.value = newRequest;
    localStorage.setItem('requests', JSON.stringify(newRequest));
  }

  return {
    requests,
    userIsOnline,
    totalRequests,
    updateRequestByUUID,
    deleteRequestByUUID,
    changeOnlineStatus,
    updateRequests,
  };
});
