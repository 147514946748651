import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import TrafficTicketAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useTrafficTicketStore = defineStore('trafficTicketStore', () => {
  const trafficTicketsByPaginate = ref<Array<any>>([]);
  const allTrafficTickets = ref<Array<any>>([]);
  const trafficTicketsToSendEmail = ref<Array<any>>([]);
  const historicSendingTrafficTickets = ref<Array<any>>([]);
  const trafficTicket = ref({});
  const lastPage = ref(1);
  const total = ref(0);
  const page = ref(1);
  const perPage = ref(10);

  const getStatusTrafficTicketsByPaginate = computed(() =>
    getters.getStatusTrafficTicketsByPaginate(trafficTicketsByPaginate.value),
  );

  const trafficTicketDocumentType = computed(() => {
    return {
      notification: 'Notificação',
      nic_fine: 'Multa NIC',
      fine: 'Multa',
    };
  });

  const avalibleTrafficTicketsCategories = computed(() => {
    return ['Notificação', 'Multa', 'Multa NIC', 'Sem informação'];
  });

  function getDocumentType(documentType) {
    const documentTypes = {
      notification: 'Notificação',
      fine: 'Multa',
      nic_fine: 'Multa NIC',
    };

    return documentTypes[documentType] ?? '';
  }

  async function getAllTrafficTicketsForExport() {
    return TrafficTicketAPI().getAllTrafficTicketsForExport();
  }

  async function getRealInfractorByVehicleMovementRecord({ infractionDate, vehicle }) {
    return TrafficTicketAPI().getRealInfractorByVehicleMovementRecord({
      infractionDate,
      vehicle,
    });
  }

  async function deleteTrafficTicket(id: number) {
    const data = await TrafficTicketAPI().deleteTrafficTicket(id);

    removeItemByField(trafficTicketsByPaginate.value, 'id', data.id);
  }

  async function updateTrafficTicket(obj: any) {
    const id = obj.get('id');

    const data = await TrafficTicketAPI().updateTrafficTicket(id, obj);

    trafficTicket.value = data;
    updateItemByField(trafficTicketsByPaginate.value, 'id', data.id, data);
  }

  async function getTrafficTicketsByPaginate({ filter, paginate }) {
    const data = await TrafficTicketAPI().paginate(filter, paginate.page, paginate.perPage);

    trafficTicketsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getNotificationsWithConductorIdentificationPendencies({ paginate }) {
    const data = await TrafficTicketAPI().getNotificationsWithConductorIdentificationPendencies(
      paginate.page,
      paginate.perPage,
    );

    trafficTicketsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveTrafficTicket(obj: Record<string, string>) {
    const data = await TrafficTicketAPI().saveTrafficTicket(obj);

    trafficTicketsByPaginate.value.unshift(data);
  }

  async function exportData({ id, fileToExtract }: any) {
    return TrafficTicketAPI().exportData(id, fileToExtract);
  }

  async function getAllTicketsToSendByEmail() {
    const data = await TrafficTicketAPI().getAllTicketsToSendByEmail();

    trafficTicketsToSendEmail.value = data;
  }

  async function getHistoryOfSendingTrafficTickets({ paginate }) {
    const data = await TrafficTicketAPI().getHistoryOfSendingTrafficTickets(
      paginate.page,
      paginate.perPage,
    );

    historicSendingTrafficTickets.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
    page.value = data.page;
    perPage.value = data.perPage;
  }

  async function sendTemplateByEmail(payload: object) {
    return await TrafficTicketAPI().sendTemplateByEmail(payload);
  }

  async function getAllTrafficTickets(
    { filterDate, filterType, costCentersToHide, costCentersToFilter, fields, relations } = {
      filterDate: null,
      filterType: null,
      costCentersToHide: [],
      costCentersToFilter: [],
      fields: [],
      relations: {},
    },
  ) {
    const data = await TrafficTicketAPI().getAllTrafficTickets({
      filterDate,
      filterType,
      costCentersToHide,
      costCentersToFilter,
      fields,
      relations,
    });

    allTrafficTickets.value = data;

    return data;
  }

  async function getRealInfractorRanking({
    filterDate,
    filterType,
    costCentersToHide,
    costCentersToFilter,
  }) {
    const data = await TrafficTicketAPI().getRealInfractorRanking({
      filterDate,
      filterType,
      costCentersToHide,
      costCentersToFilter,
    });

    return data;
  }

  async function getTrafficTicketById(id) {
    const data = await TrafficTicketAPI().getTrafficTicketById(id);

    trafficTicket.value = data;
  }

  return {
    trafficTicketsByPaginate,
    allTrafficTickets,
    trafficTicketsToSendEmail,
    historicSendingTrafficTickets,
    trafficTicket,
    lastPage,
    total,
    perPage,
    getStatusTrafficTicketsByPaginate,
    getTrafficTicketById,
    getRealInfractorRanking,
    getAllTrafficTickets,
    sendTemplateByEmail,
    getHistoryOfSendingTrafficTickets,
    getAllTicketsToSendByEmail,
    exportData,
    saveTrafficTicket,
    getNotificationsWithConductorIdentificationPendencies,
    getTrafficTicketsByPaginate,
    updateTrafficTicket,
    deleteTrafficTicket,
    getRealInfractorByVehicleMovementRecord,
    getAllTrafficTicketsForExport,
    trafficTicketDocumentType,
    avalibleTrafficTicketsCategories,
    getDocumentType,
  };
});
