import { inject } from 'vue';
import { useMath } from '../math';

export function useInvoiceApportionment() {
  const moment = inject<any>('$moment');
  const { sum } = useMath();

  function validateApportionmentCostCenter(apportionment, index) {
    if (!apportionment?.costCenter?.id) {
      return `É necessário vincular o centro de custo ao rateio #${index + 1}`;
    }
  }

  function validateApportionmentMaintenance(apportionment, index) {
    if (!apportionment?.maintenance?.id) {
      return `É necessário vincular a manutenção ao rateio #${index + 1}`;
    }
  }

  function validateApportionmentVehicle(apportionment, index) {
    if (!apportionment?.vehicle?.id) {
      return `É necessário vincular o veiculo ao rateio #${index + 1}`;
    }
  }

  function validateApportionmentTrafficTicket(apportionment, index) {
    if (!apportionment?.trafficTicket?.id) {
      return `É necessário vincular a infração de trânsito ao rateio #${index + 1}`;
    }
  }

  function validateApportionmentValue(apportionment, index) {
    if (!apportionment?.value) return `É necessário atriuir um valor ao rateio #${index + 1}`;
  }

  function validateApportionmentServiceType(apportionment, index) {
    if (!apportionment?.description)
      return `É necessário atriuir um tipo de serviço ao rateio #${index + 1}`;
  }

  function validateMaintenanceApportionments(apportionments: any[]) {
    let message: string | undefined = '';

    for (const [index, apportionment] of apportionments.entries()) {
      message = validateApportionmentMaintenance(apportionment, index);
      if (message) return message;

      message = validateApportionmentValue(apportionment, index);

      if (message) return message;
    }
  }

  function validateTrackerCostApportionments(apportionments: any[]) {
    let message: string | undefined = '';

    for (const [index, apportionment] of apportionments.entries()) {
      message = validateApportionmentVehicle(apportionment, index);
      if (message) return message;

      message = validateApportionmentCostCenter(apportionment, index);
      if (message) return message;

      message = validateApportionmentServiceType(apportionment, index);
      if (message) return message;

      message = validateApportionmentValue(apportionment, index);

      if (message) return message;
    }
  }

  function validateTrafficTicketApportionments(apportionments: any[]) {
    let message: string | undefined = '';

    for (const [index, apportionment] of apportionments.entries()) {
      message = validateApportionmentVehicle(apportionment, index);
      if (message) return message;

      message = validateApportionmentTrafficTicket(apportionment, index);
      if (message) return message;

      message = validateApportionmentCostCenter(apportionment, index);
      if (message) return message;

      message = validateApportionmentValue(apportionment, index);

      if (message) return message;
    }
  }

  function validateRentalApportionments(apportionments: any[]) {
    let message: string | undefined = '';

    for (const [index, apportionment] of apportionments.entries()) {
      message = validateApportionmentVehicle(apportionment, index);
      if (message) return message;

      message = validateApportionmentCostCenter(apportionment, index);
      if (message) return message;

      message = validateApportionmentValue(apportionment, index);

      if (message) return message;
    }
  }

  function validateTagAndFuelApportionments(apportionments: any[]) {
    let message: string | undefined = '';

    for (const [index, apportionment] of apportionments.entries()) {
      message = validateApportionmentCostCenter(apportionment, index);
      if (message) return message;

      message = validateApportionmentValue(apportionment, index);

      if (message) return message;
    }
  }

  function validateApportionments(apportionments: any[], costTypeSlug: string, invoice) {
    if (!costTypeSlug) return '';

    if (!apportionments.length) {
      return 'É necessário vincular ao menos um rateio ao documento fiscal.';
    }

    const totalApportionmentValue = apportionments.reduce((total, apportionment) => {
      total = sum(total, apportionment.value, 2);

      return total;
    }, 0);

    if (totalApportionmentValue !== invoice.value) {
      return 'O total do(s) rasteio(s) é diferente do total do documento fiscal.';
    }

    switch (true) {
      case ['TAG', 'Full'].includes(costTypeSlug):
        return validateTagAndFuelApportionments(apportionments);

      case costTypeSlug === 'TrackerCosts':
        return validateTrackerCostApportionments(apportionments);

      case costTypeSlug === 'Rental':
        return validateRentalApportionments(apportionments);

      case costTypeSlug === 'TrafficTicket':
        return validateTrafficTicketApportionments(apportionments);

      default:
        return validateMaintenanceApportionments(apportionments);
    }
  }

  function formatApportionments({ apportionments, costType, invoice, billingMonth }) {
    const costTypeSlug = costType?.slug;

    if (!costTypeSlug) return [];

    return apportionments.map((apportionment) => ({
      id: apportionment.id ?? null,
      invoice_id: invoice.id ?? null,
      cost_center_id: apportionment?.costCenter?.id ?? null,
      vehicle_id: !['Full', 'TAG'].includes(costTypeSlug) ? apportionment?.vehicle?.id : null,
      traffic_ticket_id: costTypeSlug === 'TrafficTicket' ? apportionment?.trafficTicket?.id : null,
      maintenance_id: costType.slug === 'Maintenances' ? apportionment?.maintenance?.id : null,
      value: apportionment.value,
      description:
        costTypeSlug === 'TrackerCosts'
          ? apportionment.description?.name
          : apportionment.description,
      cost_type: costTypeSlug,
      cost_type_id: costType?.id,
      billing_month: moment(billingMonth).format('YYYY-MM-DD'),
    }));
  }

  return { validateApportionments, formatApportionments };
}
