import HttpClient from '@/services/http-client';

class PaymentMethodsAPI extends HttpClient {
  async getAllPaymentMethods() {
    const response = await this.http.get('/payment-methods');
    return response.data;
  }
  
}

export default () => new PaymentMethodsAPI();
