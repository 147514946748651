import { ref } from 'vue';
import { defineStore } from 'pinia';

import AccessoryAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useAccessoryStore = defineStore('accessoryStore', () => {
  const allAccessories = ref<Array<any>>([]);
  const accessory = ref({});
  const accessoriesByPaginate = ref<Array<any>>([]);
  const lastPage = ref(0);
  const total = ref(0);

  async function getAllAccessories() {
    const data = await AccessoryAPI().getAllAccessories();

    allAccessories.value = data;
  }

  async function deleteAccessory(id: number) {
    const data = await AccessoryAPI().deleteAccessory(id);

    removeItemByField(accessoriesByPaginate.value, 'id', data.id);
  }

  async function updateAccessory(obj: any) {
    const data = await AccessoryAPI().updateAccessory(obj);

    updateItemByField(accessoriesByPaginate.value, 'id', data.id, data);
  }

  async function getAccessoriesByPaginate({ filter, paginate }) {
    const data = await AccessoryAPI().paginate(filter, paginate.page, paginate.perPage);

    accessoriesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveAccessory(obj: Record<string, string>) {
    const data = await AccessoryAPI().saveAccessory(obj);

    accessoriesByPaginate.value.unshift(data);
  }

  return {
    allAccessories,
    accessory,
    accessoriesByPaginate,
    lastPage,
    total,
    getAllAccessories,
    saveAccessory,
    getAccessoriesByPaginate,
    updateAccessory,
    deleteAccessory,
  };
});
