import { defineStore } from 'pinia';
import { ref } from 'vue';

import IdentifiedProblemAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useIdentifiedProblemStore = defineStore('identifidedProblemStore', () => {
  const allIdentifiedProblems = ref<Array<any>>([]);
  const identifiedProblemsByPaginate = ref<Array<any>>([]);
  const identifiedProblem = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteIdentifiedProblem(id: number) {
    const data = await IdentifiedProblemAPI().deleteIdentifiedProblem(id);

    removeItemByField(identifiedProblemsByPaginate.value, 'id', data.id);
  }

  async function updateIdentifiedProblem(obj: any) {
    const data = await IdentifiedProblemAPI().updateIdentifiedProblem(obj);

    updateItemByField(identifiedProblemsByPaginate.value, 'id', data.id, data);
  }

  async function getAllIdentifiedProblems() {
    const data = await IdentifiedProblemAPI().getAllIdentifiedProblems();

    allIdentifiedProblems.value = data;
  }

  async function getIdentifiedProblemsByPaginate({ filter, paginate }) {
    const data = await IdentifiedProblemAPI().paginate(filter, paginate.page, paginate.perPage);

    identifiedProblemsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveIdentifiedProblem(obj: Record<string, string>) {
    const data = await IdentifiedProblemAPI().saveIdentifiedProblem(obj);

    identifiedProblemsByPaginate.value.unshift(data);
  }

  return {
    allIdentifiedProblems,
    identifiedProblemsByPaginate,
    identifiedProblem,
    lastPage,
    total,
    saveIdentifiedProblem,
    getIdentifiedProblemsByPaginate,
    getAllIdentifiedProblems,
    updateIdentifiedProblem,
    deleteIdentifiedProblem,
  };
});
