import HttpClient from '@/services/http-client';
import qs from 'qs';

class SupplierAPI extends HttpClient {
  async getAllSuppliers(
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
  ) {
    const response = await this.http.get('/suppliers', {
      params: {
        fields,
        relations,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/suppliers/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveSupplier(data: Record<string, string>) {
    const response = await this.http.post('/suppliers', data);
    return response.data;
  }
  async updateSupplier({ id, ...data }) {
    const response = await this.http.patch('/suppliers/' + id, data);
    return response.data;
  }
  async deleteSupplier(id: number) {
    const response = await this.http.delete('/suppliers/' + id);
    return response.data;
  }
  async getVehiclesByInsurer() {
    const response = await this.http.get('/summaries/unavailable/suppliers/');
    return response.data;
  }
}

export default () => new SupplierAPI();
