import { defineStore } from 'pinia';
import { ref } from 'vue';

import ReviewCriteriaAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useReviewCriteriaStore = defineStore('reviewCriteriaStore', () => {
  const allReviewCriterias = ref<Array<any>>([]);

  async function getAllReviewCriterias() {
    const data = await ReviewCriteriaAPI().getAllReviewCriterias();

    allReviewCriterias.value = data;
  }

  return {
    allReviewCriterias,
    getAllReviewCriterias,
  };
});
