import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import TollTicketAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import getters from './getters';

export const useTagRecordStore = defineStore('tagRecordStore', () => {
  const allTollTickets = ref<Array<any>>([]);
  const allUsageErrors = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);
  const getAllTollTickets = computed(() => getters.getAllTollTickets(allTollTickets.value));

  const allParkings = ref<Array<any>>([]);
  const lastPageParking = ref(1);
  const totalParking = ref(0);
  const getAllParkings = computed(() => getters.getAllParkings(allParkings.value));

  const allTollTagMonthlyFees = ref<Array<any>>([]);
  const lastPageTollTagMonthlyFees = ref(1);
  const totalTollTagMonthlyFees = ref(0);
  const getAllTollTagMonthlyFees = computed(() =>
    getters.getAllTollTagMonthlyFees(allTollTagMonthlyFees.value),
  );

  const loadingTollTicket = ref(false);

  async function getTollTickets({ filter, paginate }) {
    const data = await TollTicketAPI().paginate(filter, paginate.page, paginate.perPage);

    allTollTickets.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveTollTickets(data: Record<string, string>) {
    return TollTicketAPI().saveTollTickets(data);
  }

  async function getParkingTickets({ filter, paginate }) {
    const data = await TollTicketAPI().paginateParkingTickets(
      filter,
      paginate.page,
      paginate.perPage,
    );

    allParkings.value = data.data;
    totalParking.value = data.total;
    lastPageParking.value = data.lastPage;
  }

  async function getTollTagMonthlyFees({ filter, paginate }) {
    const data = await TollTicketAPI().paginateTollTagMonthlyFees(
      filter,
      paginate.page,
      paginate.perPage,
    );

    allTollTagMonthlyFees.value = data.data;
    totalTollTagMonthlyFees.value = data.total;
    lastPageTollTagMonthlyFees.value = data.lastPage;
  }

  async function reportExtract(data: { startDate: string; endDate: string; type: string }) {
    return TollTicketAPI().extract(data);
  }

  async function getAllTollTicket(payload: Object = {}) {
    loadingTollTicket.value = true;
    const data = await TollTicketAPI().getAllTollTicket(payload);
    loadingTollTicket.value = false;

    return data;
  }

  async function getExtractTollsTickets(payload) {
    return TollTicketAPI().getExtractTollsTickets(payload);
  }

  return {
    allTollTickets,
    allUsageErrors,
    lastPage,
    total,
    getAllTollTickets,
    allParkings,
    lastPageParking,
    totalParking,
    getAllParkings,
    allTollTagMonthlyFees,
    lastPageTollTagMonthlyFees,
    totalTollTagMonthlyFees,
    getAllTollTagMonthlyFees,
    loadingTollTicket,
    getExtractTollsTickets,
    getAllTollTicket,
    reportExtract,
    getTollTagMonthlyFees,
    getParkingTickets,
    saveTollTickets,
    getTollTickets,
  };
});
