import { defineStore } from 'pinia';
import { ref } from 'vue';

import DashboardAPI from './service';

export const useDashboardStore = defineStore('dashboardStore', () => {
  const historicSendingUnavailabilities = ref<Array<any>>([]);
  const listCostCenter = ref<Array<any>>([]);
  const vehicleByUnits = ref<Array<any>>([]);
  const vehicleTypeArray = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getHistoryOfSendingUnavailability({ paginate }) {
    const data = await DashboardAPI().getHistoryOfSendingUnavailability(
      paginate.page,
      paginate.perPage,
    );

    historicSendingUnavailabilities.value = data.data;
    lastPage.value = data.lastPage;
    total.value = data.total;
  }

  async function exportAssetStructure() {
    return await DashboardAPI().exportAssetStructure();
  }

  async function getAssetStructureSummaryByFilterType(
    { filterType, costCentersToFilter, costCentersToHide } = {
      filterType: 'vehicleType',
      costCentersToFilter: [],
      costCentersToHide: [],
    },
  ) {
    const data = await DashboardAPI().getAssetStructureSummaryByFilterType({
      filterType,
      costCentersToFilter,
      costCentersToHide,
    });

    vehicleByUnits.value = data;
    vehicleTypeArray.value = data.vehicleTypeArray;
  }

  async function getVehicleTypeSummary(
    { costCentersToFilter, costCentersToHide } = {
      costCentersToFilter: [],
      costCentersToHide: [],
    },
  ) {
    const data = await DashboardAPI().getVehicleTypeSummary({
      costCentersToFilter,
      costCentersToHide,
    });

    vehicleTypeArray.value = data;
  }

  async function getListCostCenter() {
    const data = await DashboardAPI().getListCostCenter();

    listCostCenter.value = data;
  }

  async function getUnavailable() {
    const data = await DashboardAPI().getUnavailable();
    return data;
  }

  async function reportExtract() {
    return await DashboardAPI().reportExtract();
  }

  async function getVehicleUnavailable() {
    const data = await DashboardAPI().getVehicleUnavailable();
    return data;
  }

  async function sendTemplateByEmail(payload: object) {
    return await DashboardAPI().sendTemplateByEmail(payload);
  }

  async function getLastDestinations() {
    return await DashboardAPI().getLastDestinations();
  }

  return {
    lastPage,
    total,
    historicSendingUnavailabilities,
    listCostCenter,
    vehicleByUnits,
    vehicleTypeArray,
    getLastDestinations,
    sendTemplateByEmail,
    getVehicleUnavailable,
    reportExtract,
    getUnavailable,
    getListCostCenter,
    getAssetStructureSummaryByFilterType,
    getVehicleTypeSummary,
    exportAssetStructure,
    getHistoryOfSendingUnavailability,
  };
});
