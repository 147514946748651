import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleTypeAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useVehicleTypeStore = defineStore('vehicleTypeStore', () => {
  const allVehicleTypes = ref<Array<any>>([]);
  const vehicleTypesByPaginate = ref<Array<any>>([]);
  const vehicleType = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function getVehicleTypesByPaginate({ filter, paginate }) {
    const data = await VehicleTypeAPI().paginate(filter, paginate.page, paginate.perPage);

    vehicleTypesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllVehicleTypes() {
    const data = await VehicleTypeAPI().getAllVehicleTypes();

    allVehicleTypes.value = data;
  }

  async function saveVehicleType(payload: Record<string, string>) {
    const data = await VehicleTypeAPI().saveVehicleType(payload);

    vehicleTypesByPaginate.value.unshift(data);
  }

  async function updateVehicleType(payload: any) {
    const data = await VehicleTypeAPI().updateVehicleType(payload);

    updateItemByField(vehicleTypesByPaginate.value, 'id', data.id, data);
  }

  async function deleteVehicleType(id: number) {
    const data = await VehicleTypeAPI().deleteVehicleType(id);

    removeItemByField(vehicleTypesByPaginate.value, 'id', data.id);
  }

  return {
    allVehicleTypes,
    vehicleTypesByPaginate,
    vehicleType,
    lastPage,
    total,
    getVehicleTypesByPaginate,
    getAllVehicleTypes,
    saveVehicleType,
    updateVehicleType,
    deleteVehicleType,
  };
});
