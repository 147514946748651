import moment from 'moment';

moment.locale('pt');

const differenceBetweenCurrentDateAndInputDate = (date: Date) => {
  const now = moment();
  const due = moment(date);
  const difference = due.diff(now, 'days');
  return difference;
};

const getTitleAndContent = (appraisal) => {
  const pendencies = appraisal.pendencies;
  const accessoryNames = pendencies.map((accessory) => accessory.name);

  return {
    title: `Pendências ${appraisal.vehicle.plate} ${appraisal.appraisalType.name}`,
    content: accessoryNames.join('; <br/>'),
  };
};

const getStatusAppraisal = (appraisal) => {
  const pendencies = appraisal?.pendencies;
  if (pendencies?.length)
    return { name: 'Com pendências', color: 'warning', pendencies: getTitleAndContent(appraisal) };

  return { name: 'Sem pendências', color: 'success', pendencies: '' };
};

export const calculateAmountOfDaysLeft = (allAppraisalsByPaginate: any[]) => {
  const currentDay = 1;
  const newAppraisals = allAppraisalsByPaginate.map((appraisal) => {
    appraisal.status = getStatusAppraisal(appraisal);
    const difference = differenceBetweenCurrentDateAndInputDate(appraisal.due);

    if (difference >= 0) appraisal.dueDays = difference + currentDay;
    if (difference < 0) appraisal.dueDays = difference;

    return appraisal;
  });

  return newAppraisals.sort((appraisal) => {
    if (appraisal.status.pendencies) return -1;

    return 1;
  });
};

export const getAppraisalWithStatusAndDue = (appraisal) => {
  const currentDay = 1;
  const difference = differenceBetweenCurrentDateAndInputDate(appraisal?.due);
  const dueDays = difference + currentDay;

  return {
    ...appraisal,
    status: getStatusAppraisal(appraisal),
    dueDays,
  };
};

export const getAppraisalIndicatorDataWithType = (allAppraisals: any[]) => {
  const currentDay = 1;
  const days30 = 30;
  const days60 = 60;
  const winQuantity = {
    name: 'Em dia',
    quantity: 0,
    Tacógrafo: { total: 0, appraisals: [] },
    Lança: { total: 0, appraisals: [] },
    Acústico: { total: 0, appraisals: [] },
    Liner: { total: 0, appraisals: [] },
  };
  const expiredQuantity = {
    name: 'Vencidos',
    quantity: 0,
    Tacógrafo: { total: 0, appraisals: [] },
    Lança: { total: 0, appraisals: [] },
    Acústico: { total: 0, appraisals: [] },
    Liner: { total: 0, appraisals: [] },
  };
  const quantityWinInDays30 = {
    name: 'À vencer 30 dias',
    quantity: 0,
    Tacógrafo: { total: 0, appraisals: [] },
    Lança: { total: 0, appraisals: [] },
    Acústico: { total: 0, appraisals: [] },
    Liner: { total: 0, appraisals: [] },
  };
  const quantityWinInDays60 = {
    name: 'À vencer 60 dias',
    quantity: 0,
    Tacógrafo: { total: 0, appraisals: [] },
    Lança: { total: 0, appraisals: [] },
    Acústico: { total: 0, appraisals: [] },
    Liner: { total: 0, appraisals: [] },
  };

  for (const appraisal of allAppraisals) {
    const difference = differenceBetweenCurrentDateAndInputDate(appraisal.due);

    const dueDays = difference + currentDay;

    if (difference < 0) {
      expiredQuantity[appraisal.appraisalType.name].total++;
      expiredQuantity[appraisal.appraisalType.name].appraisals.push({
        ...appraisal,
        status: getStatusAppraisal(appraisal),
        dueDays,
      });
      expiredQuantity.quantity++;
      continue;
    }

    if (dueDays <= days30) {
      quantityWinInDays30[appraisal.appraisalType.name].total++;
      quantityWinInDays30[appraisal.appraisalType.name].appraisals.push({
        ...appraisal,
        dueDays,
        status: getStatusAppraisal(appraisal),
      });
      quantityWinInDays30.quantity++;
      continue;
    }

    if (dueDays > days30 && dueDays <= days60) {
      quantityWinInDays60[appraisal.appraisalType.name].total++;
      quantityWinInDays60[appraisal.appraisalType.name].appraisals.push({
        ...appraisal,
        dueDays,
        status: getStatusAppraisal(appraisal),
      });

      quantityWinInDays60.quantity++;
      continue;
    }

    winQuantity[appraisal.appraisalType.name].total++;
    winQuantity[appraisal.appraisalType.name].appraisals.push({
      ...appraisal,
      dueDays,
      status: getStatusAppraisal(appraisal),
    });

    winQuantity.quantity++;
  }

  return [winQuantity, expiredQuantity, quantityWinInDays30, quantityWinInDays60];
};
