import moment from 'moment';
import Vue from 'vue';

Vue.filter('date', function(date: string, format: string) {
  if (!date) return;
  return moment(date).format(format);
});

Vue.filter('cnpj', function(cnpj) {
  cnpj = cnpj.replace(/\D/g, ''); //Remocnpje tudo o que não é dígito
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitacnpjo e o nono dígitos
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return cnpj;
});

Vue.filter('cpf', function(cpf) {
  cpf = cpf.replace(/\D/g, ''); //Remove tudo o que não é dígito
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  return cpf;
});
