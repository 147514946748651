import router from '@/router';
import axios from 'axios';
import nprogress from 'nprogress';
import Vue from 'vue';

function showServerErrorDialog(response) {
  Vue.prototype.$swal({
    title: 'Falha',
    html: 'Erro interno do sistema: </br> Por favor contate o administrador do seu sistema',
    icon: 'error',
  });
}

function logoutUser() {
  localStorage.removeItem('token');

  if (router.currentRoute.fullPath !== '/login') router.push('/login');
}

export default function() {
  const host = process.env.VUE_APP_API_URL;

  const axiosInstance = axios.create({
    baseURL: host + '/api',
    // timeout: 60000,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      nprogress.start();

      const token = localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (host) config.headers['Access-Control-Allow-Origin'] = host;

      return config;
    },
    (response) => Promise.reject(response),
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      nprogress.done();
      return response;
    },
    (error) => {
      nprogress.done();
      const response = error.response;

      if (response && response.status === 401) {
        logoutUser();
      }

      if (response && response.status === 500) {
        showServerErrorDialog(response);
      }

      throw error;
    },
  );

  return axiosInstance;
}
