import { defineStore } from 'pinia';
import { ref } from 'vue';

import AuthorizedServiceAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useAuthorizedServiceStore = defineStore('authorizedServiceStore', () => {
  const allAuthorizedServices = ref<Array<any>>([]);
  const authorizedService = ref({});
  const authorizedServicesByPaginate = ref<Array<any>>([]);
  const lastPage = ref(0);
  const total = ref(0);

  async function getAllAuthorizedServices() {
    const data = await AuthorizedServiceAPI().getAllAuthorizedServices();

    allAuthorizedServices.value = data;
  }

  async function getAllAuthorizedServicesByPaginate({ filter, paginate }) {
    const data = await AuthorizedServiceAPI().paginate(filter, paginate.page, paginate.perPage);

    authorizedServicesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveAuthorizedService(obj: Record<string, string>) {
    const data = await AuthorizedServiceAPI().saveAuthorizedService(obj);

    authorizedServicesByPaginate.value.unshift(data);
  }

  async function updateAuthorizedService(obj: any) {
    const data = await AuthorizedServiceAPI().updateAuthorizedService(obj);

    updateItemByField(authorizedServicesByPaginate.value, 'id', data.id, data);
  }

  async function deleteAuthorizedService(id: number) {
    const data = await AuthorizedServiceAPI().deleteAuthorizedService(id);

    removeItemByField(authorizedServicesByPaginate.value, 'id', data.id);
  }

  return {
    allAuthorizedServices,
    authorizedService,
    authorizedServicesByPaginate,
    lastPage,
    total,
    deleteAuthorizedService,
    updateAuthorizedService,
    saveAuthorizedService,
    getAllAuthorizedServicesByPaginate,
    getAllAuthorizedServices,
  };
});
