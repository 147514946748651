import { defineStore } from 'pinia';
import { ref } from 'vue';

import PaymentMethodsAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const usePaymentMethodStore = defineStore('paymentMethodStore', () => {
  const allPaymentMethods = ref<Array<any>>([]);

  async function getAllPaymentMethods() {
    const data = await PaymentMethodsAPI().getAllPaymentMethods();

    allPaymentMethods.value = data;
  }

  return {
    allPaymentMethods,
    getAllPaymentMethods,
  };
});
