import { defineStore } from 'pinia';
import { ref } from 'vue';

import MaintenanceSchedulingAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useMaintenanceScheadulingStore = defineStore('maintenanceScheadulingStore', () => {
  const allMaintenanceSchedulings = ref<Array<any>>([]);
  const allMaintenanceSchedulingsByPaginate = ref<Array<any>>([]);
  const maintenanceScheduling = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function getMaintenanceSchedulingById(id: number) {
    const data = await MaintenanceSchedulingAPI().getMaintenanceSchedulingById(id);

    maintenanceScheduling.value = data;
  }

  async function deleteMaintenanceScheduling(id: number) {
    const data = await MaintenanceSchedulingAPI().deleteMaintenanceScheduling(id);

    removeItemByField(allMaintenanceSchedulingsByPaginate.value, 'id', data.id);

    maintenanceScheduling.value = {};
  }

  async function updateMaintenanceScheduling(obj: any) {
    const data = await MaintenanceSchedulingAPI().updateMaintenanceScheduling(obj);

    updateItemByField(allMaintenanceSchedulingsByPaginate.value, 'id', data.id, data);

    maintenanceScheduling.value = data;
  }

  async function saveMaintenanceScheduling(obj: Record<string, string>) {
    const data = await MaintenanceSchedulingAPI().saveMaintenanceScheduling(obj);

    allMaintenanceSchedulingsByPaginate.value.unshift(data);
  }

  async function getAllMaintenanceSchedulings() {
    const data = await MaintenanceSchedulingAPI().getAllMaintenanceSchedulings();

    allMaintenanceSchedulings.value = data;
  }

  async function getMaintenanceSchedulingsByPaginate({ filter, paginate }) {
    const data = await MaintenanceSchedulingAPI().paginate(filter, paginate.page, paginate.perPage);

    allMaintenanceSchedulingsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function reportExtract(id: number) {
    return MaintenanceSchedulingAPI().reportExtract(id);
  }

  async function extractAll(type) {
    return MaintenanceSchedulingAPI().extractAll({ type });
  }

  return {
    allMaintenanceSchedulings,
    allMaintenanceSchedulingsByPaginate,
    maintenanceScheduling,
    lastPage,
    total,
    extractAll,
    reportExtract,
    getMaintenanceSchedulingsByPaginate,
    getAllMaintenanceSchedulings,
    saveMaintenanceScheduling,
    updateMaintenanceScheduling,
    deleteMaintenanceScheduling,
    getMaintenanceSchedulingById,
  };
});
