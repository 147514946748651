import { defineStore } from 'pinia';
import { ref } from 'vue';

import CostCenterAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useCostCenterStore = defineStore('costCenterStore', () => {
  const allCostCenters = ref<Array<any>>([]);
  const allCostCentersByPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getCostCentersByPaginate({ filter, paginate }) {
    const data = await CostCenterAPI().getCostCenterByPaginate(
      filter,
      paginate.page,
      paginate.perPage,
    );

    allCostCentersByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function getAllCostCenters() {
    const data = await CostCenterAPI().getAllCostCenters();

    allCostCenters.value = data;
  }

  async function getAllCostCentersWithVehicles() {
    const data = await CostCenterAPI().getAllCostCentersWithVehicles();

    allCostCenters.value = data;
  }

  async function forcedUpdate() {
    return await CostCenterAPI().forcedUpdate();
  }

  async function extractAll() {
    return await CostCenterAPI().extractAll();
  }

  return {
    allCostCenters,
    allCostCentersByPaginate,
    lastPage,
    total,
    getCostCentersByPaginate,
    extractAll,
    forcedUpdate,
    getAllCostCentersWithVehicles,
    getAllCostCenters,
  };
});
