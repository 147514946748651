const getErrorMessage = (error) => {
  if (error?.response?.data instanceof Array && error?.response?.data?.length) {
    return error?.response?.data[0]?.message;
  }
  if (error?.response?.data?.error) return error?.response?.data?.error.message;
  if (error?.response?.data?.message) return error?.response?.data?.message;

  if (error?.message) return error.message;

  return error;
};

module.exports = {
  getErrorMessage,
};
