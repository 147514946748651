import HttpClient from '@/services/http-client';

class CostCenterAPI extends HttpClient {
  async getCostCenterByPaginate(filter: object, page: number, perPage: number) {
    const result = await this.http.get('/cost-centers/paginate', {
      params: { filter, page, perPage },
    });
    return result.data;
  }

  async getAllCostCenters() {
    const result = await this.http.get('/cost-centers');
    return result.data;
  }

  async forcedUpdate() {
    const result = await this.http.get('/cost-centers/force-update');
    return result.data;
  }

  async extractAll() {
    const result = await this.http.get('/cost-centers/extract');

    return result.data;
  }

  async getAllCostCentersWithVehicles() {
    const result = await this.http.get('/cost-centers/with-vehicles');

    return result.data;
  }
}

export default () => new CostCenterAPI();
