import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import layoutHelpers from '@/components/Drawer/helpers.js';
import themeSettings from '@/vendor/libs/theme-settings/theme-settings.js';
import menuEntries from '@/menu-entries';

export const useAppSettingsStore = defineStore('appSettingsStore', () => {
  const appVersion = ref(process.env.VUE_APP_VERSION);
  const publicUrl = ref(process.env.BASE_URL);

  const layoutHelpersRef = ref(layoutHelpers);

  const themeSettingsRef = ref(themeSettings);

  const menuEntriesRef = computed(() => menuEntries || []);

  const layoutNavbarBg = computed(
    () => themeSettingsRef.value.getOption('navbarBg') || 'navbar-theme',
  );

  const layoutSidenavBg = computed(
    () => themeSettingsRef.value.getOption('sidenavBg') || 'sidenav-theme',
  );

  const layoutFooterBg = computed(
    () => themeSettingsRef.value.getOption('footerBg') || 'footer-theme',
  );

  function scrollTop(
    to,
    duration,
    element = document.scrollingElement || document.documentElement,
  ) {
    if (element.scrollTop === to) return;

    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  }

  return {
    appVersion,
    publicUrl,
    layoutHelpers: layoutHelpersRef,
    themeSettings: themeSettingsRef,
    menuEntries: menuEntriesRef,
    scrollTop,
    layoutNavbarBg,
    layoutFooterBg,
    layoutSidenavBg,
  };
});
