import { defineStore } from 'pinia';
import { ref } from 'vue';

import RentalCostAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useRentalCostStore = defineStore('rentalCostStore', () => {
  const vehicles = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function fetchRentalCostSummary({ year, costCenters }) {
    return RentalCostAPI().fetchRentalCostSummary(year, costCenters);
  }

  async function exportRentalCostDataInExcel(year) {
    const data = await RentalCostAPI().exportRentalCostDataInExcel(year);
    return data;
  }

  return {
    vehicles,
    lastPage,
    total,
    exportRentalCostDataInExcel,
    fetchRentalCostSummary,
  };
});
