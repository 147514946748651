export default [
  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/',
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      public: true,
    },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/vehicle/public/:plate',
    name: 'VehiclePublicScreen',
    component: () => import('@/components/Vehicles/VehiclePublicScreen.vue'),
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/branches',
    name: 'Filiais',
    component: () => import('@/views/Branches.vue'),
    meta: {
      title: 'Filiais',
    },
  },
  {
    path: '/incident-records',
    name: 'Registros de ocorrencias',
    component: () => import('@/views/IncidentRecords.vue'),
    meta: {
      title: 'Registros de ocorrencias',
    },
  },
  {
    path: '/file-download/:fileName',
    name: 'Download de Arquivos',
    component: () => import('@/views/DownloadFilePage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      public: true,
      title: 'Download de Arquivos',
    },
  },
  {
    path: '/pdf-view/:fileName',
    name: 'Visualização de Pdfs',
    component: () => import('@/views/PdfView.vue'),
    props: true,
    meta: {
      public: true,
      requiresAuth: true,
      title: 'Visualizacao de Pdfs',
    },
  },
  {
    path: '/asset-structure',
    name: 'Estrutura de Ativos',
    component: () => import('@/components/Summaries/AssetStructure.vue'),
    meta: {
      title: 'Estrutura de Ativos',
    },
  },
  {
    path: '/tracker-costs',
    name: 'Rastreadores',
    component: () => import('@/views/TrackerCosts.vue'),
    meta: {
      title: 'Custo de rastreio',
    },
  },
  {
    path: '/tracker-cost-summary',
    name: 'Resumo de Custos de Rastreio',
    component: () => import('@/components/Summaries/TrackerCosts.vue'),
    meta: {
      title: 'Resumo de Custos de Rastreio',
    },
  },
  {
    path: '/unavailabilities',
    name: 'Unavailable',
    component: () => import('@/components/Summaries/Unavailable.vue'),
    meta: {
      title: 'Indisponibilidade',
    },
  },
  {
    path: '/operational-bases',
    name: 'OperationalBases',
    component: () => import('@/views/OperationalBases.vue'),
    meta: {
      title: 'Bases Operacionais',
    },
  },
  {
    path: '/vehicle-summary',
    name: 'Resumo de veiculos',
    component: () => import('@/components/Summaries/VehicleSummary.vue'),
    meta: {
      title: 'Resumo de veiculos',
    },
  },
  {
    path: '/rental-cost',
    name: 'RentalCost',
    component: () => import('@/components/Summaries/RentalCost.vue'),
    meta: {
      title: 'Custo de locação',
    },
  },
  {
    path: '/maintenance-cost',
    name: 'Custo de manutenção',
    component: () => import('@/components/Summaries/MaintenanceCost.vue'),
    meta: {
      title: 'Custo de manutenção',
    },
  },
  {
    path: '/maintenance-summary',
    name: 'Resumo de manutenção',
    component: () => import('@/components/Summaries/MaintenanceSummary.vue'),
    meta: {
      title: 'Resumos de manutenção',
    },
  },
  {
    path: '/fuel-cost',
    name: 'Custo de combustível',
    component: () => import('@/components/Summaries/FuelCost.vue'),
    meta: {
      title: 'Custo de Combustíveis',
    },
  },
  {
    path: '/consolidated-cost',
    name: 'Custo consolidado',
    component: () => import('@/components/Summaries/ConsolidatedCost.vue'),
    meta: {
      title: 'Custo consolidado',
    },
  },
  {
    path: '/appraisals-summary',
    name: 'Resumo de laudos',
    component: () => import('@/components/Summaries/AppraisalsSummary.vue'),
    meta: {
      title: 'Resumo de Laudos',
    },
  },
  {
    path: '/cost-tag',
    name: 'Custo de tag',
    component: () => import('@/components/Summaries/CostTag.vue'),
    meta: {
      title: 'Custo de tag',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
    meta: {
      title: 'Usuários',
    },
  },
  {
    path: '/fuel-supplies',
    name: 'FuelSupplies',
    component: () => import('@/views/FuelSupplies.vue'),
    meta: {
      title: 'Abastecimento',
    },
  },
  {
    path: '/tag-records',
    name: 'Registros de Tag',
    component: () => import('@/views/TagRecords.vue'),
    meta: {
      title: 'Registros de Tag',
    },
  },
  {
    path: '/traffic-tickets',
    name: 'Multas',
    component: () => import('@/views/TrafficTickets.vue'),
    meta: {
      title: 'Multas',
    },
  },
  {
    path: '/traffic-tickets/:id',
    name: 'TrafficTicket',
    component: () => import('@/components/TrafficTickets/TrafficTicketView.vue'),
    props: true,
    meta: {
      title: 'Infração de Transito',
    },
  },
  {
    path: '/traffic-violations',
    name: 'Tipos de infrações',
    component: () => import('@/views/TrafficViolations.vue'),
    meta: {
      title: 'Tipos de infrações',
    },
  },
  {
    path: '/maintenances',
    name: 'Maintenances',
    component: () => import('@/views/Maintenances.vue'),
    meta: {
      title: 'Manutenções',
    },
  },
  {
    path: '/maintenances/:id',
    name: 'Maintenance',
    component: () => import('@/components/Maintenances/MaintenanceView.vue'),
    props: true,
    meta: {
      title: 'Manutenção',
    },
  },
  {
    path: '/maintenance-schedulings',
    name: 'MaintenanceSchedulings',
    component: () => import('@/views/MaintenanceSchedulings.vue'),
    meta: {
      title: 'Agendamentos',
    },
  },
  {
    path: '/maintenance-schedulings/:id',
    component: () => import('@/components/Maintenances/MaintenanceSchedulingView.vue'),
    props: true,
    meta: {
      title: 'Agendamento',
    },
  },
  {
    path: '/review-criterias',
    name: 'ReviewCriterias',
    component: () => import('@/views/ReviewCriterias.vue'),
    meta: {
      title: 'Critérios de Revisão',
    },
  },
  {
    path: '/vehicle-part-groups',
    name: 'VehiclePartGroups',
    component: () => import('@/views/VehiclePartGroups.vue'),
    meta: {
      title: 'Grupos de Peças do Veículo',
    },
  },
  {
    path: '/additional-informations',
    name: 'AdditionalInformations',
    component: () => import('@/views/AdditionalInformations.vue'),
    meta: {
      title: 'Informações Adicionais',
    },
  },
  {
    path: '/vehicle-groups',
    name: 'VehicleGroups',
    component: () => import('@/views/VehicleGroups.vue'),
    meta: {
      title: 'Agrupamento de Veículos',
    },
  },
  {
    path: '/additional-users',
    name: 'AdditionalUsers',
    component: () => import('@/views/AdditionalUsers.vue'),
    meta: {
      title: 'Usuários Adicionais',
    },
  },
  {
    path: '/appraisals',
    name: 'Appraisals',
    component: () => import('@/views/Appraisals.vue'),
    meta: {
      title: 'Laudos',
    },
  },
  {
    path: '/appraisals/:id',
    name: 'Appraisal',
    component: () => import('@/components/Appraisals/AppraisalView.vue'),
    props: true,
    meta: {
      title: 'Laudo',
    },
  },
  {
    path: '/vehicle-movement-records',
    name: 'VehicleMovementRecords',
    component: () => import('@/views/VehicleMovementRecords.vue'),
    meta: {
      title: 'Registros de Portaria',
    },
  },
  {
    path: '/justification-record-input-output',
    name: 'JustificationRecordInputOutput',
    component: () => import('@/views/JustificationRecordInputOutput.vue'),
    meta: {
      title: 'Justificativas de Portaria',
    },
  },
  {
    path: '/vehicle-movement-permissions',
    name: 'VehicleMovementPermissions',
    component: () => import('@/views/VehicleMovementPermissions.vue'),
    meta: {
      title: 'Permissões de Portaria',
    },
  },
  {
    path: '/identified-problems',
    name: 'IdentifiedProblems',
    component: () => import('@/views/IdentifiedProblems.vue'),
    meta: {
      title: 'Problemas Identificados',
    },
  },
  {
    path: '/rotations',
    name: 'Rotations',
    component: () => import('@/views/Rotations.vue'),
    meta: {
      title: 'Turnos',
    },
  },
  {
    path: '/rental-contracts',
    name: 'RentalContracts',
    component: () => import('@/views/RentalContracts.vue'),
    meta: {
      title: 'Contratos de Locação',
    },
  },
  {
    path: '/rental-contracts/:id',
    name: 'RentalContractsView',
    component: () => import('@/components/RentalContract/RentalContractView.vue'),
    props: true,
  },
  {
    path: '/authorized-services',
    name: 'AuthorizedServices',
    component: () => import('@/views/AuthorizedServices.vue'),
    meta: {
      title: 'Serviços Autorizados',
    },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('@/views/Invoices.vue'),
    meta: {
      title: 'Notas fiscais',
    },
  },
  {
    path: '/invoices/:id',
    name: 'Invoice',
    component: () => import('@/components/Invoices/InvoiceView.vue'),
    props: true,
    meta: {
      title: 'Nota Fiscal',
    },
  },
  {
    path: '/vehicle-types',
    name: 'VehicleTypes',
    component: () => import('@/views/VehicleTypes.vue'),
    meta: {
      title: 'Tipos de Veículos',
    },
  },
  {
    path: '/vehicle-models',
    name: 'VehicleModels',
    component: () => import('@/views/VehicleModels.vue'),
    meta: {
      title: 'Modelos de Veículos',
    },
  },
  {
    path: '/document-status',
    name: 'DocumentStatus',
    component: () => import('@/views/DocumentStatus.vue'),
    meta: {
      title: 'Status de documentos',
    },
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import('@/views/Vehicles.vue'),
    meta: {
      title: 'Veículos',
    },
  },
  {
    path: '/vehicles/:id',
    name: 'Vehicle',
    component: () => import('@/components/Vehicles/VehicleView.vue'),
    props: true,
    meta: {
      title: 'Veículo',
    },
  },
  {
    path: '/accessories',
    name: 'Accessories',
    component: () => import('@/views/Accessories.vue'),
    meta: {
      title: 'Acessórios',
    },
  },
  {
    path: '/equipments',
    name: 'Equipments',
    component: () => import('@/views/Equipments.vue'),
    meta: {
      title: 'Equipamentos',
    },
  },
  {
    path: '/units',
    name: 'Units',
    component: () => import('@/views/Units.vue'),
    meta: {
      title: 'Unidades',
    },
  },
  {
    path: '/readjustment-indices',
    name: 'ReadjustmentIndices',
    component: () => import('@/views/ReadjustmentIndices.vue'),
    meta: {
      title: 'Índices de Reajustes',
    },
  },
  {
    path: '/cost-centers',
    name: 'CostCenters',
    component: () => import('@/views/CostCenters.vue'),
    meta: {
      title: 'Centro de Custo',
    },
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: () => import('@/views/Suppliers.vue'),
    meta: {
      title: 'Fornecedores',
    },
  },
  {
    path: '/employees',
    name: 'Funcionários',
    component: () => import('@/views/Employees.vue'),
    meta: {
      title: 'Funcionários',
    },
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('@/views/Roles.vue'),
    meta: {
      title: 'Cargos',
    },
  },
  {
    path: '/permissions',
    name: 'Permissions',
    component: () => import('@/views/Permissions.vue'),
    meta: {
      title: 'Permissões',
    },
  },
  {
    path: '/federative-units',
    name: 'Federative Units',
    component: () => import('@/views/Units.vue'),
    meta: {
      title: 'Unidades Federativas',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
    meta: {
      title: 'Configurações',
    },
  },
];
