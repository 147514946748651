import moment from 'moment-timezone';

export function useDashboard() {
  function getFilteredDateText(searchDate: string = '', filterType: string) {
    if (!searchDate || searchDate?.trim() === '-')
      return `Período: 01/2022 - ${moment().format('MM/YYYY')}`;

    const [year, month] = searchDate.split('-');

    if (filterType === 'Consolidado') {
      return `Período: ${moment(`${month}/${year}`, 'MM/YYYY').format('01/YYYY')} - ${moment(
        `${month}/${year}`,
        'MM/YYYY',
      ).format('MM/YYYY')}`;
    }

    if (!year && !month) return '';
    if (!year && month) return `Período: ${moment(month, 'MM').format('MM')}`;
    if (!month && year) return `Período: ${year}`;

    return `Período: ${month}/${year}`;
  }

  function getCostCenterFilterText(costCentersToFilter: Array<any>, costCentersToHide: Array<any>) {
    if (!costCentersToFilter.length && !costCentersToHide.length) return '';

    if (!costCentersToHide.length) {
      const costCenters = costCentersToFilter.map((costCenter) => costCenter.id).join(', ');
      return `Exibindo dados referentes ao(s) centro(s) de custo: ${costCenters}`;
    }

    const costCenters = costCentersToHide.map((costCenter) => costCenter.id).join(', ');
    return `Ocultando dados referentes ao(s) centro(s) de custo: ${costCenters}`;
  }

  return { getCostCenterFilterText, getFilteredDateText };
}
