import { useMath } from '../math';

export function useInvoice() {
  const { sum } = useMath();

  function getTotalApportionmentValue(apportionments: any[]): number {
    return apportionments.reduce((total, apportionment) => {
      total = sum(total, apportionment.value, 2);

      return total;
    }, 0);
  }

  function getBadgeStatusClass(totalApportionments, totalInvoice, apportionments) {
    if (totalApportionments < totalInvoice) return 'badge-outline-warning';
    if (totalApportionments > totalInvoice) return 'badge-outline-danger';
    if (!apportionments?.length) return 'badge-outline-danger';

    return 'badge-outline-success';
  }

  function getIconStatusClass(totalApportionments, invoice) {
    if (totalApportionments < invoice.value) return 'ion-md-warning text-warning';

    if (totalApportionments > invoice.value) return 'ion-md-warning text-danger';

    if (invoice?.cost_type_id && !invoice?.invoiceApportionments?.length)
      return 'ion-md-warning text-danger';

    return 'ion-md-checkmark text-success';
  }

  function getTotalApportionmentIndicatorMessage(totalApportionments, invoice) {
    if (totalApportionments < invoice.value)
      return 'Os valores do(s) rateio(s) sao menores que o da nota fiscal.';

    if (totalApportionments > invoice.value)
      return 'Os valores do(s) rateio(s) sao maiores que o da nota fiscal.';

    if (invoice?.cost_type_id && !invoice?.invoiceApportionments?.length)
      return 'A nota fiscal possui categoria de custo, porem nao possui nenhum raterio vinculado.';

    return 'Os valores do(s) rateio(s) conferem com o da nota fiscal.';
  }

  return {
    getTotalApportionmentValue,
    getIconStatusClass,
    getTotalApportionmentIndicatorMessage,
    getBadgeStatusClass,
  };
}
