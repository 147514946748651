import HttpClient from '@/services/http-client';
import qs from 'qs';

class VehicleAPI extends HttpClient {
  async getTotalCost(id) {
    const response = await this.http.get('/vehicles/total-cost/' + id);
    return response.data;
  }

  async saveBulkVehicles(payload: Object) {
    const response = await this.http.post('/vehicles/bulk', payload);
    return response.data;
  }

  async getAllVehiclesByFields(
    onlyActive: boolean,
    fields?: string[],
    relations?: { [key: string]: { fields?: string[] | string } },
    module?: string,
  ) {
    const response = await this.http.get(`/vehicles/all-vehicles`, {
      params: {
        fields,
        relations,
        onlyActive,
        module,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  }

  async getAllVehicles() {
    const response = await this.http.get('/vehicles');
    return response.data;
  }

  async getAllActiveVehicle() {
    const response = await this.http.get('/vehicles/active');
    return response.data;
  }

  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicles/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }

  async getRentalVehiclesCloseToReturnPaginated(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/vehicles/rented/close-to-return/paginate', {
      params: { filter, page, perPage },
    });

    return response.data;
  }

  async saveVehicle(data: Record<string, string>) {
    const response = await this.http.post('/vehicles', data);
    return response.data;
  }

  async updateVehicle(data) {
    const id = data.get('id');
    const response = await this.http.patch('/vehicles/' + id, data);
    return response.data;
  }

  async updateVehicleCRLV(data) {
    const id = data.get('id');

    const response = await this.http.patch(`/vehicles/${id}/crlv`, data);

    return response.data;
  }

  async deleteVehicle(id) {
    const response = await this.http.delete('/vehicles/' + id);
    return response.data;
  }

  async getAllVehiclesForExport() {
    const response = await this.http.get('/vehicles/extract/vehicles');
    return response.data;
  }

  async getVehicleModel() {
    const response = await this.http.get('/vehicles/extract/model');
    return response.data;
  }

  async getVehicle(id) {
    const response = await this.http.get('/vehicles/' + id);
    return response.data;
  }

  async getVehiclePublicScreen(plate) {
    const response = await this.http.get('/vehicles/vehicle/public/' + plate);
    return response.data;
  }

  async getMaintenanceByVehicle(id) {
    const response = await this.http.get('/vehicles/maintenance/' + id);
    return response.data;
  }

  async getMaintenanceSchedulingByVehicle(id) {
    const response = await this.http.get('/vehicles/scheduling/' + id);
    return response.data;
  }

  async getAppraisalByVehicle(id) {
    const response = await this.http.get('/vehicles/appraisals/' + id);
    return response.data;
  }

  async getAppraisalByVehiclePublicScreen(plate) {
    const response = await this.http.get('/vehicles/appraisals/public/' + plate);
    return response.data;
  }

  async getSuppliesByVehicle({ id, filter, page, perPage }) {
    const response = await this.http.get('/vehicles/supplies', {
      params: { id, filter, page, perPage },
    });
    return response.data;
  }

  async exportDataAppraisal(id: Number) {
    const response = await this.http.get('/vehicles/appraisals/extract/' + id);
    return response.data;
  }

  async extractFile({ vehicleId, fileType, accessoryId, entityToExtract }) {
    const response = await this.http.get('/vehicles/extract/file/' + vehicleId, {
      params: { fileType, accessoryId, entityToExtract },
    });

    return response.data;
  }
}

export default () => new VehicleAPI();
