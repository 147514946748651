function findByPlate(allVehicles: any[], plate: string) {
  return allVehicles.find((el: any) => el.plate == plate);
}

function findVehicleFieldByPlateOrAlternativePlate(allVehiclesByFields: any[], plate: string) {
  return allVehiclesByFields.find(
    (el: any) => el?.plate === plate || el?.alternative_plate === plate,
  );
}

function exportData(allVehicles: any[]) {
  const data: Array<{}> = [];

  allVehicles?.forEach((vehicle) => {
    vehicle.modality = vehicle.rental?.supplier ? 'Locado' : 'Próprio';
    vehicle.rental_temp = vehicle.rental?.supplier ? vehicle.rental?.supplier.company_name : '3C';
    vehicle.accessories_temp = vehicle.accessories.map((el) => {
      return el.name;
    });

    vehicle.conductor = vehicle?.conductor_type_id ? vehicle?.conductor?.name : '';

    vehicle = { ...vehicle, ...vehicle.rental };

    vehicle.rental_id = vehicle.rental;
    data.push(vehicle);
  });

  return data;
}

function getAllVehiclesByPaginateWithBrand(allVehiclesByPaginate: any[]) {
  return allVehiclesByPaginate.map((vehicle) => {
    vehicle.brand = vehicle?.vehicleModel?.brand;
    return vehicle;
  });
}

function normalizeDataSchedulings(schedulings: any[]) {
  return schedulings.map((scheduling) => {
    scheduling.review_criteria_value =
      scheduling.reviewCriteria?.value === 'days'
        ? scheduling.review_criteria_value + ' dias'
        : scheduling.review_criteria_value + ' km';
    scheduling.scheduling_tolerance =
      scheduling.reviewCriteria?.value === 'days'
        ? scheduling.scheduling_tolerance + ' dias'
        : scheduling.scheduling_tolerance + ' km';
    return scheduling;
  });
}

export default {
  normalizeDataSchedulings,
  getAllVehiclesByPaginateWithBrand,
  findByPlate,
  findVehicleFieldByPlateOrAlternativePlate,
  exportData,
};
