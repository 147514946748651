import moment from 'moment';
import { provide } from 'vue';

export function useMomentProvider() {
  provide('$moment', (value) => {
    moment.locale('pt-br');

    if (!value) return moment();
    return moment(value);
  });

  provide('$rootMoment', moment);
}
