import HttpClient from '@/services/http-client';

class EquipmentsAPI extends HttpClient {
  async getAllEquipments() {
    const response = await this.http.get('/equipments');
    return response.data;
  }
  async paginate(filter: object, page: number, perPage: number) {
    const response = await this.http.get('/equipments/paginate', {
      params: { filter, page, perPage },
    });
    return response.data;
  }
  async saveEquipment(data: Record<string, string>) {
    const response = await this.http.post('/equipments', data);
    return response.data;
  }
  async updateEquipment({ id, ...data }) {
    const response = await this.http.patch('/equipments/' + id, data);
    return response.data;
  }
  async deleteEquipment(id) {
    const response = await this.http.delete('/equipments/' + id);
    return response.data;
  }
}

export default () => new EquipmentsAPI();
