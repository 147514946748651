import { defineStore } from 'pinia';
import { ref } from 'vue';

import OperationalBaseAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useOperationalBaseStore = defineStore('operationalBaseStore', () => {
  const allOperationalBasesPaginate = ref<Array<any>>([]);
  const allOperationalBases = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getAllOperationalBasesPaginate({ page, perPage, filter }) {
    const data = await OperationalBaseAPI().getAllPaginate({ page, perPage, filter });

    allOperationalBasesPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function updateOperationalBase(operationalBaseData) {
    const data = await OperationalBaseAPI().update(operationalBaseData);
    return data;
  }

  async function createOperationalBase(operationalBaseData) {
    const data = await OperationalBaseAPI().create(operationalBaseData);
    return data;
  }

  async function deleteOperationalBase(id: number) {
    const data = await OperationalBaseAPI().delete(id);
    return data;
  }

  async function getAllOperationalBase() {
    const data = await OperationalBaseAPI().getAll();

    allOperationalBases.value = data;
  }

  return {
    allOperationalBasesPaginate,
    allOperationalBases,
    lastPage,
    total,
    getAllOperationalBase,
    deleteOperationalBase,
    createOperationalBase,
    updateOperationalBase,
    getAllOperationalBasesPaginate,
  };
});
