import { defineStore } from 'pinia';
import { ref } from 'vue';

import BranchAPI from './service';

export const useBranchStore = defineStore('branchStore', () => {
  const allBranches = ref<Array<any>>([]);
  const allBranchesPaginate = ref<Array<any>>([]);
  const lastPage = ref(1);
  const total = ref(0);

  async function getAllBranches() {
    const data = await BranchAPI().getAllBranches();

    allBranches.value = data;
  }

  async function getAllBranchesPaginate({ page, perPage, filter }) {
    const data = await BranchAPI().getAllBranchesPaginate({ page, perPage, filter });

    allBranchesPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  return {
    allBranches,
    allBranchesPaginate,
    lastPage,
    total,
    getAllBranches,
    getAllBranchesPaginate,
  };
});
