import {
  useVehicleStore,
  useSupplierStore,
  useVehicleMovementPermissionStore,
  useEmployeeStore,
  useAdditionalUserStore,
  useJustificationRecordInputOutputStore,
} from '@/stores';

import moment from 'moment';

export function useVehicleMovementRecord() {
  const vehicleStore = useVehicleStore();
  const supplierStore = useSupplierStore();
  const vehicleMovementPermissionStore = useVehicleMovementPermissionStore();
  const employeeStore = useEmployeeStore();
  const additionalUserStore = useAdditionalUserStore();
  const justificationRecordInputOutputStore = useJustificationRecordInputOutputStore();

  async function fetchAllFormData() {
    const lastMovementRecordFormDataRequest = localStorage.getItem(
      'lastMovementRecordFormDataRequest',
    );

    if (!lastMovementRecordFormDataRequest) {
      await Promise.allSettled([
        vehicleStore.getAllVehiclesByFields({
          fields: ['id', 'chassi', 'plate', 'alternative_plate', 'active', 'branch_id'],
          relations: { branch: { fields: ['id', 'name'] } },
          module: 'vehicle-movement-records',
        }),
        employeeStore.getAllEmployees({
          module: 'vehicle-movement-records',
          fields: ['id', 'branch_id', 'name', 'registration', 'cost_center', 'fired'],
          relations: { branch: { fields: ['id', 'name'] } },
        }),
        additionalUserStore.getAllAdditionalUsers(),
        justificationRecordInputOutputStore.getAllJustifications(),
        supplierStore.getAllSuppliers(),
        vehicleMovementPermissionStore.getAllVehicleMovementPermissions(),
      ]);

      return localStorage.setItem('lastMovementRecordFormDataRequest', moment(new Date()).format());
    }

    const diffBetweenLastRequestInMinutes = moment(moment()).diff(
      lastMovementRecordFormDataRequest,
      'minutes',
    );

    if (diffBetweenLastRequestInMinutes < 5) return;

    await Promise.allSettled([
      vehicleStore.getAllVehiclesByFields({
        fields: ['id', 'chassi', 'plate', 'alternative_plate', 'active', 'branch_id'],
        relations: { branch: { fields: ['id', 'name'] } },
        module: 'vehicle-movement-records',
      }),
      employeeStore.getAllEmployees({
        module: 'vehicle-movement-records',
        fields: ['id', 'branch_id', 'name', 'registration', 'cost_center', 'fired'],
        relations: { branch: { fields: ['id', 'name'] } },
      }),
      additionalUserStore.getAllAdditionalUsers(),
      justificationRecordInputOutputStore.getAllJustifications(),
      supplierStore.getAllSuppliers(),
      vehicleMovementPermissionStore.getAllVehicleMovementPermissions(),
    ]);

    return localStorage.setItem('lastMovementRecordFormDataRequest', moment(new Date()).format());
  }

  return { fetchAllFormData };
}
