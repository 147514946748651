import { ref, computed } from 'vue';

import moment from 'moment';

export function useDate() {
  const months = computed(() => {
    const names: any = [];

    for (let i = 0; i < 12; i++) {
      names.push(
        moment()
          .month('Janeiro')
          .add(i, 'months')
          .format('MMMM'),
      );
    }

    return names;
  });

  const currentDate = computed(() => {
    const today = Date.now();
    return today;
  });

  function getMonthNumberByName(name) {
    const month = moment()
      .month(name)
      .format('MM');
    return month;
  }

  function capitalizedFirstLetter(name: string) {
    return name[0].toUpperCase() + name.slice(1);
  }

  function numberHave4Digits(number) {
    if (!number) return false;

    return number.toString().length === 4;
  }

  function getNameMonthSummaryByName(month) {
    const name = moment(month, 'MMMM').format('MMM');

    return name[0].toUpperCase() + name.slice(1);
  }

  const monthLabels = computed(() => {
    return months.value.map((month) => getNameMonthSummaryByName(month));
  });

  function formatDateWithHour(date) {
    if (date || date === null) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
  }

  function formatDate(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '';
  }

  function compareDates(dateOne, currentDate, dateOneFormat = 'DD/MM/YYYY') {
    return moment(dateOne, dateOneFormat).isBefore(
      moment(new Date(currentDate)).format(dateOneFormat),
    );
  }

  function isAfter(dateOne, currentDate, dateOneFormat = 'DD/MM/YYYY') {
    return moment(new Date(currentDate)).isAfter(moment(dateOne, dateOneFormat));
  }

  function formatDateWithoutConvertUTC(date, format = 'DD/MM/YYYY') {
    if (date) {
      return moment(date)
        .utc()
        .format(format);
    }
    return '';
  }

  function formatDateWithoutDay(date) {
    if (date || date === null) {
      return moment(date).format('MM/YYYY');
    }
    return '';
  }

  function getArrayOfYears(startYear = 2018, endYear = new Date().getFullYear()): Array<number> {
    const years: Array<number> = [];

    for (let year = startYear; year <= endYear; year += 1) {
      years.push(year);
    }

    return years;
  }

  function getNameMonthByNumber(month) {
    if (month) {
      const name = moment()
        .month(month)
        .subtract(1, 'month')
        .format('MMMM');
      return name[0].toUpperCase() + name.slice(1);
    }

    return '';
  }

  function getNameMonthSummaryByNumber(month) {
    const name = moment()
      .month(month)
      .subtract(1, 'month')
      .format('MMM');
    return name[0].toUpperCase() + name.slice(1);
  }

  function getDiffBetweenDates(dateOne, dateTwo, unit) {
    const startDate = moment(dateOne);
    const endDate = moment(dateTwo);

    return startDate.diff(endDate, unit);
  }

  return {
    months,
    getNameMonthSummaryByName,
    getMonthNumberByName,
    numberHave4Digits,
    capitalizedFirstLetter,
    monthLabels,
    formatDateWithHour,
    formatDate,
    compareDates,
    currentDate,
    formatDateWithoutConvertUTC,
    formatDateWithoutDay,
    getArrayOfYears,
    getNameMonthByNumber,
    getNameMonthSummaryByNumber,
    isAfter,
    getDiffBetweenDates,
  };
}
