import HttpClient from '@/services/http-client';

class CityAPI extends HttpClient {
  async getAllCities() {
    const response = await this.http.get('cities');
    return response.data;
  }
}

export default () => new CityAPI();
