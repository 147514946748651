import HttpClient from '@/services/http-client';

class MaintenanceStatusAPI extends HttpClient {
  async getAllMaintenanceOrigins() {
    const response = await this.http.get('/maintenance-origins');
    return response.data;
  }
}

export default () => new MaintenanceStatusAPI();
