export function useMaintenanceScheduling() {
  function formatSchedulingCriteriaData(scheduling, field) {
    switch (true) {
      case scheduling.reviewCriteria.value === 'days':
        return `${scheduling[field]} dias`;

      case scheduling.reviewCriteria.value === 'odometer':
        return `${scheduling[field]} km`;

      case scheduling.reviewCriteria.value === 'hour_meter':
        return `${scheduling[field]} hr`;

      default:
        '';
    }
  }

  return {
    formatSchedulingCriteriaData,
  };
}
