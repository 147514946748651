const getStatusByData = (trafficTicket) => {
  if (
    !trafficTicket.conductor ||
    !trafficTicket.infraction_date ||
    !trafficTicket.issue_date ||
    !trafficTicket.amount
  ) {
    return { name: 'Pendente', color: 'warning' };
  }

  return { name: 'Concluído', color: 'success' };
};

const getStatusTrafficTicketsByPaginate = (trafficTicketsByPaginate: any[]) => {
  const trafficTickets = trafficTicketsByPaginate.map((trafficTicket) => {
    trafficTicket.status = getStatusByData(trafficTicket);

    return trafficTicket;
  });

  return trafficTickets.sort((trafficTicket) => {
    if (trafficTicket.status.name === 'Pendente') return -1;
    if (trafficTicket.status.name === 'Concluído') return 1;
    return 0;
  });
};

export default { getStatusTrafficTicketsByPaginate };
