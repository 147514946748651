const normalizeVehicleModels = (allVehicleModels: any[]) => {
  const result = allVehicleModels
    .map((x) => {
      x.model = `${x.model}, ${x.power}C, ${x.door_quantity}p.`;
      return x;
    })
    .sort((a, b) => {
      return a.model > b.model ? 1 : b.model > a.model ? -1 : 0;
    });
  return result;
};

export default {
  normalizeVehicleModels,
};
