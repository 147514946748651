import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import AppraisalAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';
import {
  getAppraisalWithStatusAndDue,
  calculateAmountOfDaysLeft,
  getAppraisalIndicatorDataWithType,
} from './getters';

export const useAppraisalStore = defineStore('appraisalStore', () => {
  const allAppraisals = ref<Array<any>>([]);
  const appraisal = ref({});
  const allAppraisalsByPaginate = ref<Array<any>>([]);
  const lastPage = ref(0);
  const total = ref(0);

  const appraisalWithStatusAndDue = computed(() => getAppraisalWithStatusAndDue(appraisal.value));

  const appraisalsWithStatusByDue = computed(() =>
    getAppraisalIndicatorDataWithType(allAppraisals.value),
  );

  const appraisalsByPaginateWithStatus = computed(() =>
    calculateAmountOfDaysLeft(allAppraisalsByPaginate.value),
  );

  async function getAllAppraisals({
    year,
    costCentersToFilter,
    costCentersToHide,
    fields,
    relations,
  }) {
    const data = await AppraisalAPI().getAllAppraisals({
      year,
      costCentersToFilter,
      costCentersToHide,
      fields,
      relations,
    });

    allAppraisals.value = data;
  }

  async function getAppraisal(id) {
    const data = await AppraisalAPI().getAppraisal(id);

    appraisal.value = data;
  }

  async function getAllAppraisalsByPaginate({ filter, paginate }) {
    const data = await AppraisalAPI().paginate(filter, paginate.page, paginate.perPage);

    allAppraisalsByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function create(obj: any) {
    const data = await AppraisalAPI().saveAppraisal(obj);

    allAppraisalsByPaginate.value.unshift(data);

    return data;
  }

  async function update(obj: any) {
    const id = obj.get('id');
    const data = await AppraisalAPI().updateAppraisal(id, obj);

    appraisal.value = data;
    updateItemByField(allAppraisalsByPaginate.value, 'id', data.id, data);

    return data;
  }

  async function deleteAppraisal(id: number) {
    const data = await AppraisalAPI().deleteAppraisal(id);

    removeItemByField(allAppraisalsByPaginate, 'id', data.id);
  }

  async function exportData(obj: any) {
    return await AppraisalAPI().exportData(obj.id);
  }

  async function exportDataPublicScreen(obj: any) {
    return await AppraisalAPI().exportDataPublicScreen(obj.id);
  }

  async function extractByPeriod(dates: { startDate: string; endDate: string }) {
    return await AppraisalAPI().extractByPeriod(dates);
  }

  async function extractAll() {
    return await AppraisalAPI().extractAll();
  }

  async function getAppraisalsSummaryByType({ appraisalType }) {
    const data = await AppraisalAPI().getAppraisalsSummaryByType({ appraisalType });

    return data;
  }

  async function extractSummary() {
    return await AppraisalAPI().extractSummary();
  }

  return {
    allAppraisals,
    appraisal,
    allAppraisalsByPaginate,
    lastPage,
    total,
    appraisalWithStatusAndDue,
    appraisalsWithStatusByDue,
    appraisalsByPaginateWithStatus,
    extractAll,
    extractByPeriod,
    exportDataPublicScreen,
    exportData,
    getAllAppraisals,
    getAllAppraisalsByPaginate,
    create,
    update,
    deleteAppraisal,
    getAppraisalsSummaryByType,
    extractSummary,
    getAppraisal,
  };
});
