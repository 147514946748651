import HttpClient from '@/services/http-client';
class VehicleMovementRecordAPI extends HttpClient {
  async paginate(filter: object, page: number, perPage: number, type: string) {
    const response = await this.http.get('/vehicle-movement-records/paginate', {
      params: { page, perPage, filter, type },
    });
    return response.data;
  }

  async saveVehicleMovementRecord(data: Record<string, string>) {
    const response = await this.request({
      method: 'POST',
      url: '/vehicle-movement-records',
      data,
      offline: true,
    });

    //const response = await this.http.post('/vehicle-movement-records', data);
    return response.data;
  }
  async updateVehicleMovementRecord(data: any) {
    const response = await this.request({
      method: 'PATCH',
      url: '/vehicle-movement-records/' + data.id,
      data,
      offline: true,
    });

    return response.data;
  }
  async deleteVehicleMovementRecord(id: number) {
    const response = await this.request({
      method: 'DELETE',
      url: '/vehicle-movement-records/' + id,

      offline: true,
    });

    return response.data;
  }
  async exportByType({ startDate, endDate, useVisitorsTable, type = 'summary' }) {
    const response = await this.http.get('/vehicle-movement-records/export', {
      params: { startDate, endDate, useVisitorsTable, type },
    });
    return response;
  }
  async getLastVehicleMovementRecord({ column, value }) {
    const response = await this.http.get('/vehicle-movement-records/last', {
      params: { column, value },
    });

    return response.data;
  }
  async getVehicleMovementRecordStatuses() {
    const response = await this.http.get('/vehicle-movement-records/statuses');

    return response.data;
  }
}

export default () => new VehicleMovementRecordAPI();
