import { defineStore } from 'pinia';
import { ref } from 'vue';

import RoleAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useRoleStore = defineStore('roleStore', () => {
  const allRoles = ref<Array<any>>([]);
  const allRolesByPaginate = ref<Array<any>>([]);
  const role = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  async function deleteRole(obj: { id: number }) {
    const data = await RoleAPI().deleteRole(obj);

    removeItemByField(allRoles.value, 'id', data.id);
  }

  async function updateRole(obj: { id: number }) {
    const data = await RoleAPI().updateRole(obj);

    updateItemByField(allRoles.value, 'id', data.id, data);
  }

  async function getAllRoles() {
    const data = await RoleAPI().getAllRoles();

    allRoles.value = data;
  }

  async function getRolesByPaginate({ filter, paginate }) {
    const data = await RoleAPI().getRolesByPaginate(filter, paginate.page, paginate.perPage);

    allRolesByPaginate.value = data.data;
    total.value = data.total;
    lastPage.value = data.lastPage;
  }

  async function saveRole(obj: Record<string, string>) {
    const data = await RoleAPI().saveRole(obj);

    allRoles.value.push(data);
  }

  return {
    allRolesByPaginate,
    allRoles,
    role,
    lastPage,
    total,
    saveRole,
    getRolesByPaginate,
    getAllRoles,
    updateRole,
    deleteRole,
  };
});
