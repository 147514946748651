const { getErrorMessage } = require('./errorHelper');

const SwalUtils = {
  install(Vue, options) {
    Vue.prototype.$error = function(message, errorEntity) {
      const errorMessage = typeof message !== 'string' ? getErrorMessage(message) : message;

      this.$swal({
        icon: 'error',
        title: 'Oops...',
        text: `${errorMessage} ${errorEntity ? getErrorMessage(errorEntity) : ''}`,
      });
    };
    Vue.prototype.$success = function(value, message = '') {
      if (message) {
        return this.$swal.fire({
          position: 'center',
          icon: 'success',
          html: `<b>${value}</b> ${message}!`,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      return this.$swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> salvo com sucesso!`,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    Vue.prototype.$ask = function(value, title = 'Aviso') {
      return this.$swal.fire({
        position: 'center',
        icon: 'warning',
        title: title,
        text: value,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Continuar`,
        cancelButtonText: `Cancelar`,
      });
    };
    Vue.prototype.$delete = function(value, message = '') {
      if (typeof value === 'object') return;

      if (message) {
        return this.$swal.fire({
          position: 'center',
          icon: 'success',
          html: `<b>${value}</b> ${message}!`,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      return this.$swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> deletado com sucesso!`,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    Vue.prototype.$swalFire = function(message, icon = 'success') {
      return this.$swal.fire({
        position: 'center',
        icon: icon,
        html: `<b>${message}</b>`,
        showConfirmButton: false,
        timer: 1500,
      });
    };
  },
};

export default SwalUtils;
