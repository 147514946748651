import { defineStore } from 'pinia';
import { ref } from 'vue';

import VehicleUsageInfoAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

import { useFuelSupplyStore } from '@/features/fuel-supplies/store';

export const useVehicleUsageInfoStore = defineStore('vehicleUsageInfoStore', () => {
  const allVehicleUsageInfos = ref<Array<any>>([]);
  const vehicleUsageInfo = ref({});
  const lastPage = ref(1);
  const total = ref(0);

  const getAllVehicleUsageInfos = async () => {
    const data = await VehicleUsageInfoAPI().getAllVehicleUsageInfos();

    allVehicleUsageInfos.value = data;
  };

  const saveBulkVehicleUsageInfo = async (usageData) => {
    const data = await VehicleUsageInfoAPI().saveBulkVehicleUsageInfo(usageData);

    return data;
  };

  const saveVehicleUsageInfo = async ({ plate, ...obj }: Record<string, string>) => {
    const data = await VehicleUsageInfoAPI().saveVehicleUsageInfo(obj);

    allVehicleUsageInfos.value.push(data);

    const fuelSupplyStore = useFuelSupplyStore();

    fuelSupplyStore.removeVehicleByPlateFromUsageErrors(plate);
  };

  return {
    allVehicleUsageInfos,
    vehicleUsageInfo,
    lastPage,
    total,
    getAllVehicleUsageInfos,
    saveVehicleUsageInfo,
    saveBulkVehicleUsageInfo,
  };
});
