module.exports = {
  orderValue(valueOne, valueTwo, type = 'asc') {
    if (type === 'asc') {
      return valueOne - valueTwo;
    }
    return valueTwo - valueOne;
  },

  removeItemByField(array, field, fieldValue) {
    array.splice(
      array.findIndex((item) => item[field] === fieldValue),
      1,
    );
  },

  updateItemByField(array, field, fieldValue, data) {
    array.forEach((item, index) => {
      if (item[field] === fieldValue) {
        array.splice(index, 1, data);
      }
    });
  },
};
