import { defineStore } from 'pinia';
import { ref } from 'vue';

import MaintenanceTypeAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useMaintenanceTypeStore = defineStore('maintenanceTypeStore', () => {
  const allMaintenanceTypes = ref<Array<any>>([]);

  async function getAllMaintenanceTypes() {
    const data = await MaintenanceTypeAPI().getAllMaintenanceTypes();

    allMaintenanceTypes.value = data;
  }

  return {
    allMaintenanceTypes,
    getAllMaintenanceTypes,
  };
});
