import { defineStore } from 'pinia';
import { ref } from 'vue';

import NoteTypeAPI from './service';
import { removeItemByField, updateItemByField } from '@/utils/Array';

export const useNoteTypeStore = defineStore('noteTypeStore', () => {
  const allNoteTypes = ref<Array<any>>([]);

  async function getAllNoteTypes() {
    const data = await NoteTypeAPI().getAllNoteTypes();

    allNoteTypes.value = data;
  }

  return {
    allNoteTypes,
    getAllNoteTypes,
  };
});
